import { RouteConfig } from 'vue-router';

import Faq from '~/views/Faq';

const config: RouteConfig = {
  component: Faq,
  path: 'faq',
};

export default config;
