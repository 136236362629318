import { ActivityCarouselInterface, ActivityCarouselItem } from 'elixir-theme';
import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { default as HotelSectionsModule } from '~/app/core/store/modules/HotelSections';
import { GQLRootQuery } from '~/GqlTypes';
import createBeach, { BeachModel } from '~/utils/beach';
import { VueComponent } from '~/utils/vue-component';
import { getModule } from 'vuex-module-decorators';

interface ActivityCarousel {
  hotelId: string;
}

const LOAD_BEACHES_QUERY = gql`
  query($id: ID!) {
    hotels {
      get(id: $id) {
        id
        beaches {
          content
          excerpt
          id
          listImage {
            description
            src
            srcset
          }
          name
        }
      }
    }
  }
`;

@Component
export default class extends VueComponent<ActivityCarousel>
  implements ActivityCarousel {
  @Prop({ default: '' })
  public hotelId!: string;

  protected loading: boolean = false;

  protected beaches: BeachModel[] = [];

  protected get header(): string {
    return this.$t('app.hotel.beachesAndActivities').toString();
  }

  protected get nextToHotel(): string {
    return this.$t('app.hotel.nextToHotel').toString();
  }

  protected get items(): ActivityCarouselItem[] {
    const beaches: ActivityCarouselItem[] = [];

    for (const beach of this.beaches) {
      if (!beach.listImage) {
        continue;
      }

      let distance = '';
      if (beach.nextToHotel) {
        distance = this.nextToHotel;
      }

      beaches.push({
        title: beach.name,
        description: beach.content,
        distance,
        src: beach.listImage.src,
        // TODO: Add srcset when added in the theme
        // srcset: beach.listImage.srcset,
      });
    }

    return beaches;
  }

  public mounted() {
    const hotelSections = getModule(HotelSectionsModule, this.$store);
    hotelSections.setActivitiesActive(true);
    hotelSections.setActivitiesLoading(true);

    return this.loadActivities()
      .catch(() => {
        hotelSections.setActivitiesActive(false);
      })
      .finally(() => {
        hotelSections.setActivitiesLoading(false);
      });
  }

  public render() {
    if (!this.loading && this.items.length < 1) {
      return;
    }

    const attrs: ActivityCarouselInterface = {
      title: this.header,
      items: this.items,
    };

    return (
      <v-container class='px-0 pb-0'>
        {(() => {
          if (!this.loading) {
            return;
          }

          return (
            <div class='elix-section-title pb-4 primary--text text-center text-lg-left'>
              {this.header}
            </div>
          );
        })()}
        <v-skeleton-loader
          loading={this.loading}
          transition='scale-transition'
          type='hotel'
          types={{ hotel: 'image, article' }}
        >
          <activity-carousel {...{ attrs }} class='mt-3' />
        </v-skeleton-loader>
      </v-container>
    );
  }

  @Watch('hotelId')
  protected loadActivities(): Promise<BeachModel[]> {
    if (!this.hotelId) {
      this.beaches = [];
      return Promise.reject();
    }

    this.loading = true;

    return this.$apollo
      .query<GQLRootQuery>({
        query: LOAD_BEACHES_QUERY,
        variables: {
          id: this.hotelId,
        },
      })
      .then((result) => {
        if (!result.data.hotels.get) {
          throw new Error('Could not load the hotel');
        }

        const beaches = result.data.hotels.get.beaches.filter((beach) => {
          return !!beach.listImage;
        });

        if (beaches.length < 1) {
          throw new Error('No activities were found');
        }

        this.beaches = beaches.map((beach) => {
          return createBeach(beach);
        });

        return this.beaches;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
