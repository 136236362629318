import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { default as HotelStore } from '~/app/core/store/modules/Hotel';
import { default as HotelSectionsModule } from '~/app/core/store/modules/HotelSections';
import { RoomBlock } from '~/components/organisms/hotel';
import { HotelModel } from '~/utils/hotel';
import { VueComponentMixin } from '~/utils/vue-component';
import { ChosenPackageEvent } from '~/components/organisms/hotel/RoomBlock';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';

interface RoomSection {
  hotel: HotelModel;
  onChosenPackage?: (data: ChosenPackageEvent) => void;
}

@Component
export default class
  extends VueComponentMixin<RoomSection, PrefetchComponent>(Prefetch)
  implements RoomSection {
  @Prop({ required: true })
  public hotel!: HotelModel;

  protected isMobile: boolean = true;

  public mounted() {
    this.checkMobile();
    const hotelSections = getModule(HotelSectionsModule, this.$store);
    hotelSections.setRoomsActive(true);
    hotelSections.setRoomsLoading(true);
    return getModule(HotelStore, this.$store)
      .loadRoomDetails({
        hotelId: this.hotel.id,
      })
      .catch(() => {
        hotelSections.setRoomsActive(false);
      })
      .finally(() => {
        hotelSections.setRoomsLoading(false);
      });
  }

  public render() {
    if (!this.hotel.rooms) {
      return;
    }

    const classes = ['mt-0', 'mb-3'];

    if (this.isMobile) {
      classes.push('px-3');
    }

    return (
      <v-container class='px-0 pb-0'>
        <section-title title='Pokoje a ceny' class={classes.join(' ')} />
        {this.hotel.rooms.map((room) => {
          return (
            <RoomBlock
              hotel={this.hotel}
              room={room}
              key={'room-block-' + room.id}
              onChosenPackage={this.onChosenPackage}
            />
          );
        })}
      </v-container>
    );
  }

  @Emit('chosenPackage')
  public onChosenPackage(data: ChosenPackageEvent): ChosenPackageEvent {
    return data;
  }

  @Watch('$vuetify.breakpoint.name')
  protected checkMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }
}
