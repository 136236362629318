export function sortByProperty(array: { [key: string]: any }[], prop: string) {
  array.sort((a, b) => {
    if (typeof a[prop] === 'number' && typeof b[prop] === 'number') {
      return a[prop] - b[prop];
    }

    if (typeof a[prop] !== 'string' || typeof b[prop] !== 'string') {
      return 0;
    }

    return a[prop].localeCompare(b[prop]);
  });
}
