import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';

import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { getLocalizedUrl } from '~/app/core/router';
import Timeout = NodeJS.Timeout;

export interface SignupEvent {
  email: string;
  consent: boolean;
}

interface NewsletterSignup {
  active?: boolean;
  loading?: boolean;
  onSignup?: (data: SignupEvent) => void;
}

@Component
export default class extends VueComponent<NewsletterSignup>
  implements NewsletterSignup {
  @Prop({ default: true })
  public active!: boolean;

  @Prop({ default: false })
  public loading!: boolean;

  protected consentThrottle?: Timeout;

  protected consent: boolean = false;

  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  public created() {
    this.setMobile();
  }

  /**
   * TODO: Move text to translation component
   */
  public render() {
    if (!this.active) {
      return;
    }

    return (
      <div>
        <v-row
          no-gutters
          align='center'
          class={
            this.isMobile ? 'justify-center px-4' : 'justify-space-between'
          }
        >
          <v-col cols='12' sm='9' md='8'>
            <v-text-field solo label='Váš e-mail' hide-details />
          </v-col>

          <v-col cols='12' sm='3' md='4' class='text-center'>
            <v-btn
              large
              width={this.$vuetify.breakpoint.name === 'sm' ? '100%' : ''}
              class={
                'ma-2 tertiary contrast--text elix-button-text ' +
                (this.isMobile ? 'mx-2' : 'mx-4')
              }
              loading={this.loading}
              onClick={this.signup}
            >
              Odebírat novinky
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align='center'
          class={'mt-0 pt-0 ' + (this.isMobile ? 'mx-3' : '')}
        >
          <v-checkbox
            color='primary'
            class='mt-2'
            onClick={(e: any) => {
              // It ignores click on the checkbox, so we need to manually trigger it
              if (
                e.target &&
                e.target.parentNode &&
                e.target.parentNode.classList.contains(
                  'v-input--selection-controls__input'
                )
              ) {
                // throttle is here because some clicks fire multiple times
                if (this.consentThrottle) {
                  clearTimeout(this.consentThrottle);
                }

                this.consentThrottle = setTimeout(() => {
                  this.consent = !this.consent;
                }, 100);
              }
            }}
            value={this.consent}
          >
            <i18n
              tag='span'
              path='app.footer.newsletter.consent'
              class='body-2 primary--text'
              slot='label'
            >
              <v-tooltip
                bottom
                scopedSlots={{
                  activator: (scope: any) => {
                    return (
                      <a
                        href={getLocalizedUrl(
                          this.$t('app.page.consent.slug').toString(),
                          this.$router
                        )}
                        target='_blank'
                        {...{
                          on: scope.on,
                        }}
                        onclick={this.stopPropagation}
                      >
                        {this.$t('app.footer.newsletter.consentLink')}*
                      </a>
                    );
                  },
                }}
              >
                {this.$t('app.common.opensInNewWindow')}
              </v-tooltip>
            </i18n>
          </v-checkbox>
        </v-row>
      </div>
    );
  }

  /**
   * TODO: Implement signup event
   */
  @Emit('signup')
  protected signup(): SignupEvent {
    return {
      email: '',
      consent: false,
    };
  }

  @Watch('$vuetify.breakpoint.name')
  protected setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  /**
   * Helper function to prevent clicking the checkbox when opening consent link
   * @param e
   */
  protected stopPropagation(e: Event) {
    e.stopPropagation();
  }
}
