import { Component, Mixins } from 'vue-property-decorator';

import galleryItem01 from '~/assets/images/weddings/sri-lanka/01.jpg';
import galleryItem02 from '~/assets/images/weddings/sri-lanka/02.jpg';
import galleryItem03 from '~/assets/images/weddings/sri-lanka/03.jpg';
import galleryItem04 from '~/assets/images/weddings/sri-lanka/04.jpg';
import galleryItem05 from '~/assets/images/weddings/sri-lanka/05.jpg';
import GalleryRow from '~/components/organisms/GalleryRow';
import HeadManagement from '~/mixins/HeadManagement';
import { ImageModel } from '~/utils/image';

const gallery: ImageModel[] = [
  galleryItem01,
  galleryItem02,
  galleryItem03,
  galleryItem04,
  galleryItem05,
].map((item) => {
  return {
    src: item,
    srcset: '',
    rank: 0,
    description: '',
  };
});

@Component
export default class SriLanka extends Mixins(HeadManagement) {
  public render() {
    return (
      <v-container class='custom-content'>
        <h1 class='primary--text elix-section-title'>Svatba na Srí Lance</h1>
        <GalleryRow items={gallery} />
        <section>
          <h2 class='title'>Proč mít svatbu na Mauriciu?</h2>
          <p>
            Zdejší krajina je opravdu světovým unikátem. Krásné písečné pláže,
            nádherná příroda, jedinečné kulturní památky a mnoho možností, jak
            strávit příjemnou dovolenou. Rozhodnete-li se uzavřít svatbu v této
            destinaci, nebudete litovat. Váš svatební den bude naplněn nádhernou
            atmosférou a nezapomenutelnými zážitky. Zdejší svatby často provází
            tradiční symbolický obřad Poruwa. Součástí obřadu jsou tanečníci a
            hudebníci, kteří zajišťují veselou atmosféru. Součástí svatebního
            obřadu mohou být i zdejší tradice. Novomanželé mohou zapálit
            olejovou lampu, symbol věčnosti a společné manželské cesty nebo se
            vydat na novomanželskou projížďku na slonovi. Přítomnost slona je
            někdy součástí svatebního obřadu, protože je zde považován za symbol
            štěstí.
          </p>
          <h2 class='title'>Je svatba na Srí Lance uznávaná českými úřady?</h2>
          <p>
            Ano, sňatek uzavřený na Srí Lance je právoplatný v ČR. Stejně jako
            na Bali ho doprovází trochu úřadování, ovšem nic co bychom společně
            nezvládli. Nejdříve se zajistí superlegalizace českých dokumentů,
            aby mohly být prezentovány úřadům na Srí Lance. Po obřadu a vydání
            oddacího listu na Srí Lance je třeba opačně přes indické Dillí
            vyžádat jeho superlegalizaci pro úřady České republiky. Po příjezdu
            do České republiky je nezbytné oddací list přeložit do českého
            jazyka a požádat matriku v místě svého trvalého bydliště o zápis
            uzavření manželství. Proces superlegalizace se skládá z více
            složitějších kroků, ale je většinou bezproblémový a se všemi
            formalitami a dalšími záležitostmi si dokážeme poradit. Naši
            spokojení klienti oddaní na Srí Lance jsou toho důkazem.
          </p>
          <h2 class='title'>Jaký hotel si pro svatbu vybrat?</h2>
          <p>
            Svatbu či svatební cestu pro Vás zorganizujeme ve Vámi vybraném
            hotelu nebo jiném vybraném místě. Nebudete-li si jisti výběrem, rády
            Vám s ním pomůžeme.
          </p>
          <h2 class='title'>Kdy je třeba svatbu naplánovat?</h2>
          <p>
            Rozhodnete-li se pro uzavření sňatku v této destinaci, kontaktujte
            nás minimálně dva měsíce předem, abychom měly čas pro Vás vše
            potřebné detailně naplánovat. Ideální je začít cestu plánovat půl
            roku dopředu, můžeme tak pro Vás dohodnout cenově výhodnější
            podmínky. Nehovoříte cizím jazykem? Ani to pro nás nepředstavuje
            žádný problém. Na vyžádání Vám můžeme dohodnout služby tlumočníka.
          </p>
        </section>
        <section>
          <p>Je Vaším snem svatba na Srí Lance? Ozvěte se nám.</p>
          <p>Všechno prodiskutujeme a naplánujeme. Těšíme se na Vás.</p>
          <p>Vaše Elixírky</p>
        </section>
      </v-container>
    );
  }
}
