import { ButtonLinkInterface } from 'elixir-theme';
import { Component } from 'vue-property-decorator';

import chooseDestinationBg from '~/assets/images/choose-destination-bg.jpg';
import { HomepageSections } from '~/utils/homepage';
import { VueComponent } from '~/utils/vue-component';

interface ChooseDestination {
  onShowForm?: () => void;
}

const openFormButtonId = 'open-form';

@Component
export default class extends VueComponent<ChooseDestination> {
  protected get header(): string {
    return this.$t('app.destination.chooseDreamDestination').toString();
  }

  protected get subtitle(): string {
    return this.$t('app.destination.chooseDreamDestinationSubtitle').toString();
  }

  protected get buttons(): ButtonLinkInterface[] {
    return [
      {
        name: this.$t('app.destination.choose').toString(),
        routeName: `#${HomepageSections.DESTINATION_LIST}`,
        vuetifyClass: 'tertiary contrast--text',
      },
      {
        name: this.$t('app.wantOffer').toString(),
        id: openFormButtonId,
        vuetifyClass: 'accent contrast--text',
      },
    ];
  }

  public render() {
    const xPadding =
      ['xs', 'sm', 'md'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'px-3'
        : 'px-0';

    return (
      <background-wrapper
        imgUrl={chooseDestinationBg}
        stripeColor='linear-gradient(to bottom, rgba(250,250,250,1) 0%,rgba(255,255,255,0) 100%)'
      >
        <v-container class={`pt-1 ${xPadding}`}>
          <customized-offer-section
            title={this.header}
            subtitle={this.subtitle}
            buttons={this.buttons}
            onButtonClick={this.buttonClick}
          />
        </v-container>
      </background-wrapper>
    );
  }

  protected buttonClick(id: string): void {
    if (id === openFormButtonId) {
      this.$emit('showForm');
    }
  }
}
