import VueI18n from 'vue-i18n';

import { PackageModel } from '~/utils/package/index';
import { AreaModel } from '~/utils/area';
import { HotelModel } from '~/utils/hotel';
import { annotate as annotateCurrency, CurrencyModel } from '~/utils/currency';

export default function transferPrice(
  pkg: PackageModel,
  area: AreaModel | null
): number {
  if (pkg.transferPrice === null && area) {
    return area.transferPrice ? area.transferPrice : 0;
  }

  return pkg.transferPrice ? pkg.transferPrice : 0;
}

export function label(
  pkg: PackageModel,
  hotel: HotelModel,
  i18n: VueI18n,
  currency?: CurrencyModel
): string | undefined {
  if (hotel.hideFlightPrice) {
    return undefined;
  }

  const price = transferPrice(pkg, hotel.area);

  if (!price) {
    return undefined;
  }

  if (!currency) {
    currency = { code: 'CZK' };
  }

  const formattedPrice = annotateCurrency(price, currency);
  return `${i18n.t('app.package.price.flight')}: ${formattedPrice}`;
}
