export default {
  app: {
    hotel: {
      beachesAndActivities: 'Pláže a aktivity',
      board: 'Strava',
      description: 'Popis hotelu',
      enquire: 'Nezávazně poptat',
      enquiry: 'Poptávka',
      header: {
        beach: 'Pláž',
        board: 'Strava',
        rooms: 'Pokoje',
        roomCapacity: '| pro 1 osobu | pro {to} osoby | pro {to} osob',
        roomMultiCapacity:
          '| | pro {from} - {to} osoby | pro {from} - {to} osob',
        roomSize: '{to} m²',
        roomSizeRange: 'od {from} - {to} m²',
      },
      list: {
        loadButton: 'Načíst další nabídky',
      },
      map: 'Destinace',
      moreButtonText: '... Více',
      nextToHotel: 'Přímo u hotelu',
      related: 'Další hotely v nabídce',
      reviews: 'Recenze',
      roomsAndPrices: 'Pokoje a ceny',
      roomType: 'Typ pokoje',
      show: 'Zobrazit hotel',
      showAll: 'Zobrazit všechny hotely',
      showMultiple: 'Zobrazit hotely',
      showMorePackages: 'Zobrazit další pobyty',
      viewMoreTerms: 'Prohlédnout další termíny',
      viewMore: 'Prohlédnout více',
    },
  },
};
