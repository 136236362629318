import { MediaFileInterface } from 'elixir-theme';
import { Component, Prop } from 'vue-property-decorator';

import { GQLImage } from '~/GqlTypes';
import { VueComponent } from '~/utils/vue-component';

interface Gallery {
  galleryItems: GQLImage[];
  loading?: boolean;
}

@Component
export default class extends VueComponent<Gallery> implements Gallery {
  @Prop({ required: true })
  public galleryItems!: GQLImage[];

  @Prop({ default: false })
  public loading!: boolean;

  protected get mediaData(): MediaFileInterface[] {
    if (!this.galleryItems) {
      return [];
    }
    if (this.galleryItems.length > 0) {
      return this.galleryItems.map((mediaItem) => {
        return {
          src: mediaItem.videoUrl ? mediaItem.videoUrl : mediaItem.src,
          srcset: mediaItem.srcset,
          description: mediaItem.description ? mediaItem.description : '',
          isVideo: !!mediaItem.videoUrl,
          thumbnail: mediaItem.src,
        };
      });
    }
    return [];
  }

  public render() {
    if (!this.loading && this.mediaData.length < 1) {
      return;
    }

    if (this.loading) {
      return (
        <div>
          <v-skeleton-loader
            loading={this.loading}
            transition='scale-transition'
            type='image'
          />
          <v-row>
            <v-col cols='4'>
              <v-skeleton-loader type='image' height='135px' />
            </v-col>
            <v-col cols='4'>
              <v-skeleton-loader type='image' height='135px' />
            </v-col>
            <v-col cols='4'>
              <v-skeleton-loader type='image' height='135px' />
            </v-col>
          </v-row>
        </div>
      );
    }

    return (
      <slider
        isLightBox={true}
        mediaFiles={this.mediaData}
        moreImagesText={this.$t('app.hotel.viewMore')}
      />
    );
  }
}
