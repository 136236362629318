import { RouteLocalization } from '~/app/localization';

const routes: RouteLocalization = {
  routes: {
    homepage: {
      name: 'Homepage',
      path: '',
    },
    'search/:fulltext': {
      name: 'Search',
      path: 'search/:fulltext',
    },
    destinations: {
      path: 'destinations',
      name: 'All destinations',
    },
    ':destination': {
      path: ':destination',
      name: 'Destination',
    },
    'special-offer': {
      name: 'Special Offer',
      path: 'special-offer',
    },
  },
};

export default routes;
