import { Component, Mixins } from 'vue-property-decorator';

import galleryItem01 from '~/assets/images/weddings/maledivy/01.jpg';
import galleryItem02 from '~/assets/images/weddings/maledivy/02.jpg';
import galleryItem03 from '~/assets/images/weddings/maledivy/03.jpg';
import galleryItem04 from '~/assets/images/weddings/maledivy/04.jpg';
import galleryItem05 from '~/assets/images/weddings/maledivy/05.jpg';
import GalleryRow from '~/components/organisms/GalleryRow';
import HeadManagement from '~/mixins/HeadManagement';
import { ImageModel } from '~/utils/image';

const gallery: ImageModel[] = [
  galleryItem01,
  galleryItem02,
  galleryItem03,
  galleryItem04,
  galleryItem05,
].map((item) => {
  return {
    src: item,
    srcset: '',
    rank: 0,
    description: '',
  };
});

@Component
export default class Maledivy extends Mixins(HeadManagement) {
  public render() {
    return (
      <v-container class='custom-content'>
        <h1 class='primary--text elix-section-title'>Svatba na Maledivách</h1>
        <GalleryRow items={gallery} />
        <section>
          <h2 class='title'>Proč mít svatbu na Maledivách?</h2>
          <p>
            Stopy v písku smyje voda, ale vzpomínky zůstanou… Rajské Maledivy
            jsou pro Vaši svatbu dokonalou destinací. Dovolujeme si tvrdit, že
            těžko budete hledat tak romantickou atmosféru jinde. Díky levnějším
            letenkám než na Mauricius a Seychely se také lehce dostanete na
            lepší cenu. A neuvěřitelná romantika může začít…
          </p>
          <h2 class='title'>Je svatba na Maledivách uznávaná českými úřady?</h2>
          <p>
            Ne, bohužel. Svatba na Maledivách zatím není právoplatnou v ČR, je
            platná pouze pro osoby muslimského vyznání. Nicméně o legalizaci
            sňatků se již jedná, Maledivy samozřejmě nechtějí zaostávat za
            Seychelami a Mauriciem. Do doby, než bude svatba i tady
            legalizována, můžete prožít alespoň neformální sňatkový obřad či
            obnovení manželského slibu. Legalizaci pak můžete vyřešit
            jednoduchým obřadem na radnici v České republice.
          </p>
          <h2 class='title'>Jaký hotel si pro svatbu vybrat?</h2>
          <p>
            Svatbu či svatební cestu pro Vás zorganizujeme ve Vámi vybraném
            hotelu. Nebudete-li si jisti výběrem, rády Vám s ním pomůžeme.
            Všechny hotely na Mauriciu jsou osobně prověřily a doporučíme Vám
            jen hotel, kde jsme byly maximálně spokojeny. Svěřte nám organizaci
            svatby i líbánek a nebudete litovat. I pro Vás se stane Mauricius
            srdeční záležitostí!
          </p>
          <h2 class='title'>Kdy je třeba svatbu naplánovat?</h2>
          <p>
            Kontaktujte nás minimálně dva měsíce předem. Ideálně ale půl roku
            dopředu, ať máme čas naplánovat každičký detail. Pokud nás
            kontaktujete brzy, svatba i dovolená bude cenově výhodnější.
          </p>
        </section>
        <section>
          <p>
            Tak, už se vidíte v bílém na krásné pláži s Indickým oceánem v
            pozadí? Určitě Vám to bude moc slušet.
          </p>
          <p>
            Ozvěte se nám. Všechno prodiskutujeme a naplánujeme. Těšíme se na
            Vás.
          </p>
          <p>Vaše Elixírky</p>
        </section>
      </v-container>
    );
  }
}
