import WindowResize from './WindowResize';
import { Vue } from 'vue-property-decorator';

export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export enum VisibilityState {
  SHOWN = 'shown',
  HIDDEN = 'hidden',
}

export interface ScrollVisibilityOptions {
  initialState: VisibilityState;
  direction: ScrollDirection;
  element: HTMLElement;
  /**
   * How far does the user need to scroll to fire the show event
   */
  length?: number;
}

export interface ScrollVisibilityEvent {
  windowScroll: {
    above: boolean;
    inside: boolean;
    below: boolean;
  };
}

export default class ScrollVisibility {
  public static lock() {
    ScrollVisibility.scrollLock = true;
  }

  public static unlock() {
    ScrollVisibility.scrollLock = false;
  }

  protected static scrollLock: boolean = false;

  public eventBus: Vue;

  public shown: boolean | null = null;

  public scrollLock: boolean = false;

  protected elementHeight: number = 0;

  protected lastScrollTop: number;

  protected element: HTMLElement;

  protected direction: ScrollDirection;

  protected scrollLength: number = 0;

  protected scrollLengthCounter: number = 0;

  public constructor(options: ScrollVisibilityOptions) {
    this.element = options.element;
    this.shown = options.initialState === VisibilityState.SHOWN;
    this.direction = options.direction;
    this.eventBus = new Vue();

    if (options.length) {
      this.scrollLength = options.length;
    }

    this.computeBounds();
    WindowResize.$on('resize', () => {
      this.computeBounds();
    });

    this.lastScrollTop = (window && window.pageYOffset) || 0;

    this.register();
  }

  public register() {
    if (this.direction === ScrollDirection.UP) {
      document.addEventListener('scroll', this.scrollUp.bind(this));
    } else if (this.direction === ScrollDirection.DOWN) {
      document.addEventListener('scroll', this.scrollDown.bind(this));
    }
  }

  public unregister() {
    document.removeEventListener('scroll', this.scrollUp.bind(this));
    document.removeEventListener('scroll', this.scrollDown.bind(this));
  }

  protected computeBounds() {
    const bounds = this.element.getBoundingClientRect();
    this.elementHeight = bounds.height;
  }

  protected computeTopOffset() {
    let el: HTMLElement | null = this.element;
    let offsetTop: number = 0;

    while (el) {
      offsetTop += el.offsetTop;
      el = el.offsetParent as HTMLElement | null;
    }

    return offsetTop;
  }

  protected scrollUp() {
    if (ScrollVisibility.scrollLock || this.scrollLock) {
      return;
    }

    const windowScrollTop: number = window.pageYOffset;

    if (this.lastScrollTop === windowScrollTop) {
      return;
    }

    this.emitVisibility(this.lastScrollTop > windowScrollTop, windowScrollTop);
    this.lastScrollTop = windowScrollTop;
  }

  protected scrollDown() {
    if (ScrollVisibility.scrollLock || this.scrollLock) {
      return;
    }

    const windowScrollTop: number = window.pageYOffset;

    if (this.lastScrollTop === windowScrollTop) {
      return;
    }

    this.emitVisibility(this.lastScrollTop < windowScrollTop, windowScrollTop);
    this.lastScrollTop = windowScrollTop;
  }

  protected emitVisibility(show: boolean, scrollTop: number) {
    this.computeScrollLength(show, scrollTop);

    if ((show && this.shown === true) || (!show && this.shown === false)) {
      return;
    }

    if (
      show &&
      this.scrollLengthCounter < this.scrollLength &&
      scrollTop !== 0
    ) {
      return;
    }

    const state = show ? VisibilityState.SHOWN : VisibilityState.HIDDEN;

    const topOffset = this.computeTopOffset();
    const bottomOffset = topOffset + this.elementHeight;

    const params: ScrollVisibilityEvent = {
      windowScroll: {
        above: false,
        inside: false,
        below: false,
      },
    };

    if (scrollTop > bottomOffset) {
      params.windowScroll.below = true;
    } else if (scrollTop > topOffset) {
      params.windowScroll.inside = true;
    } else {
      params.windowScroll.above = true;
    }

    this.eventBus.$emit(state, params);
  }

  protected computeScrollLength(show: boolean, scrollTop: number): void {
    if (!this.scrollLength) {
      return;
    }

    if (show) {
      this.scrollLengthCounter += Math.abs(scrollTop - this.lastScrollTop);
    } else {
      this.scrollLengthCounter = 0;
    }
  }
}
