import { RouteConfig } from 'vue-router';

import DataProtection from '~/views/DataProtection';

const config: RouteConfig = {
  component: DataProtection,
  path: 'data-protection',
};

export default config;
