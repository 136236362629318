import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import { format, parse } from '~/utils/date-fns';
import { ValueProp, VueComponent } from '~/utils/vue-component';
import { LocaleMessage } from 'vue-i18n';

interface DatePickerField extends ValueProp<Date | undefined> {
  allowedDates?: (date: Date) => boolean;
  errors?: string[];
  label: string | LocaleMessage;
  onChange?: (value: Date) => void;
  readonly?: boolean;
}

@Component
export default class extends VueComponent<DatePickerField>
  implements DatePickerField {
  @Prop({ required: false })
  public allowedDates?: (date: Date) => boolean;

  @Prop({ default: () => [], type: Array })
  public errors!: string[];

  @Prop({ required: true })
  public label!: string | LocaleMessage;

  @Prop({ required: false })
  public value?: Date;

  @Prop({ required: false, type: Boolean })
  public readonly?: boolean;

  @Ref('datepicker')
  public datepicker?: Vue;

  protected menuState: boolean = false;

  protected get formattedDate(): string {
    if (!this.value) {
      return '';
    }

    return format(this.value);
  }

  protected get selectedDateString(): string {
    if (!this.value) {
      return '';
    }

    return format(this.value, 'YYYY-MM-DD');
  }

  protected set selectedDateString(value: string) {
    const date = parse(value);
    if (date) {
      this.$emit('input', date);
    }
  }

  public render() {
    return (
      <v-menu
        close-on-content-click={false}
        disabled={this.readonly}
        eager
        min-width='290px'
        nudge-right={40}
        offset-y
        scopedSlots={{
          activator: (data: any) => {
            return (
              <div
                {...{
                  on: data.on,
                }}
              >
                <v-text-field
                  class='v-date-range__input-field'
                  error-messages={this.errors}
                  error-count={2}
                  label={this.label}
                  value={this.formattedDate}
                  disabled={this.readonly}
                  readonly
                  append-icon='arrow_drop_down'
                  filled
                />
              </div>
            );
          },
        }}
        transition='scale-transition'
        v-model={this.menuState}
      >
        <v-date-picker
          allowedDates={this.allowedDatesCallback}
          onChange={(value: string) => {
            this.selectedDateString = value;
            this.menuState = false;
            this.$emit('change', this.value);
          }}
          no-title
          ref='datepicker'
          value={this.selectedDateString}
        />
      </v-menu>
    );
  }

  /**
   * @param date
   */
  protected allowedDatesCallback(date: string): boolean {
    let result: boolean = true;

    // Parse the current asking date
    const compareDate = parse(`${date}T00:00:00+00:00`);

    if (this.allowedDates) {
      result = this.allowedDates(compareDate);
    }

    return result;
  }
}
