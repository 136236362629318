export default {
  app: {
    promotions: {
      elixirActive: {
        title: 'Nabízíme skupinové sportovní pobyty',
        cta: 'Nabídka Elixír Active',
        url:
          'https://www.elixiractive.cz/kategorie-produktu/pobytu-v-cr-a-zahranici/',
      },
      offer: {
        description:
          'Naši travel designeři vám podle vašich představ a možností připraví ideální nabídku jen pro Vás.',
        orCallUs: '... anebo nám zavolejte',
        title: 'Nezávazná nabídka na míru',
      },
    },
  },
};
