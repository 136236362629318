import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IntrospectionResultData,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import fetch from 'cross-fetch';
import apiUrl from '~/app/core/apiUrl';

import introspectionSchema from '~/GqlSchema.json';
import appEnv from '~/app/core/appEnv';

const filteredData = introspectionSchema.__schema.types.filter(
  (type: any) => type.possibleTypes !== null
);
introspectionSchema.__schema.types = filteredData;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionSchema as IntrospectionResultData,
});

export default function createApolloClient({
  cookie,
}: {
  cookie: string;
}): ApolloClient<NormalizedCacheObject> {
  const cache = new InMemoryCache({ fragmentMatcher });

  const httpLinkOptions: HttpLink.Options = {
    uri: apiUrl,
    fetch,
    credentials: 'include',
  };

  if (cookie && cookie.length > 0) {
    // Add cookie when passed
    httpLinkOptions.headers = {
      cookie,
    };
  }

  const httpLink = new HttpLink(httpLinkOptions);

  return new ApolloClient({
    link: httpLink,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy:
          appEnv.NODE_ENV === 'development' ? 'no-cache' : 'cache-first',
      },
    },
  });
}
