export default {
  app: {
    benefits: {
      button: 'Představení Elixír tours',
      header: 'Proč si vybrat dovolenou s Elixírem',
      luxury: 'Luxusní exotická střediska máte na dosah',
      specialists: 'Jsme specialisté na Mauricius a Seychely',
      tested: 'Naši traveldesigneři hotely testují',
      verification: 'Ověřujeme nabídku hotelů a fotografie',
    },
  },
};
