import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { Component, Mixins, Watch } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import Benefits from '~/components/organisms/Benefits';
import BlogArticles from '~/components/organisms/BlogArticles';
import ChooseDestination from '~/components/organisms/ChooseDestination';
import CustomizedOfferPromotion from '~/components/organisms/CustomizedOfferPromotion';
import ElixirActivePromotion from '~/components/organisms/ElixirActivePromotion';
import CarouselTips from '~/components/templates/CarouselTips';
import DestinationList from '~/components/templates/DestinationList';
import Header from '~/components/templates/Header';
import PromotedHotels from '~/components/templates/PromotedHotels';
import HeadManagement from '~/mixins/HeadManagement';
import { HomepageSections } from '~/utils/homepage';
import TailoredOfferForm from '~/components/templates/TailoredOfferForm';

@Component
export default class Homepage extends Mixins(HeadManagement) {
  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected displayEnquiryForm: boolean = false;

  public title() {
    return 'Dovolená a zájezdy | CK Elixír Tours';
  }

  public get seoMeta(): string {
    const metas: { name?: string; property?: string; content: string }[] = [];

    metas.push({
      name: 'title',
      content: 'Dovolená a zájezdy | CK Elixír Tours',
    });
    metas.push({
      name: 'twitter:title',
      content: 'Dovolená a zájezdy | CK Elixír Tours',
    });
    metas.push({
      property: 'og:title',
      content: 'Dovolená a zájezdy | CK Elixír Tours',
    });

    metas.push({
      name: 'description',
      content:
        'Elixír tours se specializuje na exotické destinace, jako jsou Seychely, Maledivy, Mauricius a podobně. TOP ceny. Travel specialistky hotely osobně prověřují.',
    });
    metas.push({
      name: 'twitter:description',
      content:
        'Elixír tours se specializuje na exotické destinace, jako jsou Seychely, Maledivy, Mauricius a podobně. TOP ceny. Travel specialistky hotely osobně prověřují.',
    });
    metas.push({
      property: 'og:description',
      content:
        'Elixír tours se specializuje na exotické destinace, jako jsou Seychely, Maledivy, Mauricius a podobně. TOP ceny. Travel specialistky hotely osobně prověřují.',
    });

    return metas
      .map((meta) => {
        return `<meta${meta.name ? ` name="${meta.name}"` : ''}${
          meta.property ? ` property="${meta.property}"` : ''
        } content="${meta.content}">`;
      })
      .join('');
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <CarouselTips />
        <DestinationList id={HomepageSections.DESTINATION_LIST} />
        <PromotedHotels />
        <v-container class='pt-0 px-0'>
          <v-layout wrap>
            <v-flex
              xs12
              md6
              class={`pb-2 ${
                this.$vuetify.breakpoint.name === 'xs' ? 'pr-0' : 'pr-1'
              }`}
            >
              <CustomizedOfferPromotion onShowForm={this.showForm} />
            </v-flex>
            <v-flex
              xs12
              md6
              class={`pb-2 ${
                this.$vuetify.breakpoint.name === 'xs' ? 'pr-0' : 'pl-1'
              }`}
            >
              <ElixirActivePromotion />
            </v-flex>
          </v-layout>
        </v-container>
        <Benefits />
        <BlogArticles />
        <ChooseDestination onShowForm={this.showForm} />
        <v-dialog v-model={this.displayEnquiryForm} fullscreen>
          <v-card flat>
            <v-container>
              <v-row class='fill-height' justify='center' align='center'>
                <v-col>
                  <TailoredOfferForm isMobile={this.isMobile} />
                </v-col>
              </v-row>
            </v-container>
            <v-btn
              icon
              absolute
              top
              right
              onClick={() => (this.displayEnquiryForm = false)}
            >
              <v-icon>$vuetify.icons.close</v-icon>
            </v-btn>
          </v-card>
        </v-dialog>
      </div>
    );
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  protected showForm() {
    this.displayEnquiryForm = true;
  }
}
