import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { Component, Mixins, Watch } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import elixirOwner from '~/assets/images/elixir-owner-w350.jpg';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import TailoredOfferForm from '~/components/templates/TailoredOfferForm';

@Component
export default class About extends Mixins(HeadManagement) {
  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected displayEnquiryForm: boolean = false;

  public title() {
    return this.$t('app.about.pageTitle');
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <h1 class='elix-title accent--text'>Představení Elixír tours</h1>
          <p>
            Elixír tours získal koncesi <strong>cestovní kanceláře</strong> v
            roce <strong>2013</strong> a od té doby patří mezi nejlepší luxusní
            cestovní kanceláře zaměřené na exotické destinace, a to především{' '}
            <strong>Indický oceán a svatby na pláži</strong>. Opírá se o zázemí
            stabilní firmy, která je na trhu <strong>od roku 2006</strong> a
            která je součástí brandu "Elixír" s{' '}
            <strong>20letou zkušeností</strong> s volnočasovými aktivitami žen a
            jejich rodin.
          </p>
          <h2 class='title primary--text'>Naše portfolio</h2>
          <p>
            <strong>Seychely, Mauricius, Maledivy</strong> - to je naše "Velká
            trojka", přičemž první dvě země považujeme za svůj "druhý domov". O
            Seychelách toho víme nejvíce.
          </p>
          <p>
            K nim se postupně připojily{' '}
            <strong>
              Spojené arabské emiráty, Omán, Madagaskar, Reunion, Tanzanie se
              Zanzibarem, Qatar, Thajsko, Vietnam, Laos, Kostarika, Ekvádor a
              Galapágy, Jamajka...
            </strong>
          </p>
          <p>
            S námi si užijí dovolenou plnými doušky{' '}
            <strong>
              manželské páry, rodiny s dětmi, zamilovaní a snoubenci, přátelé i
              ženy na cvičebních pobytech (@elixiractive)
            </strong>
            .
          </p>
          <h2 class='title primary--text'>
            Naše doporučení bude vždy podloženo osobní zkušeností
          </h2>
          <p>
            Stále si zakládáme na tom, že výborně známe všechny "naše"
            destinace. Jezdíme na pravidelné inspekční cesty, navazujeme
            kontakty se spolehlivými, ověřenými místními agenturami a hotely.
            Vyjednáváme pro své klienty nejlepší ceny. Tomu odpovídá i velikost
            naší cestovní kanceláře - jsme střední firma se stabilním zázemím,
            abychom stále dokázali znát dobře to, co nabízíme.
          </p>
          <p>
            Díky naší znalosti prostředí si klienti pochvalují, že s námi šetří
            nejen svůj čas, ale i peníze. Z dovolené se vracejí spokojeni,
            protože dostávají to, co jim bylo přislíbeno.
          </p>
          <h2 class='title primary--text'>Jsme tu pro vás 24/7</h2>
          <p>
            Vy - naši klienti - víte, že se na nás můžete spolehnout - náš
            servis je 24/7. S našimi partnery v destinacích jsme v kontaktu i
            přes WhatsApp, abychom i takto dohlíželi na nerušený průběh Vaší
            dovolené.
          </p>
          <p>
            <strong>Naše poslání</strong> je připravit Vám dovolenou, která Vám
            přinese zasloužený odpočinek, zastavení se v rychlém běhu života a
            uvědomění si, co je pro vás{' '}
            <strong>
              OPRAVDU DŮLEŽITÉ: obejmout své nejdražší, načerpat energii a
              věnovat se sobě a svému zdraví
            </strong>
            .
          </p>
          <p>
            Za tým Elixír tours
            <br />
            s úctou
            <br />
            PhDr. Olga Huspeková
            <br />
            zakladatelka společnosti
          </p>
          <img src={elixirOwner} alt='Olga Huspeková' />
        </v-container>
        <v-dialog v-model={this.displayEnquiryForm} fullscreen>
          <v-card flat>
            <v-container>
              <v-row className='fill-height' justify='center' align='center'>
                <v-col>
                  <TailoredOfferForm isMobile={this.isMobile} />
                </v-col>
              </v-row>
            </v-container>
            <v-btn
              icon
              absolute
              top
              right
              onClick={() => (this.displayEnquiryForm = false)}
            >
              <v-icon>$vuetify.icons.close</v-icon>
            </v-btn>
          </v-card>
        </v-dialog>
      </div>
    );
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }
}
