import appEnv from '~/app/core/appEnv';

declare global {
  interface Window {
    googleMapsApiLoader: Promise<void>;
    googleMapsApiCallback: () => void;
  }
}

export default function(): Promise<void> {
  if (!document || !window) {
    return Promise.reject(new Error('Document or Window is missing.'));
  }

  if (window.googleMapsApiLoader) {
    return window.googleMapsApiLoader;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://maps.googleapis.com/maps/api/js?callback=googleMapsApiCallback&key=${appEnv.GOOGLE_API_KEY}`;

  const head = document.querySelector('head');
  if (!head) {
    return Promise.reject(new Error('Head element was not found.'));
  }

  let loaded: boolean = false;

  window.googleMapsApiLoader = new Promise((resolve, reject) => {
    window.googleMapsApiCallback = () => {
      loaded = true;
      resolve();
    };

    script.onerror = (e) => reject(e);

    head.appendChild(script);
    setTimeout(() => {
      if (!loaded) {
        reject(new Error('Maps api failed to load in the given time'));
      }
    }, 15 * 1000);
  });

  return window.googleMapsApiLoader;
}
