import bali from '~/assets/images/bali.jpg';
import emirates from '~/assets/images/spojene-arabske-emiraty.jpg';
import oman from '~/assets/images/oman.jpg';
import maledives from '~/assets/images/maledivy.jpg';
import mauricius from '~/assets/images/mauricius.jpg';
import seycheles from '~/assets/images/seychely.jpg';
import sriLanka from '~/assets/images/sri-lanka.jpg';
import zanzibar from '~/assets/images/zanzibar.jpg';

export interface SpecialOfferBackground {
  img: string;
  light: boolean;
}

export const backgrounds: { [key: string]: SpecialOfferBackground } = {
  bali: {
    img: bali,
    light: true,
  },
  oman: {
    img: oman,
    light: true,
  },
  maledivy: {
    img: maledives,
    light: true,
  },
  mauricius: {
    img: mauricius,
    light: true,
  },
  seychely: {
    img: seycheles,
    light: true,
  },
  'spojene-arabske-emiraty': {
    img: emirates,
    light: true,
  },
  'sri-lanka': {
    img: sriLanka,
    light: true,
  },
  zanzibar: {
    img: zanzibar,
    light: false,
  },
};

export default function isSpecialOfferBackground(
  data: any
): data is keyof typeof backgrounds {
  return data && typeof data === 'string' && backgrounds.hasOwnProperty(data);
}
