import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Mutation } from 'vuex-module-decorators';

interface Section {
  loading: boolean;
  active: boolean;
}

interface Sections {
  description: Section;
  reviews: Section;
  rooms: Section;
  map: Section;
  enquiry: Section;
  activities: Section;
}

function createSection(): Section {
  return {
    active: false,
    loading: false,
  };
}

@Module({
  name: 'HotelSections',
  stateFactory: true,
  namespaced: true,
})
export default class HotelSections extends AbstractModule {
  protected sections: Sections = {
    description: createSection(),
    reviews: createSection(),
    rooms: createSection(),
    map: createSection(),
    enquiry: createSection(),
    activities: createSection(),
  };

  public get description(): Section {
    return this.sections.description;
  }

  public get reviews(): Section {
    return this.sections.reviews;
  }

  public get rooms(): Section {
    return this.sections.rooms;
  }

  public get map(): Section {
    return this.sections.map;
  }

  public get enquiry(): Section {
    return this.sections.enquiry;
  }

  public get activities(): Section {
    return this.sections.activities;
  }

  @Mutation
  public setDescriptionLoading(status: boolean) {
    this.sections.description.loading = status;
  }

  @Mutation
  public setDescriptionActive(status: boolean) {
    this.sections.description.active = status;
  }

  @Mutation
  public setReviewsLoading(status: boolean) {
    this.sections.reviews.loading = status;
  }

  @Mutation
  public setReviewsActive(status: boolean) {
    this.sections.reviews.active = status;
  }

  @Mutation
  public setRoomsLoading(status: boolean) {
    this.sections.rooms.loading = status;
  }

  @Mutation
  public setRoomsActive(status: boolean) {
    this.sections.rooms.active = status;
  }

  @Mutation
  public setMapLoading(status: boolean) {
    this.sections.map.loading = status;
  }

  @Mutation
  public setMapActive(status: boolean) {
    this.sections.map.active = status;
  }

  @Mutation
  public setEnquiryLoading(status: boolean) {
    this.sections.enquiry.loading = status;
  }

  @Mutation
  public setEnquiryActive(status: boolean) {
    this.sections.enquiry.active = status;
  }

  @Mutation
  public setActivitiesLoading(status: boolean) {
    this.sections.activities.loading = status;
  }

  @Mutation
  public setActivitiesActive(status: boolean) {
    this.sections.activities.active = status;
  }
}
