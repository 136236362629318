export default {
  $vuetify: {
    close: 'Zavřít',
    dataIterator: {
      pageText: '{0}-{1} z {2}',
      noResultsText: 'Nenalezeny žádné záznamy',
      loadingText: 'Nahrávání...',
    },
    dataTable: {
      itemsPerPageText: 'Řádků na stránku:',
      ariaLabel: {
        sortDescending: ': Seřazeno sestupně. Aktivací zrušíte řazení.',
        sortAscending:
          ': Seřazeno vzestupně. Aktivací přepnete na sestupné řazení.',
        sortNone: ': Bez řazení. Aktivací přepnete na vzestupné řazení.',
      },
      sortBy: 'Řazení',
    },
    dataFooter: {
      pageText: '{0}-{1} z {2}',
      itemsPerPageText: 'Položek na stránku:',
      itemsPerPageAll: 'Vše',
      nextPage: 'Další stránka',
      prevPage: 'Předchozí stránka',
      firstPage: 'První stránka',
      lastPage: 'Poslední stránka',
    },
    datePicker: {
      itemsSelected: '{0} vybráno',
    },
    noDataText: 'Žádná data',
    carousel: {
      prev: 'Předchozí zobrazení',
      next: 'Další zobrazení',
    },
    calendar: {
      moreEvents: '{0} další/dalších',
    },
    fileInput: {
      counter: 'souborů: {0}',
      counterSize: 'souborů: {0} ({1} celkem)',
    },
  },
};
