import { RouteConfig } from 'vue-router';

import Weddings from '~/views/Weddings';
import Maledivy from '~/views/weddings/Maledivy';
import SriLanka from '~/views/weddings/SriLanka';
import Seychely from '~/views/weddings/Seychely';
import Mauricius from '~/views/weddings/Mauricius';

const config: RouteConfig = {
  component: Weddings,
  path: 'weddings',
  children: [
    {
      component: Maledivy,
      path: 'maledivy',
      meta: {
        wedding: true,
      },
    },
    {
      component: Mauricius,
      path: 'mauricius',
      meta: {
        wedding: true,
      },
    },
    {
      component: Seychely,
      path: 'seychely',
      meta: {
        wedding: true,
      },
    },
    {
      component: SriLanka,
      path: 'sri-lanka',
      meta: {
        wedding: true,
      },
    },
  ],
};

export default config;
