import Vue from 'vue';
import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';

import { HOTEL_LIST_FRAGMENT } from '~/components/templates/hotel/List';
import { createHotel, HotelModel } from '~/utils/hotel';
import { GQLHotelSort, GQLPackageType, GQLRootQuery } from '~/GqlTypes';
import gql from 'graphql-tag';

const MAX_OFFER_ITEMS: number = 6;

const QUERY = gql`
  query($id: ID!, $first: Int!, $packages: PackageType!, $sort: HotelSort!) {
    destinations {
      get(id: $id) {
        hotels(
          first: $first
          selector: { packageType: $packages }
          sort: $sort
          withAvailablePackages: true
        ) {
          edges {
            node {
              ...HotelFragment
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
  ${HOTEL_LIST_FRAGMENT}
`;

interface GetDestinationOffersInput {
  id: string;
  slug: string;
}

interface GetDestinationOffersVariables {
  id: string;
  first: number;
  packages?: GQLPackageType;
  sort: GQLHotelSort;
}

interface DestinationOfferCommit {
  slug: string;
  hotels: HotelModel[];
}

type GetDestinationOffersCommit = DestinationOfferCommit[];

export interface DestinationLoaderModel {
  items: number;
  sortRank: number;
}

export const destinationLoaders: { [key: string]: DestinationLoaderModel } = {
  seychely: {
    items: MAX_OFFER_ITEMS,
    sortRank: 1,
  },
  maledivy: {
    items: MAX_OFFER_ITEMS,
    sortRank: 2,
  },
  mauricius: {
    items: MAX_OFFER_ITEMS,
    sortRank: 3,
  },
  'spojene-arabske-emiraty': {
    items: MAX_OFFER_ITEMS,
    sortRank: 4,
  },
};

export function getDestinationLoader(
  slug: string
): DestinationLoaderModel | undefined {
  if (destinationLoaders.hasOwnProperty(slug)) {
    return destinationLoaders[slug];
  }
  return undefined;
}

@Module({
  name: 'SpecialOffers',
  stateFactory: true,
  namespaced: true,
})
export default class SpecialOffers extends AbstractModule {
  public loading: boolean = false;

  public destinationHotels: { [key: string]: HotelModel[] } = {};

  public promiseCache: {
    [key: string]: Promise<DestinationOfferCommit[]>;
  } = {};

  public destinationRank: { [key: string]: number } = {};

  @Action({ commit: 'setDestinationOffers', rawError: true })
  public getDestinationOffers(
    data: GetDestinationOffersInput[]
  ): Promise<GetDestinationOffersCommit> {
    const promises: Promise<DestinationOfferCommit>[] = [];
    const key: string = data.map((input) => input.slug).join('|');

    if (this.promiseCache.hasOwnProperty(key)) {
      return this.promiseCache[key];
    }

    this.setLoading(true);

    data.forEach((input) => {
      const defaults = getDestinationLoader(input.slug);

      const variables: GetDestinationOffersVariables = {
        first: defaults ? defaults.items : MAX_OFFER_ITEMS,
        id: input.id,
        packages: GQLPackageType.ACTION,
        sort: GQLHotelSort.BY_PRICE,
      };

      promises.push(
        this.apollo
          .query<GQLRootQuery>({
            query: QUERY,
            variables,
            fetchPolicy: 'no-cache',
          })
          .then((result) => {
            return {
              slug: input.slug,
              hotels: result.data.destinations.get
                ? result.data.destinations.get.hotels.edges.map(({ node }) => {
                    return createHotel(node);
                  })
                : [],
            };
          })
      );
    });

    this.setPromise(promises, key);

    return Promise.all(promises).finally(() => {
      this.setLoading(false);
    });
  }

  @Mutation
  protected setPromise(
    promise: Promise<DestinationOfferCommit>[],
    key: string
  ) {
    Vue.set(this.promiseCache, key, promise);
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setDestinationOffers(data: GetDestinationOffersCommit) {
    const destinationRank: { [key: string]: number } = {};
    for (const loader in destinationLoaders) {
      if (destinationLoaders.hasOwnProperty(loader)) {
        destinationRank[loader] = destinationLoaders[loader].sortRank;
      }
    }
    data.forEach((destination) => {
      Vue.set(this.destinationHotels, destination.slug, destination.hotels);
      if (destinationRank.hasOwnProperty(destination.slug)) {
        return;
      }
      let sortRank = -1;
      for (const key in destinationRank) {
        if (
          destinationRank.hasOwnProperty(key) &&
          sortRank < destinationRank[key]
        ) {
          sortRank = destinationRank[key];
        }
      }
      sortRank++;
      destinationRank[destination.slug] = sortRank;
    });

    this.destinationRank = destinationRank;
  }
}
