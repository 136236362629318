export default {
  app: {
    search: {
      date: {
        label: 'Termín',
        placeholder: 'Vyberte termín',
      },
      destinations: {
        label: 'Destinace',
      },
      errors: {
        selectDate: 'Prosím vyberte termín, ve kterém chcete hledat zájezdy.',
        selectNumberOfNights: 'Prosím vyberte počet nocí.',
      },
      mainTitle: {
        fallback: 'Vyhledávání',
        inLocation: 'Vyhledávání na {location}',
      },
      noResults: 'Nebyly nalezeny žádné výsledky',
      numberOfNights: {
        label: 'Počet nocí',
        placeholder: 'Vyberte počet nocí',
        title: 'Vyberte počet nocí',
      },
    },
  },
};
