export default {
  app: {
    page: {
      consent: {
        slug: 'souhlas-se-zpracovanim-osobnich-udaju',
        title: 'Souhlas se zpracováním osobních údajů',
      },
    },
  },
};
