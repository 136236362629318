import {
  ImageInterface,
  OfferDetailLineInterface,
  RoomDetailBlockInterface,
} from 'elixir-theme';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import { annotate as annotateCurrency } from '~/utils/currency';
import { HotelModel } from '~/utils/hotel';
import { RoomModel } from '~/utils/room';
import { VueComponent } from '~/utils/vue-component';
import { getModule } from 'vuex-module-decorators';
import Hotel from '~/app/core/store/modules/Hotel';
import {
  getFlightLabel,
  getTransferLabel,
  PackageModel,
} from '~/utils/package';

export interface ChosenPackageEvent {
  package: PackageModel | null;
}

interface RoomBlock {
  hotel: HotelModel;
  onChosenPackage?: (data: ChosenPackageEvent) => void;
  room: RoomModel;
}

@Component
export default class extends VueComponent<RoomBlock> implements RoomBlock {
  @Prop({ required: true })
  public hotel!: HotelModel;

  @Prop({ required: true })
  public room!: RoomModel;

  protected loadedPackages: boolean = false;

  protected loadingPackages: boolean = false;

  protected limitTwoPackageView: boolean = true;

  protected get offers(): OfferDetailLineInterface[] {
    let packages = this.room.packages.map((pkg) => {
      return {
        additionalInfo: pkg.description,
        callToAction: {
          name: this.$t('app.common.enquire').toString(),
        },
        endDate: pkg.to,
        flightPrice: getFlightLabel(pkg, this.hotel, this.$i18n),
        foodServiceType: pkg.board,
        id: pkg.id,
        offerPrice:
          this.$t('app.package.price.accommodation') +
          `: ${annotateCurrency(pkg.accommodationPrice, { code: 'CZK' })}`,
        startDate: pkg.from,
        totalPrice:
          this.$t('app.package.price.total') +
          `: ${annotateCurrency(pkg.price, { code: 'CZK' })}`,
        transferPrice: getTransferLabel(pkg, this.hotel, this.$i18n),
      };
    });

    if (this.limitTwoPackageView) {
      packages = packages.slice(0, 2);
    }

    return packages;
  }

  protected get hideLoadMoreButton(): boolean {
    return this.loadedPackages || this.offers.length < 2;
  }

  protected get images(): ImageInterface[] {
    return this.room.images.map((image) => {
      return {
        src: image.src,
        srcSet: image.srcset,
        alt: image.description,
      };
    });
  }

  protected get bottombutton(): string {
    return this.$t('app.hotel.showMorePackages').toString();
  }

  public render() {
    const attrs: RoomDetailBlockInterface = {
      roomName: this.room.name,
      roomDescription: this.room.content,
      offers: this.offers,
      images: this.images,
      bottomButtonText: this.bottombutton,
    };

    return (
      <room-detail-block
        {...{ attrs }}
        onLoadMoreOffers={this.loadMoreOffers}
        onOfferDetailClick={this.offerDetailClick}
        isBottomButtonLoading={this.loadingPackages}
        isLoadMoreButtonHidden={this.hideLoadMoreButton}
      />
    );
  }

  /**
   * TODO: Implement better paging, e.g. 10 on each button press
   */
  protected loadMoreOffers() {
    this.loadingPackages = true;

    getModule(Hotel, this.$store)
      .loadRoomPackages({ roomId: this.room.id })
      .finally(() => {
        this.loadedPackages = true;
        this.limitTwoPackageView = false;
        this.loadingPackages = false;
      });
  }

  @Emit('chosenPackage')
  protected offerDetailClick(id: PackageModel['id']): ChosenPackageEvent {
    for (const pkg of this.room.packages) {
      if (pkg.id === id) {
        return {
          package: pkg,
        };
      }
    }

    return { package: null };
  }
}
