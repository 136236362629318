import { GQLPackage } from '~/GqlTypes';
import { parse } from '~/utils/date-fns';

import {
  label as getFlightLabel,
  default as getFlightPrice,
} from './flightPrice';
import {
  label as getTransferLabel,
  default as getTransferPrice,
} from './transferPrice';

interface PackageModel {
  board: string;
  description: string;
  from: Date;
  id: string;
  numberOfNights: number;
  to: Date;
  // Prices
  accommodationPrice: number;
  flightTicketPrice: number | null;
  price: number;
  roomType: string;
  transferPrice: number | null;
}

export default function createPackage(data: GQLPackage): PackageModel {
  let roomType = '';

  if (data.occupancy && data.occupancy.room) {
    roomType = `${data.occupancy.room.name}`;
    if (data.occupancy.room.area) {
      roomType = `${roomType} - ${data.occupancy.room.area}m²`;
    }
  }

  return {
    board: data.board && data.board.name ? data.board.name : '',
    description: data.description ? data.description : '',
    from: parse(data.validFrom),
    id: data.id,
    numberOfNights: data.numberOfNights ? data.numberOfNights : 0,
    to: parse(data.validTo),
    // Prices
    accommodationPrice: data.priceAccommodation
      ? data.priceAccommodation.amount
      : 0,
    flightTicketPrice: data.priceFlyTicket ? data.priceFlyTicket.amount : null,
    price: data.priceTotal ? data.priceTotal.amount : 0,
    roomType,
    transferPrice: data.priceTransfer ? data.priceTransfer.amount : null,
  };
}

export {
  getFlightLabel,
  getFlightPrice,
  getTransferLabel,
  getTransferPrice,
  PackageModel,
};
