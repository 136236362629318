import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import gql from 'graphql-tag';
import { createHotel, HotelModel } from '~/utils/hotel';
import { GQLHotelConnection, GQLPageInfo, GQLRootQuery } from '~/GqlTypes';
import ApolloClient from 'apollo-client';

const HOTELS_QUERY = gql`
  query($after: String) {
    hotels {
      list(first: 100, after: $after, visibility: PUBLISHED) {
        edges {
          node {
            id
            name
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

interface SetHotelsCommit {
  hotels: HotelModel[];
}

function loadAllHotels(
  data: GQLHotelConnection,
  apollo: ApolloClient<any>
): Promise<HotelModel[]> {
  if (!data.pageInfo.hasNextPage) {
    return Promise.resolve(data.edges.map((edge) => createHotel(edge.node)));
  }

  return apollo
    .query<GQLRootQuery>({
      query: HOTELS_QUERY,
      variables: {
        after: data.pageInfo.endCursor ? data.pageInfo.endCursor : null,
      },
    })
    .then((result) => {
      return loadAllHotels(result.data.hotels.list, apollo);
    });
}

@Module({
  name: 'Hotels',
  stateFactory: true,
  namespaced: true,
})
export default class Hotels extends AbstractModule {
  public hotels: HotelModel[] = [];

  protected loadingPromise: Promise<SetHotelsCommit> | null = null;

  @Action({ commit: 'setHotels', rawError: true })
  public loadHotels() {
    if (this.hotels.length > 0) {
      return Promise.resolve({
        hotels: this.hotels,
      });
    }

    if (this.loadingPromise) {
      return this.loadingPromise;
    }

    const pageInfo: GQLPageInfo = {
      hasPreviousPage: false,
      hasNextPage: true,
      endCursor: '',
    };

    const promise = loadAllHotels(
      {
        edges: [],
        totalCount: 0,
        pageInfo,
      },
      this.apollo
    ).then((hotels) => {
      return {
        hotels,
      };
    });

    this.setLoadingPromise(promise);

    return promise;
  }

  @Mutation
  protected setHotels(data: SetHotelsCommit) {
    this.hotels = data.hotels;
  }

  @Mutation
  protected setLoadingPromise(promise: Promise<SetHotelsCommit> | null) {
    this.loadingPromise = promise;
  }
}
