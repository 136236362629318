import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import { DestinationModel } from '~/utils/destination';
import { GQLRootQuery } from '~/GqlTypes';
import gql from 'graphql-tag';
import { createDestination, isGqlDestination } from '~/utils/destination';

interface LoadDestinationByPath {
  path: string;
}

interface SetDestinationCommit {
  destination: DestinationModel | null;
  path: string | null;
}

const DESTINATION_QUERY = gql`
  query($path: String!) {
    routes {
      get(slug: $path) {
        ... on Destination {
          canonicalSlug
          featuredImage {
            description
            src
            srcset
          }
          id
          name
        }
      }
    }
  }
`;

@Module({
  name: 'DestinationSearch',
  stateFactory: true,
  namespaced: true,
})
export default class extends AbstractModule {
  public loading: boolean = false;
  public destination: DestinationModel | null = null;

  protected currentPath: string | null = null;

  @Action({ commit: 'setDestination', rawError: true })
  public loadDestinationByPath(
    data: LoadDestinationByPath
  ): Promise<SetDestinationCommit> {
    if (this.currentPath === data.path) {
      return Promise.resolve({
        destination: this.destination,
        path: data.path,
      });
    }

    this.setLoading(true);

    return this.apollo
      .query<GQLRootQuery>({
        query: DESTINATION_QUERY,
        variables: {
          path: data.path,
        },
      })
      .then((result) => {
        if (isGqlDestination(result.data.routes.get)) {
          return {
            destination: createDestination(result.data.routes.get),
            path: data.path,
          };
        }

        return {
          destination: null,
          path: null,
        };
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setDestination(data: SetDestinationCommit) {
    this.destination = data.destination;
    this.currentPath = data.path;
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }
}
