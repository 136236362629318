const thousands = '.';
const decimal = ',';
const decimals = 2;

/**
 * Formats the input number to currency string
 */
export default function(amount: number): string {
  // First get fixed decimal amount
  const fixed = amount.toFixed(decimals);
  // Parse the decimal part, eg 6468.61 => 61
  let dec = fixed.replace(/^.*\./, '');
  // Parse the base part of the currency, eg 6468.61 => 6468
  let base = parseInt(fixed, 10).toString();

  if (base === '0') {
    return `0${decimal}${dec}`;
  }

  // Because we need to split the string every three letters from the end of the amount
  // we will create zero padding at the beginning, so the string is divisible by 3
  const threes = 3 - (base.length % 3);
  if (threes > 0 && threes < 3) {
    base = '0'.repeat(threes) + base;
  }

  // Now split the string every 3 characters
  const parts = base.match(/.{1,3}/g) || [];

  // Now remove the zero padding from the first array item
  parts[0] = parts[0].replace(/^0+/, '');

  // And join the parts as a base with the separator
  base = parts.join(thousands);

  // Convert the zero decimals to a dash
  if (dec === '00') {
    dec = '-';
  }

  // And return the string with separator between base and decimals
  return `${base}${decimal}${dec}`;
}
