import { ButtonWithIconInterface, DestinationInterface } from 'elixir-theme';
import gql from 'graphql-tag';
import { Component, Prop } from 'vue-property-decorator';

import { getLocalizedUrl } from '~/app/core/router';
import destinationBg from '~/assets/images/destination-bg.jpg';
import { GQLRootQuery } from '~/GqlTypes';
import { createDestination } from '~/utils/destination';
import { VueComponent } from '~/utils/vue-component';

interface DestinationList {
  id?: string | null;
}

export enum Buttons {
  // When user clicks "More about destination", destination ID is prefixed
  MORE_ABOUT_PREFIX = 'MORE_ABOUT_',
  SHOW_ALL_HOTELS = 'SHOW_ALL_HOTELS',
  // When user clicks "Show hotels" in destination, destination ID is prefixed
  SHOW_HOTELS_PREFIX = 'SHOW_HOTELS_',
}

@Component
export default class extends VueComponent<DestinationList>
  implements DestinationList {
  @Prop({ default: null, type: String })
  public id!: string | null;

  protected loading: boolean = true;

  protected destinations: DestinationInterface[] = [];

  protected buttons: ButtonWithIconInterface[] = [];

  protected get header(): string {
    return this.$t('app.destination.offer').toString();
  }

  public mounted() {
    this.$apollo
      .query<GQLRootQuery>({
        query: gql`
          query {
            destinations {
              list(
                first: 12
                featuredFirst: true
                selector: { onlyWithListImage: true }
              ) {
                edges {
                  node {
                    canonicalSlug
                    id
                    listImage {
                      src
                      srcset
                      description
                    }
                    name
                  }
                }
              }
            }
          }
        `,
      })
      .then((result) => {
        this.destinations = [];
        result.data.destinations.list.edges.forEach((edge) => {
          const destination = createDestination(edge.node);

          const bottomButtons: ButtonWithIconInterface[] = [];

          // TODO: Add link to destination info page
          bottomButtons.push({
            flat: true,
            iconName: 'chevron_right',
            name: Buttons.SHOW_HOTELS_PREFIX + destination.id,
            routeName: getLocalizedUrl(destination.slug, this.$router),
            text: this.$t('app.hotel.showMultiple').toString(),
            vuetifyClass: 'tertiary',
            vuetifyTextClass: 'contrast',
          });

          if (!destination.listImage) {
            return;
          }

          this.destinations.push({
            bottomButtons,
            image: {
              alt: destination.listImage.description,
              src: destination.listImage.src,
              srcSet: destination.listImage.srcset,
            },
            name: destination.id,
            title: destination.name,
          });
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public render() {
    if (!this.loading && this.destinations.length < 1) {
      return;
    }

    return (
      <background-wrapper
        id={this.id ? this.id : undefined}
        imgUrl={destinationBg}
        stripeColor='linear-gradient(to bottom, rgba(250,250,250,1) 0%,rgba(255,255,255,0) 100%)'
        imgPosition='bottom center'
      >
        <v-container class='pt-2 px-2'>
          {(() => {
            if (this.loading) {
              return (
                <h3 class='primary--text mt-6 mb-4 elix-title text-center text-sm-left'>
                  {this.header}
                </h3>
              );
            }
          })()}
          <v-skeleton-loader
            loading={this.loading}
            max-width={500}
            transition='scale-transition'
            type='card'
          >
            <destination-list
              header={this.header}
              destinations={this.destinations}
              buttons={this.buttons}
            />
          </v-skeleton-loader>
        </v-container>
      </background-wrapper>
    );
  }
}
