import { SocialLinksEnum } from 'elixir-theme';

interface SocialLink {
  enabled: boolean;
  icon: string;
  url: string;
  name: string;
}

export const SOCIAL_LINKS: {
  [key: string]: SocialLink;
  facebook: SocialLink;
  instagram: SocialLink;
  twitter: SocialLink;
  youtube: SocialLink;
} = {
  facebook: {
    enabled: true,
    icon: SocialLinksEnum.Facebook,
    name: 'Facebook',
    url: 'app.social.facebookUrl',
  },
  instagram: {
    enabled: true,
    icon: SocialLinksEnum.Instagram,
    name: 'Instagram',
    url: 'app.social.instagramUrl',
  },
  twitter: {
    enabled: false,
    icon: SocialLinksEnum.Twitter,
    name: 'Twitter',
    url: 'app.social.twitterUrl',
  },
  youtube: {
    enabled: true,
    icon: SocialLinksEnum.YouTube,
    name: 'YouTube',
    url: 'app.social.youtubeUrl',
  },
};
