import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { TabIconInterface } from 'elixir-theme';
import { Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import HotelSectionsModule from '~/app/core/store/modules/HotelSections';
import { HotelSections } from '~/utils/hotel';
import { VueComponent } from '~/utils/vue-component';

export interface StepEvent {
  id: HotelSections;
}

export enum Events {
  STEPPED_IN = 'STEPPED_IN',
  STEPPED_OUT = 'STEPPED_OUT',
}

@Component
export default class extends VueComponent<{}> {
  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  // TODO: Make the elements active while scrolling over the sections
  protected activeElementId: string = '';

  protected get tabIcons(): TabIconInterface[] {
    const hotelSections = getModule(HotelSectionsModule, this.$store);

    const tabs: TabIconInterface[] = [];

    if (hotelSections.description.active) {
      tabs.push({
        iconName: '$vuetify.icons.hotelDescription',
        isActive: false,
        link: HotelSections.DESCRIPTION,
        loading: hotelSections.description.loading,
        text: this.$t('app.hotel.description').toString(),
        vuetifyClassIcon: 'primary',
        vuetifyClassUnderline: 'tertiary',
      });
    }

    if (hotelSections.reviews.active) {
      tabs.push({
        iconName: '$vuetify.icons.review',
        isActive: true,
        link: HotelSections.REVIEWS,
        loading: hotelSections.reviews.loading,
        text: this.$t('app.hotel.reviews').toString(),
        vuetifyClassIcon: 'primary',
        vuetifyClassUnderline: 'tertiary',
      });
    }

    if (hotelSections.rooms.active) {
      tabs.push({
        iconName: '$vuetify.icons.hotelPrice',
        isActive: false,
        link: HotelSections.ROOMS,
        loading: hotelSections.rooms.loading,
        text: this.$t('app.hotel.roomsAndPrices').toString(),
        vuetifyClassIcon: 'primary',
        vuetifyClassUnderline: 'tertiary',
      });
    }

    if (hotelSections.map.active) {
      tabs.push({
        iconName: '$vuetify.icons.mapIcon',
        isActive: false,
        link: HotelSections.MAP,
        loading: hotelSections.map.loading,
        text: this.$t('app.hotel.map').toString(),
        vuetifyClassIcon: 'primary',
        vuetifyClassUnderline: 'tertiary',
      });
    }

    if (hotelSections.enquiry.active) {
      tabs.push({
        iconName: '$vuetify.icons.shellStar',
        isActive: false,
        link: HotelSections.ENQUIRY_FORM,
        loading: hotelSections.enquiry.loading,
        text: this.$t('app.hotel.enquiry').toString(),
        vuetifyClassIcon: 'primary',
        vuetifyClassUnderline: 'tertiary',
      });
    }

    if (hotelSections.activities.active) {
      tabs.push({
        iconName: '$vuetify.icons.beach',
        isActive: false,
        link: HotelSections.ACTIVITIES,
        loading: hotelSections.activities.loading,
        text: this.$t('app.hotel.beachesAndActivities').toString(),
        vuetifyClassIcon: 'primary',
        vuetifyClassUnderline: 'tertiary',
      });
    }

    return tabs;
  }

  public beforeMount() {
    this.setMobile();
    this.$on(Events.STEPPED_IN, this.setActive);
    this.$on(Events.STEPPED_OUT, this.deactivate);
  }

  public beforeDestroy() {
    this.$off(Events.STEPPED_IN, this.setActive);
    this.$off(Events.STEPPED_OUT, this.deactivate);
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  public render() {
    return (
      <v-container class='px-0 pb-0 tab-icons hotel-navigation'>
        <v-layout wrap>
          <v-flex xs12 lg9 class={this.isMobile ? 'px-2' : ''}>
            <tab-icons-list
              icons={this.tabIcons}
              activeLink={this.activeElementId}
            />
          </v-flex>
        </v-layout>
      </v-container>
    );
  }

  protected setActive(event: StepEvent) {
    this.activeElementId = event.id;
  }

  protected deactivate() {
    this.activeElementId = '';
  }
}
