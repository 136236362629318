import {
  MenuItemsInterface as MenuItems,
  MenuLinkInterface,
} from 'elixir-theme';
import { getModule } from 'vuex-module-decorators';
import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator';

import { getLocalizedUrl } from '~/app/core/router';
import Destinations from '~/app/core/store/modules/Destinations';
import Menu from '~/app/core/store/modules/Menu';
import logo from '~/assets/logo.png';
import { SOCIAL_LINKS } from '~/utils/social';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { DestinationModel } from '~/utils/destination';
import { routes } from '~/app/core/router/routes';
import { getLocalizedRoute } from '~/app/core/router/routes';

@Component
export default class Header extends Mixins<PrefetchComponent>(Prefetch) {
  protected menuItems!: MenuItems;

  protected menuModule: Menu | null = null;

  protected destinationModule: Destinations | null = null;

  @Ref('navBar')
  protected navBar: Vue | undefined;

  protected get logoLink(): string {
    return getLocalizedUrl('/', this.$router);
  }

  protected get menuDestinations(): DestinationModel[] {
    if (!this.menuModule) {
      return [];
    }

    return this.menuModule.menuDestinations;
  }

  protected get bottomBarLinks(): MenuLinkInterface[] {
    const menu: MenuLinkInterface[] = this.menuDestinations.map(
      (destination) => {
        return {
          name: destination.name,
          routeName: getLocalizedUrl(destination.slug, this.$router),
        };
      }
    );

    menu.unshift({
      name: this.$t('app.header.menu.specialOffer').toString(),
      // TODO: Localize this
      routeName: getLocalizedUrl('akce', this.$router),
    });

    menu.push({
      name: this.$t('app.header.menu.allDestinations').toString(),
      // TODO: Localize this
      routeName: getLocalizedUrl('destinace', this.$router),
    });

    menu.push({
      name: this.$t('app.header.menu.weddings').toString(),
      routeName: getLocalizedRoute(routes.weddings, this.$router),
      hasIcon: true,
      icon: '$vuetify.icons.beach',
    });

    return menu;
  }

  public prefetch() {
    if (!this.menuModule || !this.destinationModule) {
      return Promise.resolve();
    }

    return Promise.all([
      this.menuModule.loadHeaderMenuDestinations(),
      this.destinationModule.loadDestinations(),
    ]);
  }

  public created() {
    this.menuModule = getModule(Menu, this.$store);
    this.destinationModule = getModule(Destinations, this.$store);

    this.menuItems = {
      newsletterLink: {
        href: this.$t('app.newsletterLink.href').toString(),
        label: this.$t('app.newsletterLink.label').toString(),
        vuetifyClass: 'mr-auto',
      },
      phoneNumber: this.$t('app.contacts.phone').toString(),
      searchButtonText: this.$t('app.header.fulltext.buttonQuery').toString(),
      searchTitle: this.$t('app.header.fulltext.title').toString(),
      searchPlaceholder: this.$t('app.header.fulltext.placeholder').toString(),
      searchOptionButtons: [],
      searchDestinationList: this.destinationModule.destinations.map(
        (destination) => {
          return destination.name;
        }
      ),
      topBarLinks: [
        {
          href: true,
          name: this.$t('app.blog.title').toString(),
          routeName: this.$t('app.blog.url').toString(),
          target: '_blank',
        },
        // TODO: Fetch links from administration
        {
          name: 'Reference',
          routeName: getLocalizedRoute(routes.references, this.$router),
        },
        {
          name: 'FAQ',
          routeName: getLocalizedRoute(routes.faq, this.$router),
        },
        {
          name: this.$t('app.about.about').toString(),
          routeName: getLocalizedRoute(routes.about, this.$router),
        },
        {
          name: this.$t('app.contact.contacts').toString(),
          routeName: getLocalizedRoute(routes.contact, this.$router),
        },
      ],
      topBarSocialIcons: [],
      searchText: this.$t('app.header.fulltext.button').toString(),
      bottomBarLinks: [],
    };

    if (this.$router.currentRoute.params.destination !== 'destinace') {
      this.menuItems.searchOptionButtons.push({
        name: this.$t('app.header.fulltext.byParameters').toString(),
        // TODO: Localize this
        routeName: getLocalizedUrl('destinace', this.$router),
        multiline: true,
      });
    }

    for (const social in SOCIAL_LINKS) {
      if (!SOCIAL_LINKS.hasOwnProperty(social)) {
        continue;
      }

      const link = SOCIAL_LINKS[social];

      if (!link.enabled) {
        continue;
      }

      this.menuItems.topBarSocialIcons.push({
        iconName: `$vuetify.icons.${social}`,
        routeName: this.$t(link.url).toString(),
        color: 'primary',
      });
    }
  }

  public render() {
    return (
      <nav-bar
        ref='navBar'
        items={this.menuItems}
        logoWidth={180}
        homeIconLink={getLocalizedUrl('', this.$router)}
        mobileLogoWidth={100}
        logoUrl={logo}
        logoLink={this.logoLink}
        onSubmitSearch={this.submitSearch}
      />
    );
  }

  @Watch('bottomBarLinks')
  protected watchDestinations() {
    this.menuItems.bottomBarLinks = this.bottomBarLinks;
    if (this.navBar) {
      this.navBar.$forceUpdate();
    }
  }

  protected submitSearch(value: string | null) {
    if (value && this.destinationModule) {
      for (const destination of this.destinationModule.destinations) {
        if (destination.name.toLowerCase() === value.toLowerCase()) {
          this.$router.push(getLocalizedUrl(destination.slug, this.$router));

          if (this.navBar) {
            this.navBar.$emit('hideSearch');
          }

          return;
        }
      }
    }

    const route = getLocalizedRoute(routes.fulltextSearch, this.$router);
    this.$router.push({ ...route, params: { fulltext: value ? value : '' } });

    if (this.navBar) {
      this.navBar.$emit('hideSearch');
    }
  }
}
