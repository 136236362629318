import {
  ButtonLinkInterface,
  ButtonWithIconInterface,
  ItemDescriptionItemInterface,
  TripDetailsInterface,
} from 'elixir-theme';
import { Component, Emit, Prop } from 'vue-property-decorator';

import { ChosenPackageEvent } from '~/components/organisms/hotel/RoomBlock';
import { annotate as annotateCurrency } from '~/utils/currency';
import { format } from '~/utils/date-fns';
import { HotelModel, HotelSections } from '~/utils/hotel';
import {
  getFlightPrice,
  getTransferPrice,
  PackageModel,
} from '~/utils/package';
import { VueComponent } from '~/utils/vue-component';

interface PromoPackage {
  hotel: HotelModel;
  onChosenPromoPackage: (data: ChosenPackageEvent) => void;
  onMoreOffersClick: () => void;
  onPersonalizedOfferClick: () => void;
  promoPackage: PackageModel;
}

export enum BottomButtons {
  VIEW_MORE = 'VIEW_MORE',
  OFFER_REQUEST = 'OFFER_REQUEST',
}

@Component
export default class extends VueComponent<PromoPackage>
  implements PromoPackage {
  @Prop({ required: true })
  public hotel!: HotelModel;

  @Prop({ required: true })
  public promoPackage!: PackageModel;

  protected get date(): ItemDescriptionItemInterface {
    return {
      description: this.$t('app.package.season').toString(),
      content: `${format(this.promoPackage.from)} - ${format(
        this.promoPackage.to
      )}`,
    };
  }

  protected get flightPrice(): ItemDescriptionItemInterface {
    if (this.hotel.hideFlightPrice) {
      return {
        description: '',
        content: '',
      };
    }

    return {
      description: this.$t('app.package.price.flight').toString(),
      content: annotateCurrency(
        getFlightPrice(this.promoPackage, this.hotel.destination),
        { code: 'CZK' }
      ),
    };
  }

  protected get foodServiceType(): ItemDescriptionItemInterface {
    return {
      description: this.$t('app.hotel.board').toString(),
      content: this.promoPackage.board,
    };
  }

  protected get roomType(): ItemDescriptionItemInterface {
    return {
      description: this.$t('app.hotel.roomType').toString(),
      content: this.promoPackage.roomType,
    };
  }

  protected get roomPrice(): ItemDescriptionItemInterface {
    if (this.hotel.hideFlightPrice && this.hotel.hideTransferPrice) {
      // Hide room price if the other prices are hidden
      return {
        description: '',
        content: '',
      };
    }

    return {
      description: this.$t('app.package.price.accommodation').toString(),
      content: annotateCurrency(this.promoPackage.accommodationPrice, {
        code: 'CZK',
      }),
    };
  }

  protected get transferPrice(): ItemDescriptionItemInterface {
    if (this.hotel.hideTransferPrice) {
      return {
        description: '',
        content: '',
      };
    }

    return {
      description: this.$t('app.package.price.transfer').toString(),
      content: annotateCurrency(
        getTransferPrice(this.promoPackage, this.hotel.area),
        { code: 'CZK' }
      ),
    };
  }

  protected get totalPrice(): ItemDescriptionItemInterface {
    return {
      description: this.$t('app.package.price.total').toString(),
      content: annotateCurrency(this.promoPackage.price, { code: 'CZK' }),
      tooltip: this.$t('app.package.price.totalDescription').toString(),
    };
  }

  protected get additionalInfo(): string {
    return this.promoPackage.description;
  }

  protected get callToAction(): ButtonLinkInterface {
    return {
      name: this.$t('app.hotel.enquire').toString(),
    };
  }

  protected get buttonsBottom(): ButtonWithIconInterface[] {
    return [
      {
        text: this.$t('app.hotel.viewMoreTerms').toString(),
        iconName: 'chevron_right',
        name: BottomButtons.VIEW_MORE,
        vuetifyTextClass: 'primary',
      },
      {
        text: this.$t('app.wantOffer').toString(),
        iconName: 'chevron_right',
        name: BottomButtons.OFFER_REQUEST,
        vuetifyTextClass: 'primary',
      },
    ];
  }

  protected get tripDetails(): TripDetailsInterface {
    return {
      additionalInfo: this.additionalInfo,
      buttonsBottom: this.buttonsBottom,
      callToAction: this.callToAction,
      date: this.date,
      foodServiceType: this.foodServiceType,
      flightPrice: this.flightPrice,
      roomPrice: this.roomPrice,
      roomType: this.roomType,
      totalPrice: this.totalPrice,
      transferPrice: this.transferPrice,
    };
  }

  public render() {
    return (
      <trip-details
        class='mt-3 mb-3'
        {...{ attrs: this.tripDetails }}
        onTripDetailButtonClick={this.onChosenPromoPackage}
        onTripDetailButtonsClick={this.onButtonsClick}
      />
    );
  }

  @Emit('chosenPromoPackage')
  public onChosenPromoPackage(): ChosenPackageEvent {
    return {
      package: this.promoPackage,
    };
  }

  @Emit('moreOffersClick')
  public onMoreOffersClick() {
    return;
  }

  @Emit('personalizedOfferClick')
  public onPersonalizedOfferClick() {
    return;
  }

  protected onButtonsClick(name: string): ChosenPackageEvent {
    switch (name) {
      case BottomButtons.OFFER_REQUEST:
        this.onPersonalizedOfferClick();
        break;
      case BottomButtons.VIEW_MORE:
        this.onMoreOffersClick();
        break;
    }
    return {
      package: this.promoPackage,
    };
  }
}
