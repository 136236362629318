import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { getLocalizedRoute, routes } from '~/app/core/router/routes';

interface CookieBarInterface {
  open: boolean;
  onAccept?: () => void;
}

const rootClass = 'elix-cookie-bar';

// Add vuetify component interface so that scrollHack function can call bottom sheet function
interface VBottomSheet extends Vue {
  showScroll: () => void;
}

@Component
export default class extends VueComponent<CookieBarInterface>
  implements CookieBarInterface {
  @Prop({ required: true, type: Boolean })
  public open!: boolean;

  @Ref('sheet')
  protected sheet!: VBottomSheet;

  public render() {
    return (
      <div class={rootClass}>
        <v-bottom-sheet
          attach={`.${rootClass}`}
          hide-overlay={true}
          persistent={true}
          no-click-animation={true}
          retain-focus={false}
          value={this.open}
          eager={true}
          ref='sheet'
        >
          <v-sheet class={`text-center pa-8 ${rootClass}__content`}>
            <i18n path='app.cookies.consent' tag='p'>
              <router-link
                to={getLocalizedRoute(routes.dataProtection, this.$router)}
                slot='consentLink'
              >
                {this.$t('app.cookies.consentLink')}
              </router-link>
            </i18n>

            <button-with-icon
              name='cookie-accept'
              text={this.$t('app.common.close')}
              iconName='check'
              vuetifyTextClass='white--text'
              class='ml-5'
              onButtonClick={() => this.$emit('accept')}
            />
          </v-sheet>
        </v-bottom-sheet>
      </div>
    );
  }

  /**
   * Hack before bottom sheet scroll is fixed
   * @see https://github.com/vuetifyjs/vuetify/issues/6495
   * @protected
   */
  @Watch('open')
  protected scrollHack() {
    this.$nextTick(() => {
      this.sheet.showScroll();
    });
  }
}
