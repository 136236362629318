import gql from 'graphql-tag';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { default as HotelSectionsModule } from '~/app/core/store/modules/HotelSections';
import { DestinationPreviewBlock } from '~/components/organisms/destination';
import { GQLRootQuery } from '~/GqlTypes';
import { DestinationModel } from '~/utils/destination';
import { VueComponent } from '~/utils/vue-component';
import { createHotel } from '~/utils/hotel';
import { GeoPosition } from '~/utils/GeoPosition';

interface DestinationMapSection {
  hotelId: string;
}

const LOAD_DESTINATION_INFO_QUERY = gql`
  query($id: ID!) {
    hotels {
      get(id: $id) {
        area {
          id
          destination {
            id
            name
            excerpt
            content
            listImage {
              description
              src
              srcset
            }
          }
        }
        id
        latitude
        longitude
      }
    }
  }
`;

@Component
export default class extends VueComponent<DestinationMapSection>
  implements DestinationMapSection {
  @Prop({ default: '' })
  public hotelId!: string;

  protected destination: DestinationModel | null = null;

  protected hotelLocation: GeoPosition | null = null;

  protected loading: boolean = false;

  public mounted() {
    const hotelSections = getModule(HotelSectionsModule, this.$store);
    hotelSections.setMapActive(true);
    hotelSections.setMapLoading(true);

    return this.loadDestination()
      .catch(() => {
        hotelSections.setMapActive(false);
      })
      .finally(() => {
        hotelSections.setMapLoading(false);
      });
  }

  public render() {
    if (!this.loading && !this.destination) {
      return;
    }

    return (
      <v-container class='px-0 pb-0'>
        <v-skeleton-loader
          loading={this.loading}
          transition='scale-transition'
          type='hotel'
          types={{ hotel: 'image, article' }}
        >
          {this.renderDestination()}
        </v-skeleton-loader>
      </v-container>
    );
  }

  protected renderDestination(): JSX.Element | void {
    if (!this.destination) {
      return <div />;
    }

    return (
      <DestinationPreviewBlock
        destination={this.destination}
        position={this.hotelLocation}
        title={this.destination.name}
      />
    );
  }

  @Watch('hotelId')
  protected loadDestination(): Promise<DestinationModel> {
    if (!this.hotelId) {
      this.destination = null;
      return Promise.reject();
    }

    this.loading = true;
    return this.$apollo
      .query<GQLRootQuery>({
        query: LOAD_DESTINATION_INFO_QUERY,
        variables: {
          id: this.hotelId,
        },
      })
      .then((result) => {
        if (!result.data.hotels.get) {
          throw new Error('Destination was not found');
        }

        if (!result.data.hotels.get.area.destination.listImage) {
          throw new Error('Destination is missing an image');
        }

        const hotel = createHotel(result.data.hotels.get);
        if (!hotel.destination) {
          throw new Error('Destination was not loaded');
        }

        this.hotelLocation = hotel.position;

        this.destination = hotel.destination;
        return this.destination;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
