import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import {
  BreadCrumbInterface,
  ChipInterface,
  IconTextInterface,
} from 'elixir-theme';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';

import { getLocalizedUrl } from '~/app/core/router';
import { PromoPackage } from '~/components/organisms/hotel';
import { default as AppHeader } from '~/components/templates/Header';
import { HotelModel } from '~/utils/hotel';
import { VueComponent } from '~/utils/vue-component';
import { ChosenPackageEvent } from '~/components/organisms/hotel/RoomBlock';

interface Header {
  hotel: HotelModel;
  onChosenPromoPackage: (data: ChosenPackageEvent) => void;
  onMoreOffersClick: () => void;
  onPersonalizedOfferClick: () => void;
}

@Component
export default class extends VueComponent<Header> implements Header {
  @Prop({ required: true })
  public hotel!: HotelModel;

  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected get breadCrumbs(): BreadCrumbInterface[] {
    const breadcrumbs: BreadCrumbInterface[] = [
      {
        text: this.$t('app.siteName').toString(),
        href: getLocalizedUrl('', this.$router),
        disabled: false,
      },
    ];

    if (this.hotel.destination) {
      breadcrumbs.push({
        text: this.hotel.destination.name,
        href: getLocalizedUrl(this.hotel.destination.slug, this.$router),
        disabled: false,
      });
    }

    return breadcrumbs;
  }

  protected get features(): IconTextInterface[] {
    const features: IconTextInterface[] = [];

    let beachDistance: string = '';

    if (this.hotel.beachNextToHotel) {
      beachDistance = this.$t('app.hotel.nextToHotel').toString();
    } else if (this.hotel.distanceToBeach) {
      beachDistance = this.hotel.distanceToBeach;
    }

    if (beachDistance) {
      features.push({
        iconName: '$vuetify.icons.beach',
        iconColor: 'white',
        text: `${this.$t('app.hotel.header.beach')}: ${beachDistance}`,
      });
    }

    features.push({
      iconName: '$vuetify.icons.food',
      iconColor: 'white',
      text: `${this.$t('app.hotel.header.board')}: ${this.hotel.boardNames.join(
        ', '
      )}`,
    });

    const roomInfo: string[] = [];

    if (this.hotel.capacityMin && this.hotel.capacityMax) {
      if (this.hotel.capacityMin === this.hotel.capacityMax) {
        roomInfo.push(
          this.$tc('app.hotel.header.roomCapacity', this.hotel.capacityMax, {
            to: this.hotel.capacityMax,
          }).toString()
        );
      } else {
        roomInfo.push(
          this.$tc(
            'app.hotel.header.roomMultiCapacity',
            this.hotel.capacityMax,
            {
              from: this.hotel.capacityMin,
              to: this.hotel.capacityMax,
            }
          ).toString()
        );
      }
    }

    if (this.hotel.areaMin && this.hotel.areaMax) {
      if (this.hotel.areaMin === this.hotel.areaMax) {
        roomInfo.push(
          this.$tc('app.hotel.header.roomSize', this.hotel.areaMax, {
            to: this.hotel.areaMax,
          }).toString()
        );
      } else {
        roomInfo.push(
          this.$tc('app.hotel.header.roomSizeRange', this.hotel.areaMax, {
            from: this.hotel.areaMin,
            to: this.hotel.areaMax,
          }).toString()
        );
      }
    }

    if (roomInfo.length > 0) {
      features.push({
        iconName: '$vuetify.icons.room',
        iconColor: 'white',
        text: `${this.$t('app.hotel.header.rooms')}: ${roomInfo.join(', ')}`,
      });
    }

    return features;
  }

  protected get headerImage() {
    if (!this.hotel || !this.hotel.backgroundImage) {
      return;
    }

    return this.hotel.backgroundImage.src;
  }

  protected get innerStripeColor(): string {
    if (['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) < 0) {
      return 'rgba(198, 197, 222, 0.9)';
    }

    return 'rgba(255, 255, 255, 0.8)';
  }

  // TODO: Implement tags
  protected get tags(): ChipInterface[] {
    const tags: ChipInterface[] = [];

    if (this.hotel.destination && this.hotel.destination.name) {
      tags.push({
        color: 'primary',
        textColor: 'contrast',
        text: this.hotel.destination.name,
      });
    }

    return tags;
  }

  public beforeMount() {
    this.setMobile();
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  public render() {
    let stripeColor = '#f0eff7';

    if (this.headerImage) {
      stripeColor = this.hotel.darkHeader
        ? 'rgba(0,0,0,0.4)'
        : 'rgba(255,255,255,0.4)';
    }

    return (
      <background-wrapper
        stripeColor={stripeColor}
        imgUrl={this.headerImage}
        class='header-bg-z-index'
      >
        <background-wrapper stripeColor={this.innerStripeColor}>
          <v-container class='px-0 pt-0'>
            <AppHeader />
          </v-container>
        </background-wrapper>
        <v-container class='py-0'>
          <v-layout wrap>
            <v-flex xs12 md6>
              <hotel-details
                breadCrumbs={this.breadCrumbs}
                dark={this.headerImage && this.hotel.darkHeader}
                numberOfStars={this.hotel.stars}
                tags={this.tags}
                hotelName={this.hotel.name}
                featuresList={this.features}
                breadCrumbVuetifyClass='tertiary'
                class={this.isMobile ? 'mb-2' : 'mb-4'}
              />
            </v-flex>

            <v-flex xs12 md6>
              {this.renderPromoPackage()}
            </v-flex>
          </v-layout>
        </v-container>
      </background-wrapper>
    );
  }

  @Emit('chosenPromoPackage')
  public onChosenPromoPackage(data: ChosenPackageEvent): ChosenPackageEvent {
    return data;
  }

  @Emit('moreOffersClick')
  public onMoreOffersClick() {
    return;
  }

  @Emit('personalizedOfferClick')
  public onPersonalizedOfferClick() {
    return;
  }

  protected renderPromoPackage(): JSX.Element | void {
    if (!this.hotel.promoPackage) {
      return;
    }

    return (
      <PromoPackage
        hotel={this.hotel}
        onChosenPromoPackage={this.onChosenPromoPackage}
        onMoreOffersClick={this.onMoreOffersClick}
        onPersonalizedOfferClick={this.onPersonalizedOfferClick}
        promoPackage={this.hotel.promoPackage}
      />
    );
  }
}
