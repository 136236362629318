import isGqlDestination from './typeguard';
import { GQLDestination } from '~/GqlTypes';
import createImage, { ImageModel } from '~/utils/image';

interface DestinationModel {
  content: string;
  excerpt: string;
  featuredImage: ImageModel | null;
  flightPrice: number;
  id: GQLDestination['id'];
  listImage: ImageModel | null;
  name: GQLDestination['name'];
  slug: string;
}

function createDestination(data: GQLDestination): DestinationModel {
  return {
    content: data.content ? data.content : '',
    excerpt: data.excerpt ? data.excerpt : '',
    featuredImage: data.featuredImage ? createImage(data.featuredImage) : null,
    flightPrice: data.priceFlyTicket ? data.priceFlyTicket.amount : 0,
    id: data.id,
    listImage: data.listImage ? createImage(data.listImage) : null,
    name: data.name ? data.name : '',
    slug: data.canonicalSlug ? data.canonicalSlug : '',
  };
}

export { createDestination, DestinationModel, isGqlDestination };
