import { PromotionBoxInterface } from 'elixir-theme';
import { Component } from 'vue-property-decorator';

import background from '~/assets/images/promotion-active.jpg';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class extends VueComponent<{}> {
  protected get boxData(): PromotionBoxInterface {
    return {
      height: 285,
      backgroundImageUrl: background,
      title: this.$t('app.promotions.elixirActive.title').toString(),
      callToActionBtn: {
        href: true,
        name: this.$t('app.promotions.elixirActive.cta').toString(),
        routeName: this.$t('app.promotions.elixirActive.url').toString(),
        target: '_blank',
      },
      vuetifyTextClass: 'white',
      vuetifyButtonClass: 'tertiary',
      vuetifyButtonTextClass: 'contrast',
    };
  }

  public render() {
    return <promotion-box {...{ attrs: this.boxData }} />;
  }
}
