import { Component, Mixins } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';

@Component
export default class Terms extends Mixins(HeadManagement) {
  public title() {
    return this.$t('app.terms.pageTitle');
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <section>
            <h1 class='headline'>Všeobecné podmínky</h1>
            <p>
              Všeobecné podmínky pro účastníky zájezdu CK Elixír tours, podle
              ustanovení zákona č.159/99 Sb.
            </p>
            <h2 class='title'>1. Úvodní ustanovení:</h2>
            <p>
              Smlouva vzniká mezi účastníkem zájezdu a společností Elixír tours
              (dále jen „cestovní kancelář“) dnem, kdy je podepsána cestovní
              smlouva, která je závaznou přihláškou na zájezd ze strany
              účastníka zájezdu a je potvrzena cestovní kanceláří. Souběžně s
              uzavřením cestovní smlouvy se stávají účinnými i tyto všeobecné
              podmínky.
            </p>
            <p>
              Při podpisu cestovní smlouvy jako závazné přihlášky na zájezd je
              účastník zájezdu (objednavatel) povinen zaplatit zálohu ve výši 50
              % z celkové ceny zájezdu. Doplatek ve výši zbývajících 50 % z
              celkové ceny zájezdu je účastník zájezdu (objednavatel) povinen
              doplatit cestovní kanceláři Elixír tours do 45 dnů před daným
              termínem odjezdu/odletu zákazníka. Doplatek je považován za
              zrealizovaný v momentě, kdy je předmětná částka připsána na účet
              cestovní kanceláře Elixír tours nebo složena v hotovosti do
              pokladny cestovní kanceláře Elixír tours.
            </p>
            <h2 class='title'>2. Práva a povinnosti účastníka zájezdu:</h2>
            <p>
              Je povinností každého jednotlivého účastníka zájezdu mít platný
              cestovní doklad, eventuálně i platné vstupní vízum, a to i dle
              požadavků cílové země a event. zemí tranzitních. Cestovní doklad
              si zajišťuje sám na vlastní náklady.
            </p>
            <p>
              Je osobní odpovědností každého účastníka zájezdu dbát na
              dodržování celních, pasových a zdravotních podmínek a dalších
              předpisů země, do které cestuje. Eventuelní náklady, které
              vzniknou nedodržením těchto podmínek, nese účastník zájezdu.
            </p>
            <h2 class='title'>3. Práva a povinnosti cestovní kanceláře:</h2>
            <p>
              Cestovní kancelář je oprávněna, a účastníku zájezdu nevzniká právo
              na odstoupení od smlouvy, v případech, které nemůže ovlivnit
              (zejména změny u obchodních a zahraničních partnerů, dopravců,
              leteckých společností a jiných dodavatelů služeb), a okolností,
              které nelze vyvrátit (jako je např. změna devizového kurzu):
            </p>
            <ul>
              <li>
                pokud bude nezbytné účastníka zájezdu ubytovat v jiném hotelu,
                si cestovní kancelář vyhrazuje právo zajistit mu ubytování v
                jiném hotelu stejné nebo vyšší kategorie
              </li>
              <li>zvýšit cenu ve smyslu § 852c obč. zák.</li>
              <li>
                pozměnit termíny zájezdu až o 48 hodin, a to i bez souhlasu
                účastníka zájezdu
              </li>
              <li>
                zrušit či zkrátit zájezd z důvodu vyšší moci – cestovní kancelář
                má v tomto případě nárok na úhradu všech již poskytnutých služeb
                – v případě zrušení zájezdu je cestovní kancelář povinna
                nabídnout účastníkovi zájezdu náhradní zájezd
              </li>
              <li>
                cestovní kancelář není odpovědná za případné zpoždění či změny v
                dopravě a účastník zájezdu byl seznámen s možností jeho vzniku
                vinou nepříznivých podmínek (povětrnostních, technických,
                přetížené letecké cesty apod.). Účastník zájezdu musí brát v
                úvahu i možnost výrazného zpoždění při plánování přípojů a
                dalších svých aktivit.
              </li>
            </ul>
            <p>
              Cestovní kancelář se zavazuje informovat účastníka zájezdu o všech
              případných změnách.
            </p>
            <p>
              Cestovní kancelář může změnit podmínky zájezdu, pokud se jedná o
              nevýznamnou změnu, o které zákazníka informuje v textové podobě.
              Nutí-li vnější okolnosti pořadatele podstatně změnit některou z
              hlavních náležitostí zájezdu (náležitosti uvedené v § 2527 písm.
              a) občanského zákoníku) nebo nemůže-li splnit zvláštní požadavky
              zákazníka, které přijal, navrhne zákazníkovi změnu smlouvy, kterou
              může zákazník přijmout, nebo může od smlouvy odstoupit, aniž by
              musel hradit odstupné. Lhůta pro odstoupení nesmí být kratší než
              pět dnů a musí skončit před zahájením zájezdu. Neodstoupí-li
              zákazník od smlouvy v určené lhůtě, platí, že se změnou smlouvy
              souhlasí; v takovém případě je zákazník povinen do 5 dnů od
              uplynutí uvedené lhůty, nejpozději však do zahájení zájezdu
              uhradit pořadateli případný doplatek vzniklý rozdílem v ceně
              změněného zájezdu. Nezaplatí-li zákazník pořadateli doplatek řádně
              a včas, má pořadatel právo od smlouvy odstoupit; tím není dotčeno
              jeho právo na náhradu škody. Rozdíl v ceně zájezdu před a po jeho
              změně pořadatel zákazníkovi vyplatí bez zbytečného odkladu.
            </p>
            <p>
              Cestovní kancelář odpovídá za řádné poskytnutí všech sjednaných
              služeb a má povinnost poskytnout účastníku zájezdu pomoc v
              nesnázích podle § 2539, odst. 2 občanského zákoníku. Zaviní-li si
              však nesnáze účastník zájezdu sám, může pořadatel od své
              povinnosti poskytnout pomoc v nesnázích odstoupit.
            </p>
            <p>
              Podpisem cestovní smlouvy, nebo podpisem objednávky zákazník
              vyslovuje souhlas s tím, aby v souladu s ustanovením § 5 odst. 2 a
              násl. Zákona č. 101/2000 Sb.o ochraně osobních údajů v platném
              znění a v souladu s obecným nařízením o ochraně osobních údajů
              (GDPR) Elixír tours zpracovával a shromažďoval osobní údaje
              zákazníka v tomto rozsahu: jméno příjemní, datum narození,
              bydliště a jeho elektronický kontakt pro elektronickou poštu,
              příp. i jím uvedenou jinou kontaktní adresu.
            </p>
            <h2 class='title'>
              4. Zrušení smlouvy ze strany účastníků zájezdu:
            </h2>
            <p>
              Je přípustné výhradně za stornovacích podmínek, které cestovní
              kancelář vyhlašuje jako součást “všeobecných podmínek”.
            </p>
            <p>Stornovací poplatky jsou stanoveny ve výši:</p>
            <ul>
              <li>
                do 65. dne před realizací zájezdu skutečně vzniklé náklady,
                nejméně však 10 % z celkové ceny zájezdu;
              </li>
              <li>
                65 až 46 dní před realizací zájezdu skutečně vzniklé náklady,
                nejméně však 20 % z celkové ceny zájezdu;
              </li>
              <li>
                45 až 32 dní před realizací zájezdu skutečně vzniklé náklady,
                nejméně však 50 % z celkové ceny zájezdu;
              </li>
              <li>
                31 dní a méně před realizací zájezdu 100 % z celkové ceny
                zájezdu;
              </li>
            </ul>
            <h2 class='title'>
              5. Zrušení smlouvy ze strany cestovní kanceláře:
            </h2>
            <p>Cestovní kancelář je oprávněna odstoupit od smlouvy, pokud:</p>
            <ul>
              <li>
                a) počet osob přihlášených na zájezd je nižší než nejnižší počet
                určený ve smlouvě a pořadatel oznámil zákazníkovi zrušení
                zájezdu ve lhůtě určené ve smlouvě, která nesmí být kratší než:
                <ul>
                  <li>
                    dvacet dní před zahájením zájezdu v případě cest trvajících
                    déle než šest dní,
                  </li>
                  <li>
                    sedm dní před zahájením zájezdu v případě cest trvajících
                    dva až šest dní,
                  </li>
                  <li>
                    čtyřicet osm hodin před zahájením zájezdu v případě cest
                    trvajících méně než dva dny,
                  </li>
                </ul>
              </li>
              <li>
                b) v plnění závazku mu brání nevyhnutelné a mimořádné okolnosti
                a zrušení zájezdu oznámil zákazníkovi bez zbytečného odkladu
                ještě před zahájením zájezdu.
              </li>
            </ul>
            <p>
              Pořadatel v těchto případech vrátí zákazníkovi veškeré uhrazené
              platby za zájezd, nevzniká mu však vůči zákazníkovi povinnost k
              náhradě škody.
            </p>
            <h2 class='title'>6. Změna v knihování:</h2>
            <p>
              Pokud dojde k významné změně v knihování, jíž je myšlena změna
              jména cestujícího, prodloužení či zkrácení doby pobytu, změna
              termínu odjezdu, změna ubytovací kapacity, provedené do 60 dnů
              před plánovaným odjezdem, zaplatí zákazník paušální poplatek 500
              Kč/osoba. Pokud dojde ke změně později než 60 dnů před plánovaným
              odjezdem, je tato změna považována za zrušení původní smlouvy a za
              novou objednávku. V případě že, skutečné účelně vynaložené
              náklady, ke kterým došlo zrušením cestovní smlouvy nebo změnou
              rezervace, budou vyšší než výše uvedené poplatky, je zákazník
              povinen uhradit i tento rozdíl.
            </p>
            <h2 class='title'>7. Odpovědnost za vady zájezdu, reklamace:</h2>
            <p>
              Účastník zájezdu je povinen reklamovat vady poskytnutých služeb na
              místě (u delegáta cestovní kanceláře nebo přímo v hotelu) tak, aby
              bylo možno tyto vady odstranit pokud možno ještě během pobytu.
              Nebude-li závada na místě odstraněna, je účastník zájezdu povinen
              neprodleně informovat cestovní kancelář, a to prostřednictvím
              telefonátu či SMS na telefonní číslo +420 603 447 755 nebo na
              e-mail info@elixirtours.cz. V případě že by nebylo možno z
              objektivních důvodů vyřídit reklamaci na místě, musí účastník
              zájezdu uplatnit své právo u cestovní kanceláře bez zbytečného
              odkladu, nebo v případě, že se zájezd neuskutečnil, bez zbytečného
              odkladu ode dne, kdy měl být zájezd ukončen podle cestovní
              smlouvy, jinak právo zaniká.
            </p>
            <h2 class='title'>8. Pojištění účastníka zájezdu:</h2>
            <p>
              CK Elixír tours doporučuje svým klientům uzavřít komplexní
              cestovní pojištění, které zahrnuje léčebné výlohy, úrazové
              pojištění, odpovědnost za škody, pojištění cestovních zavazadel.
              Doporučujeme se pojistit u Evropské pojišťovny, tyto pojistky
              můžete uzavřít i v naší cestovní kanceláři. Účastník zájezdu si je
              plně vědom eventuálních rizik a jemu vzniklých nákladů, pokud si
              cestovní pojištění neuzavře.
            </p>
            <h2 class='title'>9. Všeobecná a závěrečná ustanovení</h2>
            <p>
              Všeobecné podmínky účasti na zájezdu platí pro všechny zájezdy a
              služby organizované cestovní kanceláří a jsou v tomto znění platné
              od 01. 07. 2018.
            </p>
          </section>
        </v-container>
      </div>
    );
  }
}
