import { RouteConfig } from 'vue-router';

import Terms from '~/views/Terms';

const config: RouteConfig = {
  component: Terms,
  path: 'terms',
};

export default config;
