import { Vue } from 'vue-property-decorator';

class WindowResize extends Vue {
  protected throttle: NodeJS.Timeout | undefined;

  protected lastWidth: number = 0;
  protected lastHeight: number = 0;

  public register() {
    if (typeof window === 'undefined') {
      return;
    }

    this.lastWidth = window.innerWidth;
    this.lastHeight = window.innerHeight;

    window.addEventListener('resize', this.resize.bind(this));
  }

  public unregister() {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('resize', this.resize.bind(this));
  }

  protected resize(e: Event) {
    if (this.throttle) {
      clearTimeout(this.throttle);
    }

    this.throttle = setTimeout(() => {
      this.throttle = undefined;
      this.fireResize(e);
    }, 100);
  }

  protected fireResize(e: Event) {
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;

    if (this.lastHeight !== currentHeight) {
      this.lastHeight = currentHeight;
      this.$emit('resize:height', e);
    }

    if (this.lastWidth !== currentWidth) {
      this.lastWidth = currentWidth;
      this.$emit('resize:width', e);
      this.$emit('resize', e);
    }
  }
}

const resizeEvents = new WindowResize();

if (typeof document !== 'undefined') {
  document.addEventListener(
    'DOMContentLoaded',
    resizeEvents.register.bind(resizeEvents)
  );
}

export default resizeEvents;
