import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    common: {
      close: 'Close',
    },
    formats: {
      date: 'YYYY/MM/DD',
      dateTime: 'YYYY/MM/DD HH:mm:ss',
    },
    errors: {
      unknown: 'Unknown error',
    },
  },
};

export default i18n;
