import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';

import Visitor from '~/app/core/store/modules/Visitor';
import Footer from '~/components/templates/Footer';
import ScrollTopButton from '~/components/molecules/ScrollTopButton';
import CookieBar from '~/components/organisms/CookieBar';
import BottomBar from '~/components/templates/BottomBar';
import { isServerPrefetchComponent } from '~/mixins/prefetch';
import push from '~/utils/dataLayer';

@Component
export default class App extends Vue {
  protected loading: boolean = false;

  protected cookieBar: boolean = false;

  protected visitorModule!: Visitor;

  protected waitingForChildPrefetch: Promise<any>[] = [];

  public created() {
    this.$router.beforeEach(this.beforeNavigation);
    this.visitorModule = getModule(Visitor, this.$store);
  }

  public mounted() {
    push({ event: 'webAppInitialized' });
    this.visitorModule.getUserConsent().then(() => {
      this.cookieBar = !this.visitorModule.acceptedCookie;
    });
  }

  public render() {
    return (
      <v-app id='app'>
        {/* Create new wrapper so the whole app does not rerender*/}
        <div>
          <v-progress-linear
            fixed
            top
            indeterminate
            active={this.loading}
            height='5px'
            color='accent'
            style='z-index: 999;'
          />
        </div>
        <v-content>
          <router-view />
        </v-content>
        <BottomBar />
        <Footer />
        <CookieBar
          open={this.cookieBar}
          onAccept={() => {
            this.cookieBar = false;
            this.visitorModule.acceptCookiePolicy();
          }}
        />
        <ScrollTopButton />
      </v-app>
    );
  }

  public updated() {
    // When the app updates, check all children for prefetch functionality
    this.waitingForChildPrefetch = [];
    this.getChildrenLoading(this.$children);

    if (this.waitingForChildPrefetch.length < 1) {
      // If there are none, just stop loading
      this.loading = false;
    } else {
      // Otherwise wait for completion of data prefetching, then stop loading
      Promise.all(this.waitingForChildPrefetch).finally(() => {
        this.loading = false;
      });
    }
  }

  /**
   * Before routing away, set loading to true
   */
  protected beforeNavigation(from: Route, to: Route, next: () => void) {
    this.loading = true;
    next();
  }

  /**
   * Recursively go through children and add their prefetch promise to be awaited
   */
  protected getChildrenLoading(children: Vue[]) {
    if (!children || children.length < 1) {
      return;
    }

    children.forEach((child) => {
      if (isServerPrefetchComponent(child) && child.prefetchPromise !== null) {
        this.waitingForChildPrefetch.push(child.prefetchPromise);
      }

      this.getChildrenLoading(child.$children);
    });
  }
}
