import { Component, Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import Destinations from '~/app/core/store/modules/Destinations';
import { DestinationModel } from '~/utils/destination';
import { ValueProp, VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { HotelModel } from '~/utils/hotel';

interface DestinationSelect extends ValueProp<DestinationModel | null> {
  disabled?: boolean;
  errorMessage?: string[];
  onChange?: () => void;
  onFocus?: () => void;
}

@Component
export default class
  extends VueComponentMixin<DestinationSelect, PrefetchComponent>(Prefetch)
  implements DestinationSelect {
  @Prop({ default: () => [] })
  public errorMessages!: string[];

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: null })
  public value!: DestinationModel | null;

  protected module!: Destinations;

  protected get destinations(): DestinationModel[] {
    if (!this.module) {
      return [];
    }

    return this.module.destinations;
  }

  public created() {
    this.module = getModule(Destinations, this.$store);
  }

  public prefetch() {
    return this.module.loadDestinations();
  }

  public render() {
    return (
      <v-select
        disabled={this.disabled}
        error-messages={this.errorMessages}
        error-count={2}
        filled
        items={this.destinations}
        item-value='id'
        item-text='name'
        label={this.$t('app.form.enquiry.destination')}
        loading={this.loadingPrefetchData}
        return-object
        value={this.value}
        onInput={(value: HotelModel | null) => this.$emit('input', value)}
        onFocus={this.onFocus}
        onChange={this.onChange}
      />
    );
  }

  @Emit('change')
  public onChange() {
    return;
  }

  @Emit('focus')
  public onFocus() {
    return;
  }
}
