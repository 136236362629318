import VueI18n from 'vue-i18n';

import { annotate as annotateCurrency, CurrencyModel } from '~/utils/currency';
import { DestinationModel } from '~/utils/destination';
import { PackageModel } from '~/utils/package';
import { HotelModel } from '~/utils/hotel';

export default function flightPrice(
  pkg: PackageModel,
  destination: DestinationModel | null
): number {
  if (pkg.flightTicketPrice === null && destination) {
    return destination.flightPrice ? destination.flightPrice : 0;
  }

  return pkg.flightTicketPrice ? pkg.flightTicketPrice : 0;
}

export function label(
  pkg: PackageModel,
  hotel: HotelModel,
  i18n: VueI18n,
  currency?: CurrencyModel
): string | undefined {
  if (hotel.hideFlightPrice) {
    return undefined;
  }

  const price = flightPrice(pkg, hotel.destination);

  if (!price) {
    return undefined;
  }

  if (!currency) {
    currency = { code: 'CZK' };
  }

  const formattedPrice = annotateCurrency(price, currency);
  return `${i18n.t('app.package.price.flight')}: ${formattedPrice}`;
}
