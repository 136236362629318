import Destinations from './Destinations';
import DestinationSearch from './DestinationSearch';
import Hotel from './Hotel';
import Hotels from './Hotels';
import HotelSections from './HotelSections';
import Menu from './Menu';
import Promotions from './Promotions';
import Search from './Search';
import SpecialOffers from './SpecialOffers';
import Visitor from './Visitor';

const modules: { [s: string]: any } = {
  Destinations,
  DestinationSearch,
  Hotel,
  Hotels,
  HotelSections,
  Menu,
  Promotions,
  Search,
  SpecialOffers,
  Visitor,
};

export { modules };
