import { RouteConfig } from 'vue-router';

import Contact from '~/views/Contact';

const config: RouteConfig = {
  component: Contact,
  path: 'contacts',
};

export default config;
