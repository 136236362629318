import Vue from 'vue';
import Router, { Route, RouteConfig } from 'vue-router';
import {
  defaultLocale,
  createI18n,
  supportedLocales,
} from '~/app/localization';
import routes from './routes';

Vue.use(Router);

export function getLocaleFromRouter(router: Router): string {
  const { matched } = router.currentRoute;
  for (const routeRecord of matched) {
    if (routeRecord.meta.locale) {
      return routeRecord.meta.locale;
    }
  }

  return defaultLocale;
}

// We can create it here even in SSR because it is used
// to generate routes for all languages
const i18n = createI18n();

export function localizeRoute(route: RouteConfig, locale: string) {
  const shallowRouteCopy = { ...route };

  if (typeof route.path !== 'undefined') {
    const key = route.path ? `routes.${route.path}` : 'routes.homepage';

    if (i18n.te(`${key}.path`, locale)) {
      shallowRouteCopy.path = i18n.t(`${key}.path`, locale).toString();
    }
    if (i18n.te(`${key}.name`, locale)) {
      shallowRouteCopy.name = i18n.t(`${key}.name`, locale).toString();
    }
    shallowRouteCopy.name = `${
      shallowRouteCopy.name ? shallowRouteCopy.name : shallowRouteCopy.path
    } | ${locale}`;
  }

  return shallowRouteCopy;
}

// Generate language routes
const languageRoutes = supportedLocales.map(
  (locale): RouteConfig => {
    const languageConfig: RouteConfig = {
      path: `/${locale}`,
      component: {
        render(c) {
          return c('router-view');
        },
      },
      meta: {
        locale,
      },
    };

    languageConfig.children = [];

    for (const route of routes) {
      languageConfig.children.push(localizeRoute(route, locale));
    }

    return languageConfig;
  }
);

export default function() {
  const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    base: '/',
    routes: [
      {
        path: '',
        redirect: `/${defaultLocale}`,
      },
      ...languageRoutes,
    ],
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        if (document) {
          const anchor = document.querySelector(to.hash) as HTMLElement;
          if (anchor) {
            const scrollTo = Math.round(
              anchor.getBoundingClientRect().top + window.scrollY
            );
            return window.scrollTo({ top: scrollTo, behavior: 'smooth' });
          }
        }

        return {
          selector: to.hash,
        };
      }

      return savedPosition ? savedPosition : { x: 0, y: 0 };
    },
  });

  router.beforeEach((to, from, next) => {
    if (to.params.lang && supportedLocales.indexOf(to.params.lang) < 0) {
      next(`/${defaultLocale}/${to.fullPath}`);
    } else {
      next();
    }
  });

  return router;
}

/**
 * Legacy function, should be migrated to getLocalizedRoute
 * @param slug
 * @param router
 */
export function getLocalizedUrl(slug: string, router: Router): string {
  const lang = router.currentRoute.params.lang
    ? router.currentRoute.params.lang
    : defaultLocale;

  const trimmedSlug = slug.replace(/^\//, '');

  return `/${lang}/${trimmedSlug}`;
}

export function getRoutePathWithoutLang(route: Route): string {
  const { matched } = route;

  let lang = defaultLocale;

  for (const routeRecord of matched) {
    if (routeRecord.meta.locale) {
      lang = routeRecord.meta.locale;
      break;
    }
  }

  if (lang) {
    return route.fullPath.replace(new RegExp(`^/${lang}/`), '/');
  }

  return route.fullPath;
}

export function getRouteNameWithoutLang(name: string): string | undefined {
  return name.split(' | ').shift();
}
