import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';
import { getModule } from 'vuex-module-decorators';

import appEnv from '~/app/core/appEnv';
import HotelSections from '~/app/core/store/modules/HotelSections';
import ConsentSentence from '~/components/molecules/ConsentSentence';
import DatePickerField from '~/components/organisms/DatePickerField';
import { GQLRootMutation, RootMutationToContactArgs } from '~/GqlTypes';
import { format, parse } from '~/utils/date-fns';
import { DestinationModel } from '~/utils/destination';
import { VueComponent } from '~/utils/vue-component';
import { SelectDestination } from '~/components/molecules/Form';
import Timeout = NodeJS.Timeout;
import { pushEvent } from '~/utils/dataLayer';

interface FormData {
  destinationId: string;
  from: string;
  mail: string;
  message: string;
  name: string;
  numberOfNights: string;
  phone: string;
  to: string;
  token: string;
}

interface FormErrors {
  [key: string]: string[];
  consent: string[];
  dateFrom: string[];
  dateTo: string[];
  destination: string[];
  email: string[];
  length: string[];
  message: string[];
  name: string[];
  phone: string[];
}

@Component({
  components: {
    VueRecaptcha,
  },
})
export default class extends VueComponent<{}> {
  @Prop({ type: Number })
  public numberOfNights?: number;

  @Ref('datePickerFrom')
  protected datePickerFrom!: Vue;

  @Ref('datePickerTo')
  protected datePickerTo!: Vue;

  @Ref('recaptcha')
  protected recaptcha!: VueRecaptcha;

  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected recaptchaStatus: Recaptcha = {
    enabled: !!appEnv.GOOGLE_RECAPTCHA_KEY,
    loading: {
      promise: null,
      reject: null,
      resolve: null,
    },
    verification: {
      promise: null,
      reject: null,
      resolve: null,
    },
    ready: false,
    required: false,
  };

  protected submitting: boolean = false;

  protected submited: boolean = false;

  /*
   * Form data
   */

  protected consentThrottle?: Timeout;

  protected today!: Date;

  /*
   * Form fields
   */
  protected consent: boolean = false;

  protected dateFrom?: Date;

  protected dateTo?: Date;

  protected email: string = '';

  protected lengthFrom: number = 6;

  protected lengthTo: number = 10;

  protected message: string = '';

  protected name: string = '';

  protected phone: string = '';

  protected selectedDestination: DestinationModel | null = null;

  protected get numberOfNightsStringified(): string {
    if (!this.lengthFrom || !this.lengthTo) {
      return '';
    }

    if (this.lengthFrom === this.lengthTo) {
      return this.lengthFrom.toString();
    }

    return `${this.lengthFrom} - ${this.lengthTo}`;
  }

  protected get formData(): FormData {
    return {
      destinationId: this.selectedDestination
        ? this.selectedDestination.id
        : '',
      from: this.dateFrom ? format(this.dateFrom) : '',
      mail: this.email,
      numberOfNights: this.numberOfNightsStringified,
      message: this.message,
      name: this.name,
      phone: this.phone,
      to: this.dateTo ? format(this.dateTo) : '',
      token: '',
    };
  }

  protected formErrors: FormErrors = {
    consent: [],
    dateFrom: [],
    dateTo: [],
    destination: [],
    email: [],
    length: [],
    message: [],
    name: [],
    phone: [],
  };

  /**
   * Checks whether there are any errors on the form
   */
  protected get formHasErrors(): boolean {
    let error = false;

    // Check all the form errors, if found, break the loop and return
    for (const formErrorsKey in this.formErrors) {
      if (this.formErrors.hasOwnProperty(formErrorsKey)) {
        if (this.formErrors[formErrorsKey].length > 0) {
          error = true;
          break;
        }
      }
    }

    return error;
  }

  protected error: boolean = false;

  protected errorMessage: string = '';

  protected get formCanBeSubmitted(): boolean {
    return this.recaptchaStatus.ready && !this.formHasErrors;
  }

  public created() {
    this.today = parse(format(new Date(), 'YYYY-MM-DD'));
    getModule(HotelSections, this.$store).setEnquiryActive(true);
  }

  public beforeMount() {
    this.setMobile();
  }

  public render() {
    const sectionTitleClass = ['mt-4', 'mb-3'];
    const formClass = ['elix-form'];

    if (this.isMobile) {
      sectionTitleClass.push('px-3');
      formClass.push('px-3');
    }

    if (this.submited) {
      return (
        <v-container class='px-0 py-0'>
          <section-title
            title={this.$t('app.form.weddingEnquiry.title')}
            class={sectionTitleClass.join(' ')}
          />
          <v-alert type='success' color='pink accent-4' dark>
            {this.$t('app.form.inquirySuccesfull')}
          </v-alert>
        </v-container>
      );
    }

    return (
      <v-container class='px-0 py-0'>
        {(() => {
          if (!this.recaptchaStatus.required || !this.recaptchaStatus.enabled) {
            return;
          }

          return (
            <vue-recaptcha
              sitekey={appEnv.GOOGLE_RECAPTCHA_KEY}
              ref='recaptcha'
              loadRecaptchaScript={true}
              badge='bottomright'
              size='invisible'
              onVerify={this.recaptchaVerified}
              onError={this.recaptchaError}
              onRender={this.recaptchaRendered}
              onExpired={this.recaptchaExpired}
            />
          );
        })()}
        <section-title
          title={this.$t('app.form.weddingEnquiry.title')}
          class={sectionTitleClass.join(' ')}
        />
        <v-row no-gutters class={formClass.join(' ')}>
          <v-col cols='12' lg='6' class={this.isMobile ? '' : 'pr-3'}>
            <v-text-field
              error-messages={this.formErrors.name}
              error-count={2}
              filled
              label={this.$t('app.form.enquiry.name') + '*'}
              v-model={this.name}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.name = [])}
            />
            <v-text-field
              error-messages={this.formErrors.email}
              error-count={2}
              filled
              label={this.$t('app.form.enquiry.email') + '*'}
              v-model={this.email}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.email = [])}
            />
            <v-text-field
              error-messages={this.formErrors.phone}
              error-count={2}
              filled
              label={this.$t('app.form.enquiry.phone')}
              v-model={this.phone}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.phone = [])}
            />
          </v-col>
          <v-col cols='12' lg='6' class={this.isMobile ? '' : 'pl-4'}>
            <SelectDestination
              errorMessages={this.formErrors.destination}
              v-model={this.selectedDestination}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.destination = [])}
            />
            <v-row no-gutters>
              <v-col cols='12' md='4' class={this.isMobile ? '' : 'pr-2'}>
                <DatePickerField
                  allowedDates={this.allowedFrom}
                  errors={this.formErrors.dateFrom}
                  label={this.$t('app.form.enquiry.from') + '*'}
                  onChange={() => (this.formErrors.dateFrom = [])}
                  onInput={(value) => {
                    this.dateFrom = value;
                    this.$forceUpdate();
                  }}
                  ref='datePickerFrom'
                  value={this.dateFrom}
                />
              </v-col>
              <v-col cols='12' md='4' class={this.isMobile ? '' : 'px-2'}>
                <DatePickerField
                  allowedDates={this.allowedTo}
                  errors={this.formErrors.dateTo}
                  key={
                    this.dateFrom
                      ? format(this.dateFrom, 'YYYY-MM-DD')
                      : 'eqnuiry-form-datepicker-to'
                  }
                  label={this.$t('app.form.enquiry.to') + '*'}
                  onChange={() => (this.formErrors.dateTo = [])}
                  onInput={(value) => {
                    this.dateTo = value;
                    this.$forceUpdate();
                  }}
                  ref='datePickerTo'
                  value={this.dateTo}
                />
              </v-col>
              <v-col cols='12' md='4' class={this.isMobile ? 'mb-5' : 'pl-2'}>
                <two-way-slider
                  bordered={false}
                  labelText={this.$t('app.form.enquiry.length') + '*'}
                  max={this.lengthTo}
                  min={this.lengthFrom}
                  placeholder=''
                  sliderEnd={40}
                  sliderStart={6}
                  class='placeholder-as-text-color'
                  title={this.$t('app.form.enquiry.lengthTitle', {
                    from: this.lengthFrom,
                    to: this.lengthTo,
                  })}
                  {...{
                    on: {
                      'update:max': (value: string) => {
                        this.lengthTo = parseInt(value, 10);
                      },
                      'update:min': (value: string) => {
                        this.lengthFrom = parseInt(value, 10);
                      },
                    },
                  }}
                />
              </v-col>
            </v-row>
          </v-col>
          <v-textarea
            error-messages={this.formErrors.message}
            error-count={2}
            filled
            label={this.$t('app.form.enquiry.message')}
            v-model={this.message}
            onChange={() => (this.formErrors.message = [])}
            onFocus={this.loadRecaptcha}
          />
        </v-row>
        {(() => {
          if (this.formHasErrors) {
            return (
              <v-row no-gutters class={this.isMobile ? 'px-3' : ''}>
                <span class='error--text'>
                  {this.$t('app.form.error.makeCorrections')}
                </span>
              </v-row>
            );
          }
        })()}
        <v-row
          no-gutters
          class={this.isMobile ? 'px-3' : ''}
          justify='space-between'
        >
          <v-col>
            <v-checkbox
              color='primary'
              class='mt-2'
              v-model={this.consent}
              error-messages={this.formErrors.consent}
              error-count={2}
              onChange={(value: boolean) => {
                if (value) {
                  this.formErrors.consent = [];
                }
              }}
              onClick={(e: any) => {
                // It ignores click on the checkbox, so we need to manually trigger it
                if (
                  e.target &&
                  e.target.parentNode &&
                  e.target.parentNode.classList.contains(
                    'v-input--selection-controls__input'
                  )
                ) {
                  // throttle is here because some clicks fire multiple times
                  if (this.consentThrottle) {
                    clearTimeout(this.consentThrottle);
                  }

                  this.consentThrottle = setTimeout(() => {
                    this.consent = !this.consent;
                    if (this.consent) {
                      // Make sure form errors are empty
                      this.formErrors.consent = [];
                    }
                  }, 100);
                }
              }}
            >
              <ConsentSentence
                slot='label'
                hasErrors={this.formErrors.consent.length > 0}
              />
            </v-checkbox>
          </v-col>
          <v-tooltip
            bottom
            disabled={this.recaptchaStatus.ready}
            scopedSlots={{
              activator: (scope: any) => {
                return (
                  <div
                    {...{
                      on: scope.on,
                    }}
                  >
                    <v-btn
                      large
                      class='accent contrast--text elix-button-text px-10'
                      block={this.isMobile}
                      onClick={this.validateAndSend}
                      disabled={!this.formCanBeSubmitted}
                      loading={this.submitting}
                    >
                      {this.$t('app.common.submit')}
                    </v-btn>
                  </div>
                );
              },
            }}
          >
            {this.$t('app.form.recaptcha.loading')}
          </v-tooltip>
        </v-row>
        <v-snackbar v-model={this.error}>
          {this.errorMessage}
          <v-btn text onClick={() => (this.error = false)}>
            {this.$t('app.common.close')}
          </v-btn>
        </v-snackbar>
      </v-container>
    );
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  /**
   * Checks dates allowed for the "From" date
   * @param date
   */
  protected allowedFrom(date: Date): boolean {
    return this.today < date;
  }

  /**
   * Checks dates allowed for the "To" date
   * @param date
   */
  protected allowedTo(date: Date): boolean {
    if (this.dateFrom) {
      return this.dateFrom < date;
    }

    return this.today < date;
  }

  /**
   * Checks for errors and validate recaptcha
   */
  protected validateAndSend() {
    // Reset all errors
    for (const formErrorsKey in this.formErrors) {
      if (this.formErrors.hasOwnProperty(formErrorsKey)) {
        this.formErrors[formErrorsKey] = [];
      }
    }

    if (!this.formData.destinationId) {
      this.formErrors.destination.push(
        this.$t('app.form.error.destination.missing').toString()
      );
    }

    if (!this.formData.name) {
      this.formErrors.name.push(
        this.$t('app.form.error.name.missing').toString()
      );
    }

    if (!this.formData.mail) {
      this.formErrors.email.push(
        this.$t('app.form.error.email.missing').toString()
      );
    }

    if (!this.dateFrom) {
      this.formErrors.dateFrom.push(
        this.$t('app.form.error.date.missingFrom').toString()
      );
    }

    if (!this.dateTo) {
      this.formErrors.dateTo.push(
        this.$t('app.form.error.date.missingTo').toString()
      );
    }

    if (this.dateFrom && this.dateTo && this.dateFrom >= this.dateTo) {
      this.formErrors.dateFrom.push(
        this.$t('app.form.error.date.invalidRange').toString()
      );
    }

    if (this.lengthFrom > this.lengthTo) {
      this.formErrors.length.push(
        this.$t('app.form.error.length.invalid').toString()
      );
    }

    if (!this.consent) {
      this.formErrors.consent.push(
        this.$t('app.form.error.consent.missing').toString()
      );
    }

    if (this.formHasErrors) {
      return;
    }

    this.submitting = true;

    // Load recaptcha if it hasn't yet
    let recaptchaLoad = this.recaptchaStatus.loading.promise;

    if (!recaptchaLoad) {
      recaptchaLoad = this.loadRecaptcha();
    }

    recaptchaLoad
      .then(() => {
        if (!this.recaptchaStatus.verification.promise) {
          // After the recaptcha loads, execute and verify the user
          this.recaptchaStatus.verification.promise = new Promise(
            (resolve, reject) => {
              this.recaptchaStatus.verification.resolve = resolve;
              this.recaptchaStatus.verification.reject = reject;
            }
          );

          if (this.recaptchaStatus.enabled) {
            this.recaptcha.execute();
          } else {
            // Fake validation of recaptcha, if it is not active
            this.recaptchaVerified('');
          }
        }

        return this.recaptchaStatus.verification.promise;
      })
      .then((token) => {
        this.submit(token)
          .then((result: boolean) => {
            if (this.recaptcha) {
              this.recaptcha.reset();
            }

            if (result) {
              pushEvent(
                'form',
                true,
                'weddingInquiry',
                this.selectedDestination
                  ? this.selectedDestination.name
                  : undefined
              );
              this.submited = true;
            }
          })
          .catch((err: ApolloError) => {
            let recaptchaError = false;

            err.graphQLErrors.forEach((error) => {
              if (error.message === 'INVALID_RECAPTCHA') {
                recaptchaError = true;
                this.recaptcha.reset();
                this.errorMessage = this.$t(
                  'app.form.error.recaptcha'
                ).toString();
                this.error = true;
              }
            });

            if (!recaptchaError) {
              throw new Error();
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      })
      .catch(() => {
        this.errorMessage = this.$t('app.form.error.recaptcha').toString();
        this.error = true;
        this.submitting = false;
      });
  }

  /**
   * Submit the data
   */
  protected submit(token: string): Promise<any> {
    const paragraphs: string[] = [
      `${this.$t('app.form.enquiry.name')}: ${this.name}`,
      `${this.$t('app.form.enquiry.email')}: ${this.email}`,
    ];

    if (this.phone.trim() !== '') {
      paragraphs.push(`${this.$t('app.form.enquiry.phone')}: ${this.phone}`);
    }

    if (this.selectedDestination) {
      paragraphs.push(
        `${this.$t('app.form.enquiry.destination')}: ${
          this.selectedDestination.name
        }`
      );
    }

    paragraphs.push(
      `${this.$t('app.form.enquiry.from')}: ${this.formData.from}`
    );
    paragraphs.push(`${this.$t('app.form.enquiry.to')}: ${this.formData.to}`);
    paragraphs.push(
      `${this.$t('app.form.enquiry.length')}: ${this.formData.numberOfNights}`
    );
    paragraphs.push(this.formData.message);

    const variables: RootMutationToContactArgs = {
      input: {
        mail: this.formData.mail,
        subject: `Svatba - ${
          this.selectedDestination ? this.selectedDestination.name : 'Poptávka'
        }`,
        message: `<p>${paragraphs.join('</p><p>')}</p>`,
        token,
      },
    };

    return this.$apollo
      .mutate<GQLRootMutation>({
        mutation: gql`
          mutation($input: Contact!) {
            contact(input: $input)
          }
        `,
        variables,
      })
      .then((result) => {
        if (result.data) {
          return result.data.contact;
        }

        return false;
      });
  }

  /**
   * Set flags for loading recaptcha into the app
   */
  protected loadRecaptcha(): Promise<void> {
    if (!this.recaptchaStatus.loading.promise) {
      // Set promise, so we can resolve or reject it based on the load status
      this.recaptchaStatus.loading.promise = new Promise((resolve, reject) => {
        // Store the callback functions to our object so it can be used from outside of this function
        this.recaptchaStatus.loading.resolve = resolve;
        this.recaptchaStatus.loading.reject = reject;
      }).then(() => {
        this.recaptchaStatus.ready = true;
      });
    }

    // This loads recaptcha into DOM
    this.recaptchaStatus.required = true;

    if (!this.recaptchaStatus.enabled) {
      // Fake rendering of recaptcha, if it is not active
      this.recaptchaRendered();
    }

    return this.recaptchaStatus.loading.promise;
  }

  /**
   * Callback from the vue-recaptcha component when it renders into DOM
   */
  protected recaptchaRendered() {
    // If we have the loading promise, resolve it and reset the status
    if (this.recaptchaStatus.loading.resolve) {
      this.recaptchaStatus.loading.resolve();
      this.recaptchaStatus.loading.resolve = null;
      this.recaptchaStatus.loading.reject = null;
    }
  }

  /**
   * Callback from the vue-recaptcha component when the user has been verified
   * @param response
   */
  protected recaptchaVerified(response: string) {
    // If we have the verification promise setup, resolve it and reset the status
    if (this.recaptchaStatus.verification.resolve) {
      this.recaptchaStatus.verification.resolve(response);
      this.recaptchaStatus.verification.resolve = null;
      this.recaptchaStatus.verification.reject = null;
    }
  }

  /**
   * Callback when something goes wrong, this can either be an invalid verification
   * or unable to load the recaptcha alltogether
   */
  protected recaptchaError() {
    // If the recaptcha was loading, reject and reset it
    if (this.recaptchaStatus.loading.reject) {
      this.recaptchaStatus.loading.reject();
      this.recaptchaStatus.loading.resolve = null;
      this.recaptchaStatus.loading.reject = null;
    }

    // If a verification was pending, reject and reset it
    if (this.recaptchaStatus.verification.reject) {
      this.recaptchaStatus.verification.reject();
      this.recaptchaStatus.verification.resolve = null;
      this.recaptchaStatus.verification.reject = null;
    }

    // Show an error to the user
    this.error = true;
    this.errorMessage = this.$t('app.form.error.recaptcha').toString();
  }

  /**
   * Reset verification when the recaptcha expires
   */
  protected recaptchaExpired() {
    this.recaptchaStatus.verification.promise = null;
    this.recaptchaStatus.verification.resolve = null;
    this.recaptchaStatus.verification.reject = null;
  }
}

interface Recaptcha {
  enabled: boolean;
  loading: {
    promise: Promise<void> | null;
    reject: (() => void) | null;
    resolve: (() => void) | null;
  };
  verification: {
    promise: Promise<string> | null;
    reject: (() => void) | null;
    resolve: ((token: string) => void) | null;
  };
  ready: boolean;
  required: boolean;
}
