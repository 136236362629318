import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';
import { default as Benefits } from './benefits';
import { default as Base } from './common/base';
import { default as Buttons } from './common/buttons';
import { default as Cookies } from './common/cookies';
import { default as Footer } from './common/footer';
import { default as Header } from './common/header';
import { default as Social } from './common/social';
import { default as About } from './pages/about';
import { default as Contact } from './pages/contact';
import { default as DataProtection } from './pages/dataProtection';
import { default as Faq } from './pages/faq';
import { default as References } from './pages/references';
import { default as Terms } from './pages/terms';
import { default as Weddings } from './pages/weddings';
import { default as Destination } from './destination';
import { default as Form } from './form';
import { default as Hotel } from './hotel';
import { default as Package } from './package';
import { default as Pages } from './pages';
import { default as Promotions } from './promotions';
import { default as Routes } from './routes';
import { default as Search } from './search';
import { default as Vuetify } from './vuetify';
import { default as SpecialOffer } from './pages/specialOffer';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  About,
  Base,
  Benefits,
  Buttons,
  Contact,
  Cookies,
  DataProtection,
  Destination,
  Faq,
  Footer,
  Form,
  Header,
  Hotel,
  Package,
  Pages,
  Promotions,
  References,
  Routes,
  Search,
  Social,
  SpecialOffer,
  Terms,
  Vuetify,
  Weddings,
]) as LocaleMessages;

export default mergedLocalizations;

export function choiceIndex(choice: number, choicesLength: number): number {
  let index = 3;

  if (choice === 0 || choice === 1) {
    index = choice;
  } else if (choice < 5) {
    index = 2;
  }

  return choicesLength < index ? choicesLength : index;
}
