import Description from './Description';
import {
  default as HotelNavigationBar,
  Events as HotelNavigationBarEvents,
} from './NavigationBar';
import PromoPackage from './PromoPackage';
import RoomBlock from './RoomBlock';
import Gallery from './Gallery';

export {
  Description as HotelDescription,
  Gallery as HotelGallery,
  HotelNavigationBar,
  HotelNavigationBarEvents,
  PromoPackage,
  RoomBlock,
};
