import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import {
  BreadCrumbInterface,
  DatePickerInterface,
  MainSearchInterface,
  SelectFieldInterface,
} from 'elixir-theme';
import { DateRange } from 'elixir-theme/dist/models/SearchForm';
import { SelectItem } from 'elixir-theme/dist/models/Select';
import { TwoWaySliderMetaInterface } from 'elixir-theme/dist/models/TwoWaySlider';
import { LocaleMessage } from 'vue-i18n';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { getLocalizedUrl } from '~/app/core/router';
import Destinations from '~/app/core/store/modules/Destinations';
import Search from '~/app/core/store/modules/Search';
import defaultBg from '~/assets/images/header-bg-default.jpg';
import { default as AppHeader } from '~/components/templates/Header';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { format, parse } from '~/utils/date-fns';
import { DestinationModel } from '~/utils/destination';
import { Vocabulary } from '~/utils/vocabulary';
import { VueComponentMixin } from '~/utils/vue-component';
import defaultFilterState from '~/utils/defaultFilterState';

interface Header {
  destination?: DestinationModel | null;
}

@Component
export default class
  extends VueComponentMixin<Header, PrefetchComponent>(Prefetch)
  implements Header {
  @Prop({ default: null })
  public destination!: DestinationModel | null;

  protected get destinationStore(): Destinations {
    return getModule(Destinations, this.$store);
  }

  protected get searchStore(): Search {
    return getModule(Search, this.$store);
  }

  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected chosenDestinations: DestinationModel[] = [];

  protected numberOfNightsMin: number = defaultFilterState.numberOfNightsMin;

  protected numberOfNightsMax: number = defaultFilterState.numberOfNightsMax;

  protected selectedDates: DateRange = {
    start: defaultFilterState.start,
    end: defaultFilterState.end,
  };

  protected errors: Vocabulary<
    'capacities' | 'date' | 'destinations' | 'nights',
    LocaleMessage[]
  > = {
    capacities: [],
    date: [],
    destinations: [],
    nights: [],
  };

  protected get formHasErrors(): boolean {
    for (const errKey in this.errors) {
      if (
        this.errors.hasOwnProperty(errKey) &&
        (this.errors as { [key: string]: LocaleMessage[] })[errKey].length > 0
      ) {
        return true;
      }
    }

    return false;
  }

  protected get headerImage(): string {
    return defaultBg;
  }

  protected get innerStripeColor(): string {
    if (['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) < 0) {
      return 'rgba(198, 197, 222, 0.9)';
    }

    return 'rgba(255, 255, 255, 0.8)';
  }

  protected get title(): string {
    if (this.chosenDestinations.length > 0) {
      const names = this.chosenDestinations.map(
        (destination) => destination.name
      );
      return this.$t('app.search.mainTitle.inLocation', {
        location: names.join(', '),
      }).toString();
    }

    return this.$t('app.search.mainTitle.fallback').toString();
  }

  protected get breadcrumbs(): BreadCrumbInterface[] {
    const breadcrumbs: BreadCrumbInterface[] = [];

    breadcrumbs.push({
      text: this.$t('app.siteName').toString(),
      disabled: false,
      href: getLocalizedUrl('/', this.$router),
    });

    if (this.destination) {
      breadcrumbs.push({
        text: this.destination.name,
        disabled: true,
        href: getLocalizedUrl(this.destination.slug, this.$router),
      });
    }
    // TODO: Add breadcrumbs based on context

    return breadcrumbs;
  }

  protected get datePicker(): DatePickerInterface {
    return {
      allowMinDate: format(new Date(), 'YYYY-MM-DD'),
      anyTimeText: '',
      cancelText: this.$t('app.buttons.cancel').toString(),
      errors: this.errors.date.map((err) => err.toString()),
      labelText: this.selectedDates.start
        ? this.$t('app.search.date.label').toString()
        : this.$t('app.search.date.placeholder').toString(),
      preciseTimeText: '',
      separatorLabel: '-',
      useText: this.$t('app.buttons.confirm').toString(),
    };
  }

  protected get destinationLink(): string | null {
    // TODO: Retrieve link to a destination page
    return null;
  }

  protected get searchField(): SelectFieldInterface {
    return {
      label: this.$t('app.search.destinations.label').toString(),
      errors: this.errors.destinations.map((err) => err.toString()),
      options: this.destinationStore.destinations.map((destination) => {
        return {
          text: destination.name,
          value: destination.id,
        };
      }),
    };
  }

  protected get chosenDestinationsSelect(): SelectItem[] {
    return this.chosenDestinations.map((destination) => {
      return {
        text: destination.name,
        value: destination.id,
      };
    });
  }

  protected get numberOfNights(): TwoWaySliderMetaInterface {
    return {
      errors: this.errors.nights.map((err) => err.toString()),
      labelText: this.$t('app.search.numberOfNights.title').toString(),
      placeholder: this.$t('app.search.numberOfNights.placeholder').toString(),
      sliderEnd: 30,
      sliderStart: 6,
      title: this.$t('app.search.numberOfNights.label').toString(),
    };
  }

  protected get mainSearch(): MainSearchInterface {
    return {
      chosenDestinations: this.chosenDestinationsSelect,
      datePicker: this.datePicker,
      loading: this.searchStore.loading,
      numberOfNights: this.numberOfNights,
      searchField: this.searchField,
      searchText: this.$t('app.buttons.search').toString(),
      selectedDate: this.selectedDates,
      selectedMinNumberOfNights: this.numberOfNightsMin,
      selectedMaxNumberOfNights: this.numberOfNightsMax,
      selectedPeopleList: [],
      title: this.title,
    };
  }

  public prefetch() {
    return this.destinationStore.loadDestinations().then(() => {
      if (this.$ssrContext) {
        return this.search();
      }
    });
  }

  public beforeMount() {
    this.setMobile();
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  public render() {
    return (
      <background-wrapper
        stripeColor={this.isMobile ? '#f0eff7' : 'rgba(255,255,255,0.4)'}
        imgUrl={this.headerImage}
        class='header-bg-z-index'
      >
        <background-wrapper stripeColor={this.innerStripeColor}>
          <v-container class='px-0 pt-0'>
            <AppHeader />
          </v-container>
        </background-wrapper>
        <v-container class={this.isMobile ? 'px-3' : 'px-0 pt-0'}>
          <v-row
            align='center'
            justify='space-between'
            class={this.isMobile ? 'px-2 py-0' : 'pa-0'}
            no-gutters
          >
            <v-breadcrumbs
              items={this.breadcrumbs}
              class='mx-0 px-0'
              divider='/'
            />
            {this.renderDestinationLink()}
          </v-row>
          <main-search
            {...{ attrs: this.mainSearch }}
            {...{
              on: {
                'update:chosenDestinations': (val: SelectItem[]) => {
                  this.chosenDestinations = [];
                  val.forEach((selectedDestination) => {
                    for (const destination of this.destinationStore
                      .destinations) {
                      if (destination.id === selectedDestination.value) {
                        this.chosenDestinations.push(destination);
                        break;
                      }
                    }
                  });
                },
                'update:selectedMinNumberOfNights': (val: number) => {
                  this.numberOfNightsMin = val;
                },
                'update:selectedMaxNumberOfNights': (val: number) => {
                  this.numberOfNightsMax = val;
                },
                'update:selectedDate': (val: DateRange) => {
                  this.errors.date = [];
                  this.selectedDates = val;
                },
                searchButtonClick: this.search,
              },
            }}
          />
        </v-container>
      </background-wrapper>
    );
  }

  protected renderDestinationLink(): JSX.Element | void {
    if (!this.destinationLink) {
      return;
    }

    return (
      <v-btn
        flat
        class='primary--text'
        to={getLocalizedUrl(this.destinationLink, this.$router)}
      >
        {this.$t('app.destination.moreInfo')}
        <v-icon>chevron_right</v-icon>
      </v-btn>
    );
  }

  protected search() {
    for (const errKey in this.errors) {
      if (this.errors.hasOwnProperty(errKey)) {
        (this.errors as { [key: string]: LocaleMessage[] })[errKey] = [];
      }
    }

    this.errors = {
      capacities: [],
      date: [],
      destinations: [],
      nights: [],
    };

    if (!this.selectedDates.start || !this.selectedDates.end) {
      this.errors.date.push(this.$t('app.search.errors.selectDate'));
    }

    if (!this.numberOfNightsMin || !this.numberOfNightsMax) {
      this.errors.nights.push(
        this.$t('app.search.errors.selectNumberOfNights')
      );
    }

    if (this.formHasErrors) {
      return;
    }

    const searchStore = this.searchStore || getModule(Search, this.$store);

    return searchStore.search({
      date: {
        from: parse(this.selectedDates.start),
        to: parse(this.selectedDates.end),
      },
      destinations: this.chosenDestinations,
      numberOfNights: {
        max: this.numberOfNightsMax,
        min: this.numberOfNightsMin,
      },
    });
  }

  @Watch('destination', { immediate: true })
  protected destinationChange() {
    this.chosenDestinations = [];

    if (this.destination) {
      this.chosenDestinations.push(this.destination);
      return this.search();
    }
  }
}
