import Vue from 'vue';
import VueI18n, { I18nOptions, LocaleMessages } from 'vue-i18n';
import { default as i18n_cs, choiceIndex as csChoiceIndex } from './cs';
import { default as i18n_en } from './en';
import deepmerge from 'deepmerge';
import dateFnsCs from 'date-fns/locale/cs';

Vue.use(VueI18n);

const localizations: LocaleMessages[] = [
  {
    cs: i18n_cs,
  },
  {
    en: i18n_en,
  },
];

const dateFnsLocalizations = {
  cs: dateFnsCs,
};

const mergedLocalizations: LocaleMessages = deepmerge.all(
  localizations
) as LocaleMessages;

const defaultLocale = 'cs';

const supportedLocales = ['cs'];

const i18nOptions: I18nOptions = {
  locale: defaultLocale,
  messages: mergedLocalizations,
  pluralizationRules: {
    cs: csChoiceIndex,
  },
};

export function createI18n() {
  return new VueI18n(i18nOptions);
}

interface RouteLocalization {
  routes: {
    [key: string]: {
      name: string;
      path: string;
    };
  };
}

export {
  dateFnsLocalizations,
  defaultLocale,
  RouteLocalization,
  supportedLocales,
};
