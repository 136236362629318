import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import Destinations from '~/app/core/store/modules/Destinations';
import { DestinationModel } from '~/utils/destination';
import { ValueProp, VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';

interface DestinationMultiselect extends ValueProp<string[]> {
  disabled?: boolean;
  errorMessages?: string[];
  label?: string;
  onChange?: () => void;
  onFocus?: () => void;
}

@Component
export default class
  extends VueComponentMixin<DestinationMultiselect, PrefetchComponent>(Prefetch)
  implements DestinationMultiselect {
  @Prop({ default: () => [] })
  public errorMessages!: string[];

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ required: true })
  public value!: string[];

  @Prop({ type: String })
  public label?: string;

  protected selected: string[] = [];

  protected module!: Destinations;

  protected get destinations(): DestinationModel[] {
    if (!this.module) {
      return [];
    }

    return this.module.destinations;
  }

  public created() {
    this.module = getModule(Destinations, this.$store);
  }

  public prefetch() {
    return this.module.loadDestinations();
  }

  public render() {
    return (
      <div class='v-input theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed'>
        <div class='v-input__control'>
          <div class='v-input__slot'>
            <div class='v-text-field__slot flex-wrap'>
              <div class='v-text-field__prefix mt-0'>{this.label}</div>
              {this.destinations.map((destination, index) => {
                return (
                  <v-checkbox
                    disabled={this.disabled}
                    loading={this.loadingPrefetchData}
                    v-model={this.selected}
                    onFocus={this.onFocus}
                    label={destination.name}
                    key={index}
                    value={destination.id}
                    hide-details
                    class={'ml-2 my-2'}
                  />
                );
              })}
            </div>
          </div>
          <div class='v-text-field__details'>
            <div class='v-messages theme--light error--text' role='alert'>
              <div class='v-messages__wrapper'>
                <div class='v-messages__message'>
                  {this.errorMessages.join('\n')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  @Emit('change')
  public onChange() {
    return;
  }

  @Emit('focus')
  public onFocus() {
    return;
  }

  @Watch('selected', { deep: true })
  public handleSelectedChange() {
    this.$emit('input', this.selected);
    this.onChange();
  }

  @Watch('value', { deep: true, immediate: true })
  public updateSelected() {
    this.selected = this.value;
  }
}
