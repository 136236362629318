import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import HotelSectionsModule from '~/app/core/store/modules/HotelSections';
import { HotelModel } from '~/utils/hotel';
import { VueComponent } from '~/utils/vue-component';

interface DescriptionSection {
  hotelData: HotelModel;
}

@Component
export default class extends VueComponent<DescriptionSection>
  implements DescriptionSection {
  @Prop({ required: true })
  public hotelData!: HotelModel;

  protected get content(): string {
    return this.hotelData.excerpt + this.hotelData.content;
  }

  public created() {
    getModule(HotelSectionsModule, this.$store).setDescriptionActive(true);
  }

  public render() {
    return (
      <hotel-description
        itemsList={[]}
        title={this.$t('app.hotel.description').toString()}
        description={this.content}
        moreText={this.$t('app.hotel.moreButtonText').toString()}
      />
    );
  }
}
