import { Component, Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import Hotels from '~/app/core/store/modules/Hotels';
import { HotelModel } from '~/utils/hotel';
import { ValueProp, VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';

interface HotelSelect extends ValueProp<HotelModel | null> {
  disabled?: boolean;
  errorMessages?: string[];
  onChange?: () => void;
  onFocus?: () => void;
}

@Component
export default class
  extends VueComponentMixin<HotelSelect, PrefetchComponent>(Prefetch)
  implements HotelSelect {
  @Prop({ default: () => [] })
  public errorMessages!: string[];

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: null })
  public value!: HotelModel | null;

  protected module!: Hotels;

  protected get hotels(): HotelModel[] {
    if (!this.module) {
      return [];
    }

    return this.module.hotels;
  }

  public created() {
    this.module = getModule(Hotels, this.$store);
  }

  public prefetch() {
    return this.module.loadHotels();
  }

  public render() {
    return (
      <v-select
        disabled={this.disabled}
        error-messages={this.errorMessages}
        error-count={2}
        filled
        items={this.hotels}
        item-value='id'
        item-text='name'
        label={this.$t('app.form.enquiry.hotel')}
        loading={this.loadingPrefetchData}
        return-object
        value={this.value}
        onInput={(value: HotelModel | null) => this.$emit('input', value)}
        onFocus={this.onFocus}
        onChange={this.onChange}
      />
    );
  }

  @Emit('change')
  public onChange() {
    return;
  }

  @Emit('focus')
  public onFocus() {
    return;
  }
}
