import Vue from 'vue';
import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import { createHotel, HotelModel, isGqlHotel } from '~/utils/hotel';
import { GQLRootQuery, PackagesToListArgs, GQLImage } from '~/GqlTypes';
import gql from 'graphql-tag';
import { createRoom, RoomModel } from '~/utils/room';
import createPackage, { PackageModel } from '~/utils/package';
import { OccupancyModel } from '~/utils/occupancy';

const HOTEL_QUERY = gql`
  query($path: String!) {
    routes {
      get(slug: $path) {
        ... on Hotel {
          id
          canonicalSlug
          name
          area {
            id
            destination {
              id
              name
              canonicalSlug
              featuredImage {
                src
                srcset
                description
              }
              priceFlyTicket(currencyCode: "CZK") {
                amount
              }
            }
            priceTransfer(currencyCode: "CZK") {
              amount
            }
          }
          beaches {
            nextToHotel
          }
          content
          boards {
            name
          }
          darkHeader
          description
          distanceToBeach
          excerpt
          featuredImage {
            src
            srcset
            description
          }
          hideFlyTicketPrice
          hideTransferPrice
          hotelType {
            id
            stars
          }
          promoPackage {
            id
            board {
              name
            }
            description
            numberOfNights
            occupancy {
              id
              room {
                id
                area
                name
              }
            }
            priceAccommodation(currencyCode: "CZK") {
              amount
            }
            priceFlyTicket(currencyCode: "CZK") {
              amount
            }
            priceTotal(currencyCode: "CZK") {
              amount
            }
            priceTransfer(currencyCode: "CZK") {
              amount
            }
            validFrom
            validTo
          }
          rooms {
            area
            content
            id
            name
            occupancies {
              id
              adult
              child
            }
          }
        }
      }
    }
  }
`;

const PACKAGE_NODE_FRAGMENT = gql`
  fragment PackageNodeFragment on Package {
    board {
      name
    }
    description
    id
    name
    numberOfNights
    priceAccommodation(currencyCode: "CZK") {
      amount
    }
    priceFlyTicket(currencyCode: "CZK") {
      amount
    }
    priceTransfer(currencyCode: "CZK") {
      amount
    }
    priceTotal(currencyCode: "CZK") {
      amount
    }
    type
    validFrom
    validTo
  }
`;

const ROOM_DETAILS_QUERY = gql`
  query($id: ID!) {
    hotels {
      get(id: $id) {
        id
        rooms {
          id
          images {
            description
            src
            srcset
            rank
          }
          occupancies {
            id
            packages(first: 2, selector: ACTIVE, sort: BY_VALID_FROM) {
              edges {
                node {
                  ...PackageNodeFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${PACKAGE_NODE_FRAGMENT}
`;

const LOAD_PACKAGES_QUERY = gql`
  query($occupancyId: ID, $not: [ID!]) {
    packages {
      list(
        first: 100
        selector: ACTIVE
        sort: BY_VALID_FROM
        occupancyId: $occupancyId
        not: $not
      ) {
        edges {
          node {
            ...PackageNodeFragment
          }
        }
      }
    }
  }
  ${PACKAGE_NODE_FRAGMENT}
`;

interface LoadHotelByPathInput {
  path: string;
}

interface LoadRoomDetails {
  hotelId: HotelModel['id'];
}

interface LoadRoomPackagesInput {
  roomId: RoomModel['id'];
}

interface SetHotelCommit {
  hotel: HotelModel | null;
  path: string;
}

interface SetRoomdetailsCommit {
  hotelId: HotelModel['id'];
  rooms: { [key: string]: RoomModel };
}

interface AddRoomPackagesCommit {
  roomId: RoomModel['id'];
  packages: PackageModel[];
}

interface LoadHotelGalleryInput {
  id: string;
}

interface SetHotelGalleryCommit {
  hotelId: HotelModel['id'];
  media: GQLImage[] | null;
}

@Module({
  name: 'Hotel',
  stateFactory: true,
  namespaced: true,
})
export default class Hotel extends AbstractModule {
  public hotelLoading: boolean = false;
  public hotelGalleryLoading: boolean = false;
  public hotelGalleryCache: { [key: string]: GQLImage[] | null } = {};
  public hotel: HotelModel | null = null;

  public currentGalleryId: HotelModel['id'] | null = null;
  protected currentPath: string | null = null;

  @Action({ rawError: true })
  public loadHotelByPath(data: LoadHotelByPathInput): Promise<void> {
    if (this.currentPath === data.path) {
      return Promise.resolve();
    }

    this.setHotelLoading(true);

    return this.apollo
      .query<GQLRootQuery>({
        query: HOTEL_QUERY,
        variables: {
          path: data.path,
        },
      })
      .then((result) => {
        this.setCurrentPath(data.path);
        if (isGqlHotel(result.data.routes.get)) {
          this.setHotel({
            hotel: createHotel(result.data.routes.get),
            path: data.path,
          });
          return;
        }

        this.setHotel({
          hotel: null,
          path: data.path,
        });
      })
      .finally(() => {
        this.setHotelLoading(false);
      });
  }

  @Action({ commit: 'setRoomDetails', rawError: true })
  public loadRoomDetails(
    input: LoadRoomDetails
  ): Promise<SetRoomdetailsCommit> {
    return this.apollo
      .query<GQLRootQuery>({
        query: ROOM_DETAILS_QUERY,
        variables: {
          id: input.hotelId,
        },
      })
      .then((result) => {
        const commit: SetRoomdetailsCommit = {
          hotelId: input.hotelId,
          rooms: {},
        };

        if (!result.data.hotels.get) {
          return commit;
        }

        result.data.hotels.get.rooms.forEach((room) => {
          commit.rooms[room.id] = createRoom(room);
        });

        return commit;
      });
  }

  @Action({ commit: 'addRoomPackages', rawError: true })
  public loadRoomPackages(
    data: LoadRoomPackagesInput
  ): Promise<AddRoomPackagesCommit> {
    const commit: AddRoomPackagesCommit = {
      roomId: data.roomId,
      packages: [],
    };

    if (!this.hotel) {
      return Promise.resolve(commit);
    }
    const promises: Promise<any>[] = [];
    let occupancyIds: OccupancyModel['id'][] = [];
    let packageIds: PackageModel['id'][] = [];

    const theRoom = this.hotel.rooms.find((room) => room.id === data.roomId);
    if (theRoom) {
      occupancyIds = theRoom.occupancies.map((occupancy) => occupancy.id);
      packageIds = theRoom.packages.map((pkg) => pkg.id);
    }

    occupancyIds.forEach((occupancyId) => {
      const variables: PackagesToListArgs = {
        occupancyId,
        not: packageIds,
      };

      const promise = this.apollo
        .query<GQLRootQuery>({
          query: LOAD_PACKAGES_QUERY,
          variables,
        })
        .then((result) => {
          if (!result.data.packages.list) {
            return;
          }

          result.data.packages.list.edges.forEach((edge) => {
            commit.packages.push(createPackage(edge.node));
          });
        });

      promises.push(promise);
    });

    return (Promise as any).allSettled(promises).then(() => {
      return commit;
    });
  }

  @Action({ rawError: true })
  public loadHotelGallery(data: LoadHotelGalleryInput): Promise<void> {
    if (
      this.hotelGalleryCache.hasOwnProperty(data.id) &&
      this.hotelGalleryCache[data.id] &&
      (this.hotelGalleryCache[data.id] as GQLImage[]).length > 0
    ) {
      this.setCurrentGalleryId(data.id);
      return Promise.resolve();
    }

    this.setHotelGalleryLoading(true);
    return this.apollo
      .query<GQLRootQuery>({
        query: gql`
          query($id: ID!) {
            hotels {
              get(id: $id) {
                id
                images {
                  description
                  rank
                  src
                  srcset
                  videoUrl
                }
              }
            }
          }
        `,
        variables: {
          id: data.id,
        },
      })
      .then((result) => {
        if (result.data.hotels.get) {
          this.setHotelGallery({
            hotelId: data.id,
            media: result.data.hotels.get.images,
          });
          this.setCurrentGalleryId(data.id);
          return;
        }

        this.setHotelGallery({
          hotelId: data.id,
          media: null,
        });
        this.setCurrentGalleryId(data.id);
      })
      .finally(() => {
        this.setHotelGalleryLoading(false);
      });
  }

  @Mutation
  protected setCurrentPath(path: string) {
    this.currentPath = path;
  }

  @Mutation
  protected setCurrentGalleryId(id: HotelModel['id'] | null) {
    this.currentGalleryId = id;
  }

  @Mutation
  protected setHotel(data: SetHotelCommit): void {
    this.hotel = data.hotel;
  }

  @Mutation
  protected setHotelLoading(state: boolean): void {
    this.hotelLoading = state;
  }

  @Mutation
  protected setHotelGalleryLoading(state: boolean): void {
    this.hotelGalleryLoading = state;
  }

  @Mutation
  protected setRoomDetails(data: SetRoomdetailsCommit): void {
    if (!this.hotel || this.hotel.id !== data.hotelId) {
      return;
    }

    this.hotel.rooms = this.hotel.rooms.map((room) => {
      if (!data.rooms.hasOwnProperty(room.id)) {
        return room;
      }

      const roomDetails = data.rooms[room.id];

      if (roomDetails.images) {
        room.images = roomDetails.images;
      }

      if (roomDetails.packages) {
        room.packages = roomDetails.packages;
      }

      return room;
    });
  }

  @Mutation
  protected addRoomPackages(data: AddRoomPackagesCommit): void {
    if (!this.hotel) {
      return;
    }

    for (const room of this.hotel.rooms) {
      if (room.id !== data.roomId) {
        continue;
      }

      room.packages = room.packages.concat(data.packages);
      break;
    }
  }

  @Mutation
  protected setHotelGallery(data: SetHotelGalleryCommit) {
    this.hotelGalleryCache[data.hotelId] = data.media;
    Vue.set(this.hotelGalleryCache, data.hotelId, data.media);
  }
}
