import { BlogPostInterface } from 'elixir-theme';
import { Component } from 'vue-property-decorator';

import { VueComponent } from '~/utils/vue-component';

interface BlogPost {
  _embedded: {
    'wp:featuredmedia':
      | undefined
      | {
          alt_text: string;
          media_details: {
            width: number;
            height: number;
            sizes: {
              [key: string]: {
                width: number;
                height: number;
                source_url: string;
              };
            };
          };
          source_url: string;
        }[];
  };
  id: number;
  link: string;
  title: {
    rendered: string;
  };
}

@Component
export default class extends VueComponent<{}> {
  protected loading: boolean = true;

  protected posts: BlogPostInterface[] = [];

  protected get header(): string {
    return this.$t('app.blog.header').toString();
  }

  protected get bottomButtonText(): string {
    return this.$t('app.blog.button').toString();
  }

  protected get blogUrl(): string {
    return this.$t('app.blog.url').toString();
  }

  public mounted() {
    return fetch(this.blogUrl + '/wp-json/wp/v2/posts?per_page=3&_embed=true')
      .then((response) => response.json())
      .then((result: BlogPost[]) => {
        result.forEach((post) => {
          if (
            !post.title ||
            !post._embedded['wp:featuredmedia'] ||
            post._embedded['wp:featuredmedia'].length < 1
          ) {
            return;
          }

          const featured = post._embedded['wp:featuredmedia'][0];
          const ratio =
            featured.media_details.width / featured.media_details.height;
          const srcsets: string[] = [];

          for (const sizesKey in featured.media_details.sizes) {
            if (!featured.media_details.sizes.hasOwnProperty(sizesKey)) {
              continue;
            }
            const size = featured.media_details.sizes[sizesKey];
            const sizeRatio = size.width / size.height;
            // Make sure we create sourcesets only from matching ratios (with a slight rounding error)
            if (Math.abs(ratio - sizeRatio) > 0.001) {
              continue;
            }

            srcsets.push(`${size.source_url} ${size.width}w`);
          }

          this.posts.push({
            author: '',
            href: true,
            id: post.id.toString(),
            imgSrc: featured.source_url,
            imgSrcSet: srcsets.join(','),
            postTitle: post.title.rendered,
            routeName: post.link,
            target: '_blank',
          });
        });
      })
      .catch(() => {
        // ignore the error
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public render() {
    if (!this.loading && this.posts.length < 1) {
      return;
    }

    return (
      <v-container class='px-0'>
        {(() => {
          if (this.loading) {
            return <section-title class='mb-6' title={this.header} />;
          }
        })()}
        <v-skeleton-loader
          loading={this.loading}
          transition='scale-transition'
          type='card'
        >
          <blog-posts
            title={this.header}
            posts={this.posts}
            bottomButtonText={this.bottomButtonText}
            onCenterButtonClick={this.goToBlog}
          />
        </v-skeleton-loader>
      </v-container>
    );
  }

  protected goToBlog() {
    window.open(this.blogUrl, '_blank');
  }
}
