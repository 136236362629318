import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    footer: {
      copyright: `©${new Date().getFullYear()} + CK Elixír Tours`,
      destinations: {
        title: 'Destinace',
      },
      navigation: {
        blog: 'Blog',
        blogUrl: 'https://blog.elixirtours.cz',
        title: 'Navigace',
      },
      newsletter: {
        consent: 'Odběrem novinek souhlasíte se {0}',
        consentLink: 'zásadami ochrany osobních údajů',
      },
      social: {
        title: 'Sociální sítě',
      },
      travelDesigners: 'Naši travel designeři jsou tu pro vás',
      bar: {
        newsletter: {
          title: 'Odebírejte nabídky a články e-mailem',
        },
        social: {
          title: 'Sledujte nás',
        },
      },
    },
  },
};

export default i18n;
