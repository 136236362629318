import format from 'date-fns/format';
import { dateFnsLocalizations, defaultLocale } from '~/app/localization';
import { defaultDateFormat, defaultDateTimeFormat } from '~/utils/dateTime';

export default function(date: Date, dateFormat: string = defaultDateFormat) {
  return format(date, dateFormat, {
    locale: dateFnsLocalizations[defaultLocale],
  });
}

export function timeFormat(
  date: Date,
  dateFormat: string = defaultDateTimeFormat
) {
  return format(date, dateFormat, {
    locale: dateFnsLocalizations[defaultLocale],
  });
}
