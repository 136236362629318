import { GQLRoom } from '~/GqlTypes';
import createImage, { ImageModel } from '~/utils/image';
import createPackage, { PackageModel } from '~/utils/package';
import { sortByProperty } from '~/utils/sort';
import { OccupancyModel } from '~/utils/occupancy';

export interface RoomModel {
  content: string;
  id: string;
  images: ImageModel[];
  name: string;
  occupancies: OccupancyModel[];
  packages: PackageModel[];
}

export function createRoom(data: GQLRoom): RoomModel {
  const packages: PackageModel[] = [];

  const occupancies: OccupancyModel[] = [];

  if (data.occupancies) {
    for (const occupancy of data.occupancies) {
      occupancies.push(occupancy);

      if (occupancy.packages) {
        for (const edge of occupancy.packages.edges) {
          packages.push(createPackage(edge.node));
        }
      }
    }
  }

  const room: RoomModel = {
    content: data.content ? data.content : '',
    id: data.id,
    images: data.images ? data.images.map(createImage) : [],
    name: data.name ? data.name : '',
    occupancies,
    packages,
  };

  sortByProperty(room.images, 'rank');

  return room;
}
