import { RouteConfig } from 'vue-router';

import Hotel from '~/views/Hotel';

const config: RouteConfig = {
  component: Hotel,
  path: ':destination/:area/:hotel',
};

export default config;
