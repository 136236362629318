import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import Search from '~/app/core/store/modules/Search';
import { HotelList } from '~/components/templates/hotel';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { DestinationModel } from '~/utils/destination';
import { VueComponentMixin } from '~/utils/vue-component';
interface Results {
  loadingDestination?: boolean;
  destination?: DestinationModel | null;
}

@Component
export default class extends VueComponentMixin<Results, PrefetchComponent>(
  Prefetch
) {
  @Prop({ default: null })
  public destination!: DestinationModel | null;

  @Prop({ default: false, type: Boolean })
  public loadingDestination!: boolean;

  protected get searchStore(): Search {
    return getModule(Search, this.$store);
  }

  protected get canLoadMore(): boolean {
    if (this.searchStore.loading) {
      return false;
    }

    return this.searchStore.hasMoreResults;
  }

  protected get fetchingMore(): boolean {
    return this.searchStore.loading;
  }

  protected get listOfHotels() {
    return this.searchStore.searchResults;
  }

  protected get loading(): boolean {
    if (this.searchStore.searchResults.length > 0) {
      return false;
    }

    return this.searchStore.loading;
  }

  public render() {
    return (
      <HotelList
        hotels={this.listOfHotels}
        headline={
          this.searchStore.noResults ? this.$t('app.search.noResults') : ''
        }
        fetchingMore={this.fetchingMore}
        loading={this.loadingDestination || this.loading}
        buttonDisabled={!this.canLoadMore}
        onLoadMore={this.searchStore.loadMore}
      />
    );
  }
}
