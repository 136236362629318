import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    blog: {
      button: 'Více článků',
      header: 'Blog Elixír',
      title: 'Blog',
      url: 'https://blog.elixirtours.cz',
    },
    common: {
      close: 'Zavřít',
      enquire: 'Nezávazně poptat',
      opensInNewWindow: 'Otevře se v novém okně',
      search: 'Hledat',
      submit: 'Odeslat',
    },
    contacts: {
      phone: '+420 603 447 755',
      email: 'info@elixirtours.cz',
    },
    formats: {
      date: 'DD.MM.YYYY',
      dateTime: 'DD.MM.YYYY HH:mm:ss',
    },
    errors: {
      unknown: 'Neznámá chyba',
    },
    newsletterLink: {
      href:
        'https://app.smartemailing.cz/public/web-forms-v2/display-form/7544-ld96debtb9rphboa81smblwoy9gofz1q0531jo7o0ppnkk0mejzi7g0py7dafo9flgh7rd9mgsu3fkst1r7v07qjsyafzydl8h9i',
      label: 'Přihlásit k Newsletteru',
    },
    price: 'Cena',
    priceFrom: 'od {amount}',
    promotionOffer: 'Akční nabídka',
    siteName: 'Elixír Tours',
    wantOffer: 'Chci nabídku na míru',
    view: 'Prohlédnout',
  },
};

export default i18n;
