import { RouteConfig } from 'vue-router';

import SpecialOffer from '~/views/SpecialOffer';

const config: RouteConfig = {
  component: SpecialOffer,
  path: 'special-offer',
};

export default config;
