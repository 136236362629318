import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { Component, Mixins, Watch } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import insuranceIns from '~/assets/pojistka-ins.pdf';
import insuranceOdp from '~/assets/pojistka-odp.pdf';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import EnquiryForm from '~/components/templates/EnquiryForm';

@Component
export default class Faq extends Mixins(HeadManagement) {
  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected displayEnquiryForm: boolean = false;

  public title() {
    return this.$t('app.faq.pageTitle');
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <h1 class='elix-title primary--text text-center text-sm-left'>
            {this.$t('app.faq.title')}
          </h1>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Jak si mohu objednat individuální zájezd?
            </v-card-title>
            <v-card-text>
              <p>
                Vyberte si destinaci či hotel. Náš unikátní rezervační systém
                Vás povede:). Díky němu zjistíte předběžnou cenu zájezu na 1
                osobu. Tak si uděláte představu, kolik Vás dovolená bude stát:).
                Odešlete nezávaznou rezervaci a naši travel specialisté Vám
                obratem pošlou přesnou cenu zájezdu dle aktuálních cen letenek.
                Nevíte, která destinace či hotel je pro Vás ten pravý? Pošlete
                nám{' '}
                <a
                  role='button'
                  onClick={() => (this.displayEnquiryForm = true)}
                >
                  nezávazný formulář
                </a>
                , v němž nás seznámíte s Vašimi představami a sny a naši travel
                specialisté Vám pošlou nabídku šitou na míru. Samozřejmě nám
                můžete i <strong>kdykoliv zavolat</strong> na telefonní číslo{' '}
                <a href='tel:+420603447755'>+420 603 44 77 55</a>. Rádi Vás také
                přivítáme u nás v kanceláři, kde můžeme probrat Vaše představy a
                vytvořit Vaši ideální dovolenou společně.
              </p>
              <p>
                Cenově velmi lákavé jsou naše akční nabídky, které pravidelně
                zveřejňujeme na našich webových stránkách a sociálních sítích.
                Našim VIP klientům je odesíláme ve speciálním newsletteru. Být
                naším VIP klientem se rozhodně vyplatí - dostávají naše akční
                nabídky jako první a často je potěšíme slevovým voucherem.
                Přihlašte se proto k odběru našeho newsletteru:)
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Jak si mohu objednat skupinový zájezd?
            </v-card-title>
            <v-card-text>
              <p>
                Naše cestovní kancelář nabízí i skupinové pobyty se cvičením, se
                kterými má zkušenosti již 14 let. Naši nabídku najdete na
                stránkách a e-shopu Elixír active zde:{' '}
                <a href='https://www.elixiractive.cz' target='_blank'>
                  https://www.elixiractive.cz
                </a>
              </p>
              <p>
                V menu si vyberete některý z nabízených pobytů. Přidáte jej do
                košíku a pak už stačí poslat přihlášku k pobytu. Skupinový
                zájezd můžete zaplatit online na našem e-shopu či bankovním
                převodem.
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Kde najdu kancelář Elixír tours?
            </v-card-title>
            <v-card-text>
              <p>
                Naši cestovní kancelář, stejně jako další firmy "Elixíru" -
                můžete navštívit na adrese Prague Seasons, Legerova 1853/24, 120
                00 Praha.
              </p>
              <p>
                Rádi Vás u nás přivítáme a společně naplánujeme Vaši vysněnou
                dovolenou.
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Proč bych si měl/a objednat zájezd právě u Elixír tours?
            </v-card-title>
            <v-card-text>
              <p>
                Cestovní kancelář Elixír tours s.r.o. působí na trhu již 9.
                rokem a navazuje na činnost naší cestovní agentury, jež byla
                založena před 14 lety. Elixír Tours čerpá ze zkušeností z
                pořádání mnoha wellness pobytů Elixír studia a taktéž mnoha
                individuálních zájezdů do exotických destinací i pořádání svateb
                na bílých plážích. O úspěchu svědčí spokojenost klientů, kteří
                se často i opakovaně účastní skupinových zájezdů či kteří
                opakovaně s námi jezdí do exotiky na individuální zájezdy.
              </p>
              <p>
                Elixír tours není o kvantitě nabízených destinací a zájezdů.{' '}
                <strong>
                  Elixír tours je perfektním znalcem prostředí na Seychelách i
                  Mauriciu a dalších "svých" destinací
                </strong>
                , proto dokáže připravit unikátní zájezd do těchto destinací.
                Dopřejte si i Vy luxus za mimořádně příznivé ceny. Hotely, které
                najdete v naší nabídce, jsme osobně navštívili. Splníme Vaše sny
                o exkluzivní dovolené, ať už toužíte po dovolené na míru,
                dokonalé svatbě, svatební cestě nebo dovolené s golfem. Budeme
                se Vám věnovat osobně a s veškerou péčí.
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Mohu si u Vás objednat pouze některou službu, např. ubytování?
            </v-card-title>
            <v-card-text>
              <p>
                Ano, Elixír tours nabízí nejen ucelené zájezdy, ale i samostatné
                služby. Rádi pro Vás připravíme pouze letenku, transfery,
                ubytování nebo výlety. Neváhejte nás kontaktovat, pokud se Vaše
                přání liší od „klasiky“.
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              A co svatby na pláži? Kde je umíte uspořádat?
            </v-card-title>
            <v-card-text>
              <p>
                Je nám ctí pořádat pro naše klienty svatby na bílých plážích.
                Nejčastěji pořádáme svatby na Seychelách a Mauriciu, ale také na
                Srí Lance či Bali. Pořádáme také symbolické svatby na
                Maledivách.
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Za jakou cenu si lze objednat dovolenou v pohádkových destinacích
              jako jsou např. Seychely a Mauricius?
            </v-card-title>
            <v-card-text>
              <p>
                Naše balíčky (pobyt na 8 dní s polopenzí, leteckou dopravou z
                Prahy, transfery, delegátem atd.) začínají již na 39 990 Kč.
                Seychely i Mauricius perfektně známe, splníme Vám dovolenou v
                tomto ráji za reálnou cenu.
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Je Elixír tours pojištěna proti úpadku?
            </v-card-title>
            <v-card-text>
              <p>Ano.</p>
              <p>
                Elixír tours má u <strong>ERV pojišťovny</strong>, a.s.
                uzavřenou pojistnou smlouvu č.1180000496 na pojištění záruky pro
                případ úpadku cestovní kanceláře.
              </p>
              <p>
                Elixír tours má u ERV pojišťovny, a.s. uzavřenou pojistnou
                smlouvu č. 1160000747 na pojištění záruky odpovědnosti za škodu
                pro pořadatele zájezdu.
              </p>
              <p>
                Pro prověření, že naše cestovní kancelář má sjednáno povinné
                pojištění cestovních kanceláří v případě úpadku, klikněte zde:{' '}
                <a
                  href='https://www.ervpojistovna.cz/cs/ck-pojistene-pro-pripad-upadku'
                  target='_blank'
                >
                  https://www.ervpojistovna.cz/cs/ck-pojistene-pro-pripad-upadku
                </a>
              </p>
              <p>
                Níže scan originálních listin pojištění CK proti úpadku a za
                odpovědnost za případné škody
              </p>
              <p>
                <a
                  href={insuranceIns}
                  target='_blank'
                  download='pojistka-ins.pdf'
                >
                  ERV – pojistka pro případ úpadku
                </a>
              </p>
              <p>
                <a
                  href={insuranceOdp}
                  target='_blank'
                  download='pojistka-odp.pdf'
                >
                  ERV – pojistka odpovědnosti za škodu
                </a>
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Jaké nabízíte cestovní pojištění?
            </v-card-title>
            <v-card-text>
              <p>
                <strong>
                  Spolupracujeme s předním poskytovatelem cestovního pojištění.
                </strong>{' '}
                ERV pojišťovna poskytuje nadstandardní služby v oblasti
                cestovního pojištění a již několik let patří mezi TOP 3
                pojišťovny v ČR. ERV Evropská pojišťovna má více jak stoletou
                tradici. Mimo běžného cestovního pojištění Vašich cest nabízíme
                možnost pojištění <strong>storna zájezdu</strong>. S námi se
                nemusíte bát, že by Vaše pojištění nepokrylo to, co potřebujete.
                Pokrývá i mnoho okolností týkající se nemoci Covid 19, zeptejte
                se nás - rádi vám vše vysvětlíme.
              </p>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Mohu platit pomocí benefitů?
            </v-card-title>
            <v-card-text>
              <p>
                <strong>
                  Spolupracujeme se všemi předními společnostmi zaměstnaneckých
                  benefitů
                </strong>
                : (akceptujeme poukázky, šeky, karty, on-line poukázky, atd.)
              </p>
              <ul>
                <li>SODEXO</li>
                <li>BENEFITY a.s.</li>
                <li>EDENRED s.r.o.</li>
                <li>BENEFIT PLUS a.s.</li>
                <li>CHEQUE DÉJEUNER a.s.(unišeky)</li>
              </ul>
            </v-card-text>
          </v-card>
          <v-card class='my-3'>
            <v-card-title class='headline accent--text'>
              Mohu cestovat sám / sama?
            </v-card-title>
            <v-card-text>
              <p>
                Ano, všechny naše hotely v nabídce mají i jednolůžkové pokoje.
                Ceny jsou na vyžádání, doporučíme Vám ubytování, které se pro
                jednotlivce vyplatí.
              </p>
            </v-card-text>
          </v-card>
        </v-container>
        <v-dialog v-model={this.displayEnquiryForm} fullscreen>
          <v-card flat>
            <v-container>
              <v-row class='fill-height' justify='center' align='center'>
                <v-col>
                  <EnquiryForm isMobile={this.isMobile} />
                </v-col>
              </v-row>
            </v-container>
            <v-btn
              icon
              absolute
              top
              right
              onClick={() => (this.displayEnquiryForm = false)}
            >
              <v-icon>$vuetify.icons.close</v-icon>
            </v-btn>
          </v-card>
        </v-dialog>
      </div>
    );
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }
}
