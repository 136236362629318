import { RouteConfig } from 'vue-router';

import References from '~/views/References';

const config: RouteConfig = {
  component: References,
  path: 'references',
};

export default config;
