export interface GeoPosition {
  latitude: number;
  longitude: number;
}

export function createPosition(
  latitude?: number | null,
  longitude?: number | null
): GeoPosition | null {
  if (!latitude || !longitude) {
    return null;
  }

  return {
    latitude,
    longitude,
  };
}
