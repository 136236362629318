import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';

import { Component, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { SOCIAL_LINKS } from '~/utils/social';
import { SocialLinkInterface, SocialLinksEnum } from 'elixir-theme';
import NewsletterSignup, {
  SignupEvent,
} from '~/components/organisms/NewsletterSignup';

interface NewsletterHandler {
  loading: boolean;
  active: boolean;
}

@Component
export default class extends VueComponent<{}> {
  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected newsletter: NewsletterHandler = {
    active: false,
    loading: false,
  };

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  protected get socialLinks(): SocialLinkInterface[] {
    const socialList: SocialLinkInterface[] = [];
    for (const social in SOCIAL_LINKS) {
      if (SOCIAL_LINKS.hasOwnProperty(social)) {
        const socialLink = SOCIAL_LINKS[social];
        if (!socialLink.enabled) {
          continue;
        }

        socialList.push({
          type: socialLink.icon as SocialLinksEnum,
          link: this.$i18n.t(socialLink.url).toString(),
          color: '#00abca',
          width: '50px',
        });
      }
    }

    return socialList;
  }

  public render() {
    return (
      <v-container class='px-0 pb-4 pb-md-6 pt-4 pt-md-6'>
        <newsletter
          title={this.$t('app.footer.bar.newsletter.title')}
          socialMediaIcons={this.socialLinks}
          socialMediaTitle={this.$t('app.footer.bar.social.title')}
          subscriptionFormActive={this.newsletter.active}
        >
          <NewsletterSignup
            active={this.newsletter.active}
            onSignup={this.newsletterSignup}
            loading={this.newsletter.loading}
          />
        </newsletter>
      </v-container>
    );
  }

  /**
   * TODO: Implement signup
   * @param data
   */
  protected newsletterSignup(data: SignupEvent) {
    this.newsletter.loading = true;

    Promise.resolve().finally(() => {
      this.newsletter.loading = false;
    });
  }
}
