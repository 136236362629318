import { BigBlockInterface } from 'elixir-theme';
import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import Promotions, {
  SetPromoHotelsCommit,
} from '~/app/core/store/modules/Promotions';
import { createBigHotelBlock } from '~/utils/hotel';
import { VueComponent } from '~/utils/vue-component';

const PROMO_LIMIT_PER_PAGE = 3;

@Component
export default class extends VueComponent<{}> {
  protected loading: boolean = true;

  protected limit: number = PROMO_LIMIT_PER_PAGE;

  protected loadButtonDisabled: boolean = false;

  protected get header(): string {
    return this.$t('app.promotionOffer').toString();
  }

  protected get buttonText(): string {
    return this.$t('app.buttons.morePromotionOffers').toString();
  }

  protected get blocks(): BigBlockInterface[] {
    const promotionsModule = getModule(Promotions, this.$store);

    return promotionsModule.promoHotels.map((hotel) =>
      createBigHotelBlock(hotel, this.$router, this.$i18n)
    );
  }

  public mounted() {
    getModule(Promotions, this.$store)
      .loadPromoHotels()
      .then((data: SetPromoHotelsCommit) => {
        this.loadButtonDisabled = data.hotels.length <= this.limit;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public render() {
    if (this.loading) {
      return (
        <v-container>
          <h3 class='primary--text mt-4 mb-3 elix-title text-center text-sm-left'>
            {this.header}
          </h3>
          <v-row>
            {(() => {
              const skeletons: JSX.Element[] = [];
              for (let i = 0; i < 3; i++) {
                skeletons.push(
                  <v-col cols='12' md='4'>
                    <v-skeleton-loader type='card' />
                  </v-col>
                );
              }
              return skeletons;
            })()}
          </v-row>
        </v-container>
      );
    }

    if (this.blocks.length < 1) {
      return;
    }

    return (
      <big-blocks-preview
        blocks={this.blocks.slice(0, this.limit)}
        header={this.header}
        bottomButtonText={this.buttonText}
        bottomButtonDisabled={this.loadButtonDisabled}
        bottomButtonVuetifyClass='accent'
        onCenterButtonClick={this.loadMoreOffers}
      />
    );
  }

  protected loadMoreOffers() {
    if (this.limit < this.blocks.length) {
      this.limit += PROMO_LIMIT_PER_PAGE;
    }

    if (this.limit >= this.blocks.length) {
      this.loadButtonDisabled = true;
    }
  }
}
