enum Sections {
  BENEFITS = 'hp-benefits',
  BLOG_ARTICLES = 'hp-blog-articles',
  CAROUSEL = 'hp-carousel',
  CHOOSE_DESTINATION = 'hp-choose-destination',
  DESTINATION_LIST = 'hp-destination-list',
  PROMOTED_OFFERS = 'hp-promoted-offers',
  PROMOTION_BLOCKS = 'hp-promotion-blocks',
}

export default Sections;
