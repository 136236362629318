export default {
  app: {
    specialOffer: {
      title: 'Akční nabídka zájezdů',
      pageTitle: 'Akční nabídka | CK Elixír Tours',
      description:
        'Akční nabídka Elixír Tours speciálně sestavena našimi Travel specialistkami.',
      destinationLink: 'Všechny zájezdy - {destination}',
      loading: 'Načítání akcí a speciálních nabídek',
      noOffers: 'Na akčních nabídkách této destinace aktuálně pracujeme.',
    },
  },
};
