import { RouteConfig } from 'vue-router';

import About from '~/views/About';

const config: RouteConfig = {
  component: About,
  path: 'about',
};

export default config;
