import { Component, Ref, Vue } from 'vue-property-decorator';

import ScrollVisibility, {
  ScrollVisibilityOptions,
  ScrollDirection,
  VisibilityState,
  ScrollVisibilityEvent,
} from '~/utils/ScrollVisibility';

@Component
export default class ScrollTopButton extends Vue {
  protected visible: boolean = false;
  protected scrollVisibility!: ScrollVisibility;

  @Ref('scrollReference')
  protected scrollReference!: HTMLElement;

  public mounted() {
    const options: ScrollVisibilityOptions = {
      direction: ScrollDirection.UP,
      // Dummy element as we don't care about positioning
      element: this.scrollReference,
      initialState: VisibilityState.HIDDEN,
      // Show the scrollToTop button when user scrolls up more than 200 pixels
      length: 200,
    };

    this.scrollVisibility = new ScrollVisibility(options);

    this.scrollVisibility.eventBus.$on(
      VisibilityState.SHOWN,
      (params: ScrollVisibilityEvent) => {
        if (params.windowScroll.below) {
          this.visible = true;
          this.scrollVisibility.shown = this.visible;
        }
      }
    );

    this.scrollVisibility.eventBus.$on(VisibilityState.HIDDEN, () => {
      this.visible = false;
      this.scrollVisibility.shown = this.visible;
    });
  }

  public render() {
    return (
      <div>
        <div class='elix-scroll-reference' ref='scrollReference' />
        <v-fab-transition>
          {this.visible && (
            <v-btn
              fab={true}
              fixed={true}
              left={true}
              bottom={true}
              color='primary'
              onClick={this.scrollToTop}
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          )}
        </v-fab-transition>
      </div>
    );
  }

  protected scrollToTop(e: Event) {
    e.preventDefault();

    this.visible = false;
    this.scrollVisibility.shown = this.visible;

    const anchor = e.currentTarget as HTMLElement;
    if (!anchor) {
      return;
    }

    if (window.pageYOffset > 0) {
      this.scrollVisibility.scrollLock = true;
      setTimeout(() => {
        this.scrollVisibility.scrollLock = false;
      }, 1000);

      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
