import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    social: {
      facebookUrl: 'https://www.facebook.com/Elixirtours.cz',
      instagramUrl: 'https://www.instagram.com/elixirtours/',
      twitterUrl: '',
      youtubeUrl: 'https://www.youtube.com/channel/UC9iKCxsNSgW8w6Fgs29BZsQ',
    },
  },
};

export default i18n;
