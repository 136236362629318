import Vue from 'vue';
import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';

import { HOTEL_LIST_FRAGMENT } from '~/components/templates/hotel/List';
import { createHotel, HotelModel } from '~/utils/hotel';
import {
  GQLPageInfo,
  GQLRootQuery,
  GQLSearchVacancySort,
  SearchToHotelArgs,
  SearchToVacancyArgs,
} from '~/GqlTypes';
import gql from 'graphql-tag';
import { DestinationModel } from '~/utils/destination';

const SEARCH_QUERY = gql`
  query(
    $first: Int!
    $after: String
    $selector: SearchVacancyQuery!
    $sort: SearchVacancySort = null
  ) {
    search {
      vacancy(first: $first, after: $after, selector: $selector, sort: $sort) {
        edges {
          node {
            id
            hotel {
              ...HotelFragment
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${HOTEL_LIST_FRAGMENT}
`;

const FULLTEXT_QUERY = gql`
  query($first: Int!, $after: String, $selector: SearchHotelQuery!) {
    search {
      hotel(first: $first, after: $after, selector: $selector) {
        edges {
          node {
            ...HotelFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
  ${HOTEL_LIST_FRAGMENT}
`;

export interface SearchInput {
  numberOfNights: {
    max: number;
    min: number;
  };
  date: {
    from: Date;
    to: Date;
  };
  destinations: DestinationModel[];
}

export interface FulltextInput {
  term: string;
}

interface AddSearchResultCommit {
  hotels: HotelModel[];
}

interface SortedHotelModel extends HotelModel {
  sortRank: number;
}

@Module({
  name: 'Search',
  stateFactory: true,
  namespaced: true,
})
export default class Search extends AbstractModule {
  public loading: boolean = false;

  public noResults: boolean = false;

  protected rawSearchResults: { [key: string]: SortedHotelModel } = {};

  protected vacancyArgs: SearchToVacancyArgs | null = null;

  protected fulltextArgs: SearchToHotelArgs | null = null;

  protected pageInfo: GQLPageInfo = {
    hasNextPage: false,
    hasPreviousPage: false,
  };

  public get hasMoreResults(): boolean {
    return this.pageInfo.hasNextPage;
  }

  public get searchResults(): HotelModel[] {
    const hotels: SortedHotelModel[] = [];
    for (const key in this.rawSearchResults) {
      if (this.rawSearchResults.hasOwnProperty(key)) {
        hotels.push(this.rawSearchResults[key]);
      }
    }

    hotels.sort((a, b) => {
      return a.sortRank - b.sortRank;
    });

    return hotels;
  }

  @Action({ commit: 'addSearchResults', rawError: true })
  public search(data: SearchInput): Promise<AddSearchResultCommit> {
    this.setLoading(true);
    this.setNoResults(false);
    this.unsetSearchResults();

    const variables: SearchToVacancyArgs = {
      first: 10,
      after: undefined,
      selector: {
        currencyCode: 'CZK',
        numberOfNightsFrom: data.numberOfNights.min,
        numberOfNightsTo: data.numberOfNights.max,
        destinations: data.destinations.map((destination) => destination.id),
        validFrom: data.date.from,
        validTo: data.date.to,
      },
      sort: GQLSearchVacancySort.CHEAPEST,
    };

    this.setVacancyArgs(variables);

    return this.apollo
      .query<GQLRootQuery>({
        query: SEARCH_QUERY,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        this.setPageInfo(result.data.search.vacancy.pageInfo);
        return {
          hotels: result.data.search.vacancy.edges.map(({ node }) => {
            return createHotel(node.hotel);
          }),
        };
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action({ commit: 'addSearchResults', rawError: true })
  public fulltextSearch(data: FulltextInput): Promise<AddSearchResultCommit> {
    this.setLoading(true);
    this.setNoResults(false);
    this.unsetSearchResults();

    const variables: SearchToHotelArgs = {
      first: 10,
      after: undefined,
      selector: {
        name: data.term,
      },
    };

    this.setFulltextArgs(variables);

    return this.apollo
      .query<GQLRootQuery>({
        query: FULLTEXT_QUERY,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        this.setPageInfo(result.data.search.hotel.pageInfo);
        return {
          hotels: result.data.search.hotel.edges.map(({ node }) => {
            return createHotel(node);
          }),
        };
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action({ commit: 'addSearchResults', rawError: true })
  public loadMore(): Promise<AddSearchResultCommit> {
    if (this.pageInfo && !this.pageInfo.hasNextPage) {
      return Promise.resolve({ hotels: [] });
    }

    this.setLoading(true);

    const variables = this.vacancyArgs || this.fulltextArgs;

    if (!variables) {
      return Promise.reject(new Error('Search args were not set'));
    }

    if (this.pageInfo) {
      variables.after = this.pageInfo.endCursor;
    }

    if (this.vacancyArgs) {
      return this.apollo
        .query<GQLRootQuery>({
          query: SEARCH_QUERY,
          variables,
        })
        .then((result) => {
          this.setPageInfo(result.data.search.vacancy.pageInfo);
          return {
            hotels: result.data.search.vacancy.edges.map(({ node }) => {
              return createHotel(node.hotel);
            }),
          };
        })
        .finally(() => {
          this.setLoading(false);
        });
    }

    return this.apollo
      .query<GQLRootQuery>({
        query: FULLTEXT_QUERY,
        variables,
      })
      .then((result) => {
        this.setPageInfo(result.data.search.hotel.pageInfo);
        return {
          hotels: result.data.search.hotel.edges.map(({ node }) => {
            return createHotel(node);
          }),
        };
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setNoResults(state: boolean) {
    this.noResults = state;
  }

  @Mutation
  protected setVacancyArgs(args: SearchToVacancyArgs | null) {
    this.vacancyArgs = args;
  }

  @Mutation
  protected setFulltextArgs(args: SearchToHotelArgs | null) {
    this.fulltextArgs = args;
  }

  @Mutation
  protected setPageInfo(pageInfo: GQLPageInfo) {
    this.pageInfo = pageInfo;
  }

  @Mutation
  protected unsetSearchResults() {
    this.vacancyArgs = null;
    this.fulltextArgs = null;
    this.rawSearchResults = {};
    this.pageInfo.startCursor = undefined;
    this.pageInfo.endCursor = undefined;
  }

  @Mutation
  protected addSearchResults(data: AddSearchResultCommit) {
    let sortRank = -1;
    for (const key in this.rawSearchResults) {
      if (
        this.rawSearchResults.hasOwnProperty(key) &&
        sortRank < this.rawSearchResults[key].sortRank
      ) {
        sortRank = this.rawSearchResults[key].sortRank;
      }
    }

    sortRank++;

    for (const hotel of data.hotels) {
      const sortedHotel: SortedHotelModel = {
        ...hotel,
        sortRank: sortRank++,
      };
      Vue.set(this.rawSearchResults, hotel.id, sortedHotel);
    }

    if (Object.keys(this.rawSearchResults).length < 1) {
      this.noResults = true;
    }
  }
}
