import { Component, Prop } from 'vue-property-decorator';

import { VueComponent } from '~/utils/vue-component';
import { getLocalizedRoute, routes } from '~/app/core/router/routes';

interface ConsentSetence {
  hasErrors?: boolean;
}

@Component
export default class extends VueComponent<ConsentSetence>
  implements ConsentSetence {
  @Prop({ default: false, type: Boolean })
  public hasErrors!: boolean;

  public render() {
    return (
      <i18n
        path='app.form.consent'
        tag='span'
        class={this.hasErrors ? 'body-2 error--text' : 'body-2 primary--text'}
      >
        <v-tooltip
          bottom
          scopedSlots={{
            activator: (scope: any) => {
              return (
                <a
                  href={
                    this.$router.resolve(
                      getLocalizedRoute(routes.dataProtection, this.$router)
                    ).href
                  }
                  target='_blank'
                  {...{
                    on: scope.on,
                  }}
                  onClick={this.click}
                >
                  {this.$t('app.form.consentLink')}*
                </a>
              );
            },
          }}
        >
          {this.$t('app.common.opensInNewWindow')}
        </v-tooltip>
      </i18n>
    );
  }

  protected click(e: Event) {
    e.stopPropagation();
  }
}
