import { Component, Prop, Ref } from 'vue-property-decorator';

import { VueComponent } from '~/utils/vue-component';
import { DestinationModel } from '~/utils/destination';
import { GeoPosition } from '~/utils/GeoPosition';
import mapsApiLoader from '~/utils/googleMapsApiLoader';

import style from './PreviewBlock.scss';

interface PreviewBlock {
  destination: DestinationModel;
  position: GeoPosition | null;
  title: string;
}

interface MapsApi {
  failed: boolean;
  loaded: boolean;
  loading: boolean;
  map: google.maps.Map | null;
}

@Component({
  style,
})
export default class extends VueComponent<PreviewBlock>
  implements PreviewBlock {
  @Prop({ required: true })
  public destination!: DestinationModel;

  @Prop({ required: false, default: null })
  public position!: GeoPosition | null;

  @Prop({ required: true, type: String })
  public title!: string;

  @Ref()
  protected readonly mapsApiBlock?: HTMLElement;

  protected mapsApi: MapsApi = {
    failed: false,
    loaded: false,
    loading: false,
    map: null,
  };

  protected get imageSrc(): string {
    if (!this.destination.listImage) {
      return '';
    }

    return this.destination.listImage.src;
  }

  public beforeMount() {
    if (this.position) {
      this.mapsApi.loading = true;
      mapsApiLoader()
        .then(() => {
          this.mapsApi.loaded = true;
          this.initializeMap();
        })
        .catch((err) => {
          this.mapsApi.failed = true;
        })
        .finally(() => {
          this.mapsApi.loading = false;
        });
    }
  }

  public mounted() {
    this.initializeMap();
  }

  public beforeUpdate() {
    this.destroyMap();
  }

  public updated() {
    this.initializeMap();
  }

  public beforeDestroy() {
    this.destroyMap();
  }

  public render() {
    return (
      <map-description
        title={this.title}
        subtitle={this.destination.excerpt}
        text={this.destination.content}
        mapImgSrc={this.imageSrc}
      >
        {this.renderMap()}
      </map-description>
    );
  }

  protected renderMap() {
    if (this.mapsApi.loaded) {
      return <div class='google-map' slot='image' ref='mapsApiBlock' />;
    }

    if (this.position && this.mapsApi.loading) {
      return <v-skeleton-loader slot='image' type='image' />;
    }
  }

  protected initializeMap() {
    if (
      !this.position ||
      !this.mapsApi.loaded ||
      this.mapsApi.map ||
      !this.mapsApiBlock
    ) {
      return;
    }

    this.mapsApi.map = new google.maps.Map(this.mapsApiBlock, {
      center: {
        lat: this.position.latitude,
        lng: this.position.longitude,
      },
      zoom: 15,
    });
  }

  protected destroyMap() {
    if (this.mapsApi.map) {
      this.mapsApi.map.unbindAll();
      this.mapsApi.map = null;
    }
  }
}
