import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

interface HTMLRenderer {
  content: string;
}

@Component
export default class extends VueComponent<HTMLRenderer>
  implements HTMLRenderer {
  @Prop({ required: true, type: String })
  public content!: string;

  protected templateRenderer: any;

  /**
   * Recompiles the content on the client side
   */
  public mounted() {
    if (this.content.trim() === '') {
      return;
    }

    const { render, staticRenderFns } = Vue.compile(
      `<div>${this.content}</div>`
    );

    this.templateRenderer = render;
    this.$options.staticRenderFns = staticRenderFns;

    this.$forceUpdate();
  }

  public render() {
    if (this.templateRenderer) {
      return this.templateRenderer();
    }

    return <div>{this.content}</div>;
  }
}
