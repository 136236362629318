import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';
import { default as Base } from './common/base';
import { default as Routes } from './routes';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  Base,
  Routes,
]) as LocaleMessages;

export default mergedLocalizations;
