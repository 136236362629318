import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';

interface UserConsent {
  cookie: {
    accepted: boolean;
  };
}

function isUserConsent(data: any): data is UserConsent {
  if (!data) {
    return false;
  }

  if (
    !data.cookie ||
    (data.cookie.accepted !== false && data.cookie.accepted !== true)
  ) {
    return false;
  }

  return true;
}

const userConsentLocalStorageKey = 'app.userConsent';

const defaultConsent: () => UserConsent = () => {
  return {
    cookie: {
      accepted: false,
    },
  };
};

@Module({
  name: 'Visitor',
  stateFactory: true,
  namespaced: true,
})
export default class Visitor extends AbstractModule {
  protected userConsent: UserConsent = defaultConsent();

  public get acceptedCookie() {
    return this.userConsent.cookie.accepted;
  }

  @Action({ commit: 'setUserConsent', rawError: true })
  public getUserConsent(): Promise<UserConsent> {
    const consentJson = window.localStorage.getItem(userConsentLocalStorageKey);
    if (consentJson) {
      try {
        const consent = JSON.parse(consentJson);

        if (isUserConsent(consent)) {
          return Promise.resolve(consent);
        }
      } catch (e) {
        // Invalid json
      }
    }

    return Promise.resolve(defaultConsent());
  }

  @Action
  public acceptCookiePolicy(): Promise<void> {
    this.setCookiePolicy(true);
    this.saveConsent();
    return Promise.resolve();
  }

  @Mutation
  protected setCookiePolicy(state: boolean) {
    this.userConsent.cookie.accepted = state;
  }

  @Mutation
  protected setUserConsent(consent: UserConsent) {
    this.userConsent = consent;
  }

  @Mutation
  protected saveConsent() {
    window.localStorage.setItem(
      userConsentLocalStorageKey,
      JSON.stringify(this.userConsent)
    );
  }
}
