import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { ImageModel } from '~/utils/image';

interface GalleryRow {
  items: ImageModel[];
}

@Component
export default class extends VueComponent<GalleryRow> implements GalleryRow {
  @Prop({ required: true })
  public items!: ImageModel[];

  public render() {
    return (
      <v-row justify='center' justify-sm='space-between' align='center'>
        {this.items.map((item) => {
          return (
            <v-col class='pa-3' cols='6' sm='2'>
              <v-img src={item.src} aspect-ratio={1} />
            </v-col>
          );
        })}
      </v-row>
    );
  }
}
