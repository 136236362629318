declare var api_url: string;

let apiUrl: string = '/';

if (process.env.VUE_ENV === 'server' && process.env.BACKEND_API_URL) {
  apiUrl = process.env.BACKEND_API_URL;
} else if (typeof api_url !== 'undefined') {
  apiUrl = api_url;
}

export default apiUrl;
