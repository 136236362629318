export default {
  app: {
    form: {
      consent: 'Souhlasím se {0}',
      consentLink: 'zpracováním osobních údajů',
      enquiry: {
        chosenOffer: 'Vybrali jste poptávku na zájezd v ceně {amount}',
        changeOffer: 'Chci změnit nabídku',
        destination: 'Destinace',
        from: 'Ideálně od',
        email: 'E-mail',
        hotel: 'Hotel',
        length: 'Délka pobytu',
        lengthTitle: 'Délka pobytu od {from} do {to} dní',
        message: 'Vaše zpráva',
        name: 'Jméno a příjmení',
        phone: 'Telefon',
        to: 'Ideálně do',
      },
      tailoredOffer: {
        title: 'Nezávazná poptávka zájezdu',
        adults: 'Počet dospělých osob',
        children: 'Počet dětí',
        budget: 'Maximální celkový rozpočet zájezdu',
      },
      error: {
        consent: {
          missing:
            'Z důvodu zpracování formuláře musíte souhlasit se zpracováním osobních údajů.',
        },
        destination: {
          missing: 'Prosím vyberte destinaci.',
        },
        date: {
          invalidRange: 'Rozsah datumů je neplatný.',
          missingFrom: 'Zadejte datum "od".',
          missingTo: 'Zadejte datum "do".',
        },
        email: {
          invalid: 'Zadaný e-mail neprošel kontrolou.',
          missing: 'Prosím zadejte e-mail.',
        },
        length: {
          invalid: 'Rozsah délky pobytu je neplatný',
        },
        mailNotSent:
          'Nastala chyba při odesílání e-mailu. Prosím zkuste to znovu později.',
        makeCorrections: 'Prosím opravte výše uvedené chyby',
        hotel: {
          missing: 'Prosím vyberte hotel.',
        },
        name: {
          missing: 'Prosím zadejte své jméno.',
        },
        recaptcha: 'Nastala chyba při ověřování formuláře.',
        adults: 'Prosím zadejte počet dospělých (alespoň jeden).',
        children: 'Prosím zadejte počet dětí',
        common:
          'Nastala chyba při odesílání formuláře. Zkontrolujte prosím své údaje a zkuste to znovu.',
      },
      hotelEnquiry: {
        title: 'Nezávazná poptávka hotelu',
      },
      inquirySuccesfull:
        'Poptávka byla úspěšně odeslána. Ozveme se vám co nejdříve to bude možné.',
      packageChosenInfo:
        'Změna těchto údajů není možná, protože jste vybrali konkrétní balíček. Pokud chcete změnit datum, klikněte na tlačítko "Chci změnit nabídku" nad formulářem.',
      recaptcha: {
        loading: 'Probíhá ověření uživatele pomocí Google ReCaptcha',
      },
      weddingEnquiry: {
        title: 'Nezávazná poptávka svatby',
      },
    },
  },
};
