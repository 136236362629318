import ActivityCarousel from './ActivityCarousel';
import DestinationMapSection from './DestinationMapSection';
import Header from './Header';
import Information from './Information';
import List from './List';
import RelatedHotels from './RelatedHotels';
import RoomSection from './RoomSection';

export {
  ActivityCarousel,
  DestinationMapSection,
  Header as HotelHeader,
  Information as HotelInformation,
  List as HotelList,
  RelatedHotels,
  RoomSection,
};
