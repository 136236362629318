export default {
  app: {
    destination: {
      choose: 'Vybrat destinaci',
      chooseDreamDestination: 'Vyberte si vysněnou destinaci a hotel',
      chooseDreamDestinationSubtitle:
        'Nebo si nechte připravit nabídku podle vašich možností a přání ',
      moreInfo: 'Více o destinaci',
      offer: 'Nabízíme následující destinace',
      showAll: 'Zobrazit všechny destinace',
    },
  },
};
