import annotate from './annotate';
import format from './format';
import { GQLCurrency } from '~/GqlTypes';

export interface CurrencyModel {
  code: string;
}

export function createCurrency(data: GQLCurrency): CurrencyModel {
  return {
    code: data.code ? data.code : '',
  };
}

export { annotate, format };
