export default {
  app: {
    package: {
      season: 'Termín',
      price: {
        accommodation: 'Cena ubytování',
        flight: 'Cena letenky',
        perRoomPerNight: 'Orientační cena zájezdu na osobu',
        total: 'Orientační cena zájezdu na osobu',
        totalDescription:
          'Cena se řídí aktuálním kurzem CZK a dalšími faktory.',
        transfer: 'Cena transferu',
      },
    },
  },
};
