import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';

import appEnv from '~/app/core/appEnv';
import ConsentSentence from '~/components/molecules/ConsentSentence';
import DatePickerField from '~/components/organisms/DatePickerField';
import { GQLRootMutation, GQLTailoredOffer } from '~/GqlTypes';
import { format, parse } from '~/utils/date-fns';
import { VueComponent } from '~/utils/vue-component';
import Timeout = NodeJS.Timeout;
import { pushEvent } from '~/utils/dataLayer';
import DestinationMultiselect from '../molecules/Form/DestinationMultiselect';

interface TailoredOfferForm {
  isMobile?: boolean;
  onRequestCustomOffer?: () => void;
}

interface FormErrors {
  [key: string]: string[];
  name: string[];
  email: string[];
  phone: string[];
  dateFrom: string[];
  dateTo: string[];
  length: string[];
  destinations: string[];
  adults: string[];
  children: string[];
  budget: string[];
  message: string[];
  consent: string[];
}

export enum Events {
  RESET = 'resetForm',
}

@Component({
  components: {
    VueRecaptcha,
  },
})
export default class extends VueComponent<TailoredOfferForm>
  implements TailoredOfferForm {
  @Prop({ default: IS_MOBILE_BY_DEFAULT, type: Boolean })
  public isMobile!: boolean;

  @Ref('datePickerFrom')
  protected datePickerFrom!: Vue;

  @Ref('datePickerTo')
  protected datePickerTo!: Vue;

  @Ref('recaptcha')
  protected recaptcha!: VueRecaptcha;

  protected recaptchaStatus: Recaptcha = {
    enabled: !!appEnv.GOOGLE_RECAPTCHA_KEY,
    loading: {
      promise: null,
      reject: null,
      resolve: null,
    },
    verification: {
      promise: null,
      reject: null,
      resolve: null,
    },
    ready: false,
    required: false,
  };

  protected submitting: boolean = false;

  protected submited: boolean = false;

  /*
   * Form data
   */

  protected consentThrottle?: Timeout;

  protected today!: Date;

  protected name: string = '';

  protected email: string = '';

  protected phone: string = '';

  protected dateFrom?: Date;

  protected dateTo?: Date;

  protected lengthFrom: number = 6;

  protected lengthTo: number = 10;

  protected selectedDestinations: string[] = [];

  protected adults: string | null = null;

  protected children: string | null = null;

  protected budget: string | null = null;

  protected message: string = '';
  /*
   * Form fields
   */
  protected consent: boolean = false;

  protected get numberOfNightsStringified(): string {
    if (!this.lengthFrom || !this.lengthTo) {
      return '';
    }

    if (this.lengthFrom === this.lengthTo) {
      return this.lengthFrom.toString();
    }

    return `${this.lengthFrom} - ${this.lengthTo}`;
  }

  protected formErrors: FormErrors = {
    name: [],
    email: [],
    phone: [],
    dateFrom: [],
    dateTo: [],
    length: [],
    destinations: [],
    adults: [],
    children: [],
    budget: [],
    message: [],
    consent: [],
  };

  /**
   * Checks whether there are any errors on the form
   */
  protected get formHasErrors(): boolean {
    let error = false;

    // Check all the form errors, if found, break the loop and return
    for (const formErrorsKey in this.formErrors) {
      if (this.formErrors.hasOwnProperty(formErrorsKey)) {
        if (this.formErrors[formErrorsKey].length > 0) {
          error = true;
          break;
        }
      }
    }

    return error;
  }

  protected error: boolean = false;

  protected errorMessage: string = '';

  protected get formCanBeSubmitted(): boolean {
    return this.recaptchaStatus.ready && !this.formHasErrors;
  }

  public created() {
    this.today = parse(format(new Date(), 'YYYY-MM-DD'));
    this.$on(Events.RESET, () => {
      this.error = false;
      this.errorMessage = '';
      this.submited = false;
      for (const key in this.formErrors) {
        if (this.formErrors.hasOwnProperty(key)) {
          this.resetFormFieldErrors(key);
        }
      }
    });
  }

  public render() {
    const sectionTitleClass = ['mt-4', 'mb-3', 'px-3', 'px-lg-0'];
    const formClass = ['elix-form', 'px-3', 'px-lg-0'];

    if (this.submited) {
      return (
        <v-container class='px-0 py-0'>
          <section-title
            title={this.$t('app.form.tailoredOffer.title')}
            class={sectionTitleClass.join(' ')}
          />
          <v-alert type='success' color='pink accent-4' dark>
            {this.$t('app.form.inquirySuccesfull')}
          </v-alert>
        </v-container>
      );
    }

    return (
      <v-container class='px-0 py-0'>
        {(() => {
          if (!this.recaptchaStatus.required || !this.recaptchaStatus.enabled) {
            return;
          }

          return (
            <vue-recaptcha
              sitekey={appEnv.GOOGLE_RECAPTCHA_KEY}
              ref='recaptcha'
              loadRecaptchaScript={true}
              badge='bottomright'
              size='invisible'
              onVerify={this.recaptchaVerified}
              onError={this.recaptchaError}
              onRender={this.recaptchaRendered}
              onExpired={this.recaptchaExpired}
            />
          );
        })()}
        <section-title
          title={this.$t('app.form.tailoredOffer.title')}
          class={sectionTitleClass.join(' ')}
        />
        <v-row no-gutters class={formClass.join(' ')}>
          <v-col cols='12' lg='6' class={'pr-lg-3'}>
            <v-text-field
              error-messages={this.formErrors.name}
              error-count={2}
              filled
              label={this.$t('app.form.enquiry.name') + '*'}
              v-model={this.name}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.name = [])}
            />
            <v-text-field
              error-messages={this.formErrors.phone}
              error-count={2}
              filled
              label={this.$t('app.form.enquiry.phone')}
              v-model={this.phone}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.phone = [])}
            />
          </v-col>
          <v-col cols='12' lg='6' className={'pl-lg-4'}>
            <v-row no-gutters>
              <v-col cols='12'>
                <v-text-field
                  error-messages={this.formErrors.email}
                  error-count={2}
                  filled
                  label={this.$t('app.form.enquiry.email') + '*'}
                  v-model={this.email}
                  onFocus={this.loadRecaptcha}
                  onChange={() => (this.formErrors.email = [])}
                />
              </v-col>
              <v-col cols='12'>
                <v-row no-gutters>
                  <v-col cols='12' md='4' class={'pr-md-2'}>
                    <DatePickerField
                      allowedDates={this.allowedFrom}
                      errors={this.formErrors.dateFrom}
                      label={this.$t('app.form.enquiry.from') + '*'}
                      onChange={() => (this.formErrors.dateFrom = [])}
                      onInput={(value) => {
                        this.dateFrom = value;
                        this.$forceUpdate();
                      }}
                      ref='datePickerFrom'
                      value={this.dateFrom}
                    />
                  </v-col>
                  <v-col cols='12' md='4' class={'px-md-2'}>
                    <DatePickerField
                      allowedDates={this.allowedTo}
                      errors={this.formErrors.dateTo}
                      key={
                        this.dateFrom
                          ? format(this.dateFrom, 'YYYY-MM-DD')
                          : 'eqnuiry-form-datepicker-to'
                      }
                      label={this.$t('app.form.enquiry.to') + '*'}
                      onChange={() => (this.formErrors.dateTo = [])}
                      onInput={(value) => {
                        this.dateTo = value;
                        this.$forceUpdate();
                      }}
                      ref='datePickerTo'
                      value={this.dateTo}
                    />
                  </v-col>
                  <v-col cols='12' md='4' class={'pl-lg-2'}>
                    <two-way-slider
                      bordered={false}
                      labelText={this.$t('app.form.enquiry.length') + '*'}
                      max={this.lengthTo}
                      min={this.lengthFrom}
                      placeholder=''
                      sliderEnd={40}
                      sliderStart={6}
                      class='placeholder-as-text-color'
                      title={this.$t('app.form.enquiry.lengthTitle', {
                        from: this.lengthFrom,
                        to: this.lengthTo,
                      })}
                      {...{
                        on: {
                          'update:max': (value: string) => {
                            this.lengthTo = parseInt(value, 10);
                          },
                          'update:min': (value: string) => {
                            this.lengthFrom = parseInt(value, 10);
                          },
                        },
                      }}
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols='12' class={'pt-8 pt-md-0'}>
            <DestinationMultiselect
              errorMessages={this.formErrors.destinations}
              v-model={this.selectedDestinations}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.destinations = [])}
              label={this.$t('app.form.enquiry.destination').toString() + '*'}
            />
          </v-col>
          <v-col cols='12' lg='4' class={'pr-lg-3'}>
            <v-text-field
              error-messages={this.formErrors.adults}
              error-count={2}
              filled
              label={this.$t('app.form.tailoredOffer.adults') + '*'}
              v-model={this.adults}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.adults = [])}
              min='0'
              type='number'
            />
          </v-col>
          <v-col cols='12' lg='4' class={'pr-lg-3'}>
            <v-text-field
              error-messages={this.formErrors.children}
              error-count={2}
              filled
              label={this.$t('app.form.tailoredOffer.children') + '*'}
              v-model={this.children}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.children = [])}
              min='0'
              type='number'
            />
          </v-col>
          <v-col cols='12' lg='4'>
            <v-text-field
              error-messages={this.formErrors.budget}
              error-count={2}
              filled
              label={this.$t('app.form.tailoredOffer.budget')}
              v-model={this.budget}
              onFocus={this.loadRecaptcha}
              onChange={() => (this.formErrors.budget = [])}
              type='number'
              min='0'
              clearable
            />
          </v-col>
          <v-textarea
            error-messages={this.formErrors.message}
            error-count={2}
            filled
            label={this.$t('app.form.enquiry.message')}
            v-model={this.message}
            onChange={() => (this.formErrors.message = [])}
            onFocus={this.loadRecaptcha}
          />
        </v-row>
        {(() => {
          if (this.formHasErrors) {
            return (
              <v-row no-gutters class={'px-3 px-lg-0'}>
                <span class='error--text'>
                  {this.$t('app.form.error.makeCorrections')}
                </span>
              </v-row>
            );
          }
        })()}
        <v-row no-gutters class={'px-3 px-lg-0'} justify='space-between'>
          <v-col>
            <v-checkbox
              color='primary'
              class='mt-2'
              v-model={this.consent}
              error-messages={this.formErrors.consent}
              error-count={2}
              onChange={(value: boolean) => {
                if (value) {
                  this.formErrors.consent = [];
                }
              }}
              onClick={(e: any) => {
                // It ignores click on the checkbox, so we need to manually trigger it
                if (
                  e.target &&
                  e.target.parentNode &&
                  e.target.parentNode.classList.contains(
                    'v-input--selection-controls__input'
                  )
                ) {
                  // throttle is here because some clicks fire multiple times
                  if (this.consentThrottle) {
                    clearTimeout(this.consentThrottle);
                  }

                  this.consentThrottle = setTimeout(() => {
                    this.consent = !this.consent;
                    if (this.consent) {
                      // Make sure form errors are empty
                      this.formErrors.consent = [];
                    }
                  }, 100);
                }
              }}
            >
              <ConsentSentence
                slot='label'
                hasErrors={this.formErrors.consent.length > 0}
              />
            </v-checkbox>
          </v-col>
          <v-tooltip
            bottom
            disabled={this.recaptchaStatus.ready}
            scopedSlots={{
              activator: (scope: any) => {
                return (
                  <div
                    {...{
                      on: scope.on,
                    }}
                  >
                    <v-btn
                      large
                      class='accent contrast--text elix-button-text px-10'
                      block={this.isMobile}
                      onClick={this.validateAndSend}
                      disabled={!this.formCanBeSubmitted}
                      loading={this.submitting}
                    >
                      {this.$t('app.common.submit')}
                    </v-btn>
                  </div>
                );
              },
            }}
          >
            {this.$t('app.form.recaptcha.loading')}
          </v-tooltip>
        </v-row>
        <v-snackbar v-model={this.error}>
          {this.errorMessage}
          <v-btn text onClick={() => (this.error = false)}>
            {this.$t('app.common.close')}
          </v-btn>
        </v-snackbar>
      </v-container>
    );
  }

  @Emit('requestCustomOffer')
  public onRequestCustomOffer() {
    return;
  }

  /**
   * Checks dates allowed for the "From" date
   * @param date
   */
  protected allowedFrom(date: Date): boolean {
    return this.today < date;
  }

  /**
   * Checks dates allowed for the "To" date
   * @param date
   */
  protected allowedTo(date: Date): boolean {
    if (this.dateFrom) {
      return this.dateFrom < date;
    }

    return this.today < date;
  }

  /**
   * Checks for errors and validate recaptcha
   */
  protected validateAndSend() {
    // Reset all errors
    for (const formErrorsKey in this.formErrors) {
      if (this.formErrors.hasOwnProperty(formErrorsKey)) {
        this.formErrors[formErrorsKey] = [];
      }
    }

    let adults: number | null = null;

    if (this.adults !== null) {
      adults = parseInt(this.adults, 10);
    }

    if (adults === null || adults === 0 || isNaN(adults)) {
      this.formErrors.adults.push(this.$t('app.form.error.adults').toString());
    }

    let children: number | null = null;

    if (this.children !== null) {
      children = parseInt(this.children, 10);
    }

    if (children === null || isNaN(children)) {
      this.formErrors.children.push(
        this.$t('app.form.error.children').toString()
      );
    }

    if (!this.selectedDestinations || this.selectedDestinations.length < 1) {
      this.formErrors.destinations.push(
        this.$t('app.form.error.destination.missing').toString()
      );
    }

    if (!this.name) {
      this.formErrors.name.push(
        this.$t('app.form.error.name.missing').toString()
      );
    }

    if (!this.email) {
      this.formErrors.email.push(
        this.$t('app.form.error.email.missing').toString()
      );
    }

    if (!this.dateFrom) {
      this.formErrors.dateFrom.push(
        this.$t('app.form.error.date.missingFrom').toString()
      );
    }

    if (!this.dateTo) {
      this.formErrors.dateTo.push(
        this.$t('app.form.error.date.missingTo').toString()
      );
    }

    if (this.dateFrom && this.dateTo && this.dateFrom >= this.dateTo) {
      this.formErrors.dateFrom.push(
        this.$t('app.form.error.date.invalidRange').toString()
      );
    }

    if (this.lengthFrom > this.lengthTo) {
      this.formErrors.length.push(
        this.$t('app.form.error.length.invalid').toString()
      );
    }

    if (!this.consent) {
      this.formErrors.consent.push(
        this.$t('app.form.error.consent.missing').toString()
      );
    }

    if (this.formHasErrors) {
      return;
    }

    if (adults === null || children === null) {
      return;
    }

    const formData: GQLTailoredOffer = {
      name: this.name,
      email: this.email,
      phone: this.phone,
      from: this.dateFrom ? format(this.dateFrom) : '',
      to: this.dateTo ? format(this.dateTo) : '',
      numberOfNights: this.numberOfNightsStringified,
      destinationIds: this.selectedDestinations,
      adults,
      children,
      budget: (this.budget && parseInt(this.budget, 10)) || undefined,
      message: this.message,
      token: '',
    };

    this.submitting = true;

    // Load recaptcha if it hasn't yet
    let recaptchaLoad = this.recaptchaStatus.loading.promise;

    if (!recaptchaLoad) {
      recaptchaLoad = this.loadRecaptcha();
    }

    recaptchaLoad
      .then(() => {
        if (!this.recaptchaStatus.verification.promise) {
          // After the recaptcha loads, execute and verify the user
          this.recaptchaStatus.verification.promise = new Promise(
            (resolve, reject) => {
              this.recaptchaStatus.verification.resolve = resolve;
              this.recaptchaStatus.verification.reject = reject;
            }
          );

          if (this.recaptchaStatus.enabled) {
            this.recaptcha.execute();
          } else {
            // Fake validation of recaptcha, if it is not active
            this.recaptchaVerified('');
          }
        }

        return this.recaptchaStatus.verification.promise;
      })
      .then((token) => {
        this.submit(token, formData)
          .then((result: boolean) => {
            if (this.recaptcha) {
              this.recaptcha.reset();
            }

            if (result) {
              this.submited = true;
              pushEvent(
                'form',
                true,
                'tailoredOffer',
                this.selectedDestinations.join(', ')
              );
            } else {
              this.errorMessage = this.$t(
                'app.form.error.mailNotSent'
              ).toString();
              this.error = true;
            }
          })
          .catch((err: ApolloError) => {
            let recaptchaError = false;

            err.graphQLErrors.forEach((error) => {
              if (error.message === 'INVALID_RECAPTCHA') {
                recaptchaError = true;
                this.recaptcha.reset();
                this.errorMessage = this.$t(
                  'app.form.error.recaptcha'
                ).toString();
                this.error = true;
              }
            });

            if (!recaptchaError) {
              this.errorMessage = this.$t('app.form.error.common').toString();
              this.error = true;
              this.submitting = false;
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      })
      .catch(() => {
        this.errorMessage = this.$t('app.form.error.recaptcha').toString();
        this.error = true;
        this.submitting = false;
      });
  }

  /**
   * Submit the data
   */
  protected submit(token: string, formData: GQLTailoredOffer): Promise<any> {
    return this.$apollo
      .mutate<GQLRootMutation>({
        mutation: gql`
          mutation($input: TailoredOffer!) {
            tailoredOffer(input: $input)
          }
        `,
        variables: {
          input: { ...formData, token },
        },
      })
      .then((result) => {
        if (result.data) {
          return result.data.tailoredOffer;
        }

        return false;
      });
  }

  protected resetFormFieldErrors(field: string) {
    if (this.formErrors.hasOwnProperty(field)) {
      this.formErrors[field] = [];
    }
  }

  /**
   * Set flags for loading recaptcha into the app
   */
  protected loadRecaptcha(): Promise<void> {
    if (!this.recaptchaStatus.loading.promise) {
      // Set promise, so we can resolve or reject it based on the load status
      this.recaptchaStatus.loading.promise = new Promise((resolve, reject) => {
        // Store the callback functions to our object so it can be used from outside of this function
        this.recaptchaStatus.loading.resolve = resolve;
        this.recaptchaStatus.loading.reject = reject;
      }).then(() => {
        this.recaptchaStatus.ready = true;
      });
    }

    // This loads recaptcha into DOM
    this.recaptchaStatus.required = true;

    if (!this.recaptchaStatus.enabled) {
      // Fake rendering of recaptcha, if it is not active
      this.recaptchaRendered();
    }

    return this.recaptchaStatus.loading.promise;
  }

  /**
   * Callback from the vue-recaptcha component when it renders into DOM
   */
  protected recaptchaRendered() {
    // If we have the loading promise, resolve it and reset the status
    if (this.recaptchaStatus.loading.resolve) {
      this.recaptchaStatus.loading.resolve();
      this.recaptchaStatus.loading.resolve = null;
      this.recaptchaStatus.loading.reject = null;
    }
  }

  /**
   * Callback from the vue-recaptcha component when the user has been verified
   * @param response
   */
  protected recaptchaVerified(response: string) {
    // If we have the verification promise setup, resolve it and reset the status
    if (this.recaptchaStatus.verification.resolve) {
      this.recaptchaStatus.verification.resolve(response);
      this.recaptchaStatus.verification.resolve = null;
      this.recaptchaStatus.verification.reject = null;
    }
  }

  /**
   * Callback when something goes wrong, this can either be an invalid verification
   * or unable to load the recaptcha alltogether
   */
  protected recaptchaError() {
    // If the recaptcha was loading, reject and reset it
    if (this.recaptchaStatus.loading.reject) {
      this.recaptchaStatus.loading.reject();
      this.recaptchaStatus.loading.resolve = null;
      this.recaptchaStatus.loading.reject = null;
    }

    // If a verification was pending, reject and reset it
    if (this.recaptchaStatus.verification.reject) {
      this.recaptchaStatus.verification.reject();
      this.recaptchaStatus.verification.resolve = null;
      this.recaptchaStatus.verification.reject = null;
    }

    // Show an error to the user
    this.error = true;
    this.errorMessage = this.$t('app.form.error.recaptcha').toString();
  }

  /**
   * Reset verification when the recaptcha expires
   */
  protected recaptchaExpired() {
    this.recaptchaStatus.verification.promise = null;
    this.recaptchaStatus.verification.resolve = null;
    this.recaptchaStatus.verification.reject = null;
  }
}

interface Recaptcha {
  enabled: boolean;
  loading: {
    promise: Promise<void> | null;
    reject: (() => void) | null;
    resolve: ((value?: unknown) => void) | null;
  };
  verification: {
    promise: Promise<string> | null;
    reject: (() => void) | null;
    resolve: ((token: string) => void) | null;
  };
  ready: boolean;
  required: boolean;
}
