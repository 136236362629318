import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';

Vue.use(VueApollo);

export default function<ApolloCacheShape>({
  apolloClient,
}: {
  apolloClient: ApolloClient<ApolloCacheShape>;
}) {
  return new VueApollo({
    defaultClient: apolloClient,
  });
}
