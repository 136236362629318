import { GQLImage } from '~/GqlTypes';

export interface ImageModel {
  description: string;
  rank: number;
  src: string;
  srcset: string;
}

export default function createImage(image: GQLImage): ImageModel {
  return {
    description: image.description ? image.description : '',
    rank: image.rank ? image.rank : 0,
    src: image.src,
    srcset: image.srcset,
  };
}
