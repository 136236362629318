import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { Component, Mixins, Ref, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Timeout = NodeJS.Timeout;

import { getRoutePathWithoutLang } from '~/app/core/router';
import { default as HotelSectionsModule } from '~/app/core/store/modules/HotelSections';
import Hotel from '~/app/core/store/modules/Hotel';
import {
  HotelNavigationBar,
  HotelNavigationBarEvents,
} from '~/components/organisms/hotel';
import { StepEvent as HotelNavigationBarStepEvent } from '~/components/organisms/hotel/NavigationBar';
import { ChosenPackageEvent } from '~/components/organisms/hotel/RoomBlock';
import {
  ActivityCarousel,
  DestinationMapSection,
  HotelInformation,
  HotelHeader,
  RelatedHotels,
  RoomSection,
} from '~/components/templates/hotel';
import EnquiryForm, {
  Events as FormEvents,
} from '~/components/templates/EnquiryForm';
import HeadManagement from '~/mixins/HeadManagement';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { HotelModel, HotelSections } from '~/utils/hotel';
import { PackageModel } from '~/utils/package';

@Component
export default class extends Mixins<PrefetchComponent>(
  HeadManagement,
  Prefetch
) {
  protected isMobile: boolean = IS_MOBILE_BY_DEFAULT;

  protected selectedPackage: PackageModel | null = null;

  protected scrollObserver!: IntersectionObserver;

  @Ref('navigationBar')
  protected readonly navigationBar?: Vue;

  @Ref(HotelSections.DESCRIPTION)
  protected readonly descriptionSection?: Vue;

  @Ref(HotelSections.ROOMS)
  protected readonly roomSection?: Vue;

  @Ref(HotelSections.MAP)
  protected readonly mapSection?: Vue;

  @Ref(HotelSections.ENQUIRY_FORM)
  protected readonly formSection?: Vue;

  @Ref(HotelSections.ACTIVITIES)
  protected readonly activitiesSection?: Vue;

  protected intersectedSections: { [key: string]: Element } = {};

  protected observedSections: Element[] = [];

  protected observerThrottle: Timeout | null = null;

  public title() {
    return '';
  }

  public get seoMeta(): string {
    if (!this.hotel) {
      return '';
    }

    const metas: { name?: string; property?: string; content: string }[] = [];

    if (this.hotel.name) {
      metas.push({
        name: 'title',
        content: this.hotel.name,
      });
      metas.push({
        name: 'twitter:title',
        content: this.hotel.name,
      });
      metas.push({
        property: 'og:title',
        content: this.hotel.name,
      });
    }

    if (this.hotel.description) {
      metas.push({
        name: 'description',
        content: this.hotel.description,
      });
      metas.push({
        name: 'twitter:description',
        content: this.hotel.description,
      });
      metas.push({
        property: 'og:description',
        content: this.hotel.description,
      });
    }

    const image = this.hotel.listImage || this.hotel.backgroundImage;

    if (image) {
      metas.push({
        name: 'twitter:image',
        content: image.src,
      });
      metas.push({
        property: 'og:image',
        content: image.src,
      });
      metas.push({
        property: 'og:image:secure_url',
        content: image.src,
      });
    }

    return metas
      .map((meta) => {
        return `<meta${meta.name ? ` name="${meta.name}"` : ''}${
          meta.property ? ` property="${meta.property}"` : ''
        } content="${meta.content}">`;
      })
      .join('');
  }

  protected get hotelModule(): Hotel {
    return getModule(Hotel, this.$store);
  }

  public get hotel(): HotelModel | null {
    return this.hotelModule.hotel;
  }

  public prefetch() {
    const slug = getRoutePathWithoutLang(this.$route).replace(/^\//, '');
    const hotelModule = this.hotelModule || getModule(Hotel, this.$store);
    return hotelModule.loadHotelByPath({ path: slug });
  }

  public created() {
    getModule(HotelSectionsModule, this.$store).setReviewsActive(false);
  }

  public beforeMount() {
    this.setMobile();

    const config: IntersectionObserverInit = {
      root: null,
      threshold: [0, 0.2, 0.8, 1],
    };

    this.scrollObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { isIntersecting, intersectionRatio } = entry;
        if (isIntersecting && intersectionRatio > 0.2) {
          Vue.set(this.intersectedSections, entry.target.id, entry.target);
        } else {
          Vue.delete(this.intersectedSections, entry.target.id);
        }
      });
    }, config);
  }

  public beforeDestroy() {
    if (this.scrollObserver) {
      this.scrollObserver.disconnect();
    }
  }

  @Watch('$vuetify.breakpoint.name')
  public setMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  public render() {
    if (this.hotelModule.hotelLoading) {
      return (
        <v-container>
          <v-skeleton-loader type='list-item-avatar-three-line' />
          <v-skeleton-loader type='image' />
          <v-row>
            <v-col cols='2' offset='1'>
              <v-skeleton-loader type='button' />
            </v-col>
            <v-col cols='2'>
              <v-skeleton-loader type='button' />
            </v-col>
            <v-col cols='2'>
              <v-skeleton-loader type='button' />
            </v-col>
            <v-col cols='2'>
              <v-skeleton-loader type='button' />
            </v-col>
            <v-col cols='2'>
              <v-skeleton-loader type='button' />
            </v-col>
          </v-row>
          <v-skeleton-loader type='article' />
          <v-row>
            <v-col cols='12' md='8'>
              <v-skeleton-loader type='card' />
            </v-col>
            <v-col cols='12' md='4'>
              <v-skeleton-loader type='image' />
            </v-col>
          </v-row>
        </v-container>
      );
    }

    if (!this.hotel) {
      return;
    }

    const observerUpdateEvents = {
      on: {
        'hook:mounted': this.updateScrollWatch,
        'hook:updated': this.updateScrollWatch,
        'hook:destroyed': this.updateScrollWatch,
      },
    };

    return (
      <div>
        <HotelHeader
          hotel={this.hotel}
          onChosenPromoPackage={this.onChosenPackage}
          onMoreOffersClick={this.onMoreOffersClick}
          onPersonalizedOfferClick={this.onPersonalizedOfferClick}
          key={this.hotel.id}
        />
        <HotelNavigationBar ref='navigationBar' key={this.hotel.id} />
        <HotelInformation
          hotelData={this.hotel}
          id={HotelSections.DESCRIPTION}
          isMobile={this.isMobile}
          ref={HotelSections.DESCRIPTION}
          {...observerUpdateEvents}
          key={this.hotel.id}
        />
        <RoomSection
          hotel={this.hotel}
          id={HotelSections.ROOMS}
          onChosenPackage={this.onChosenPackage}
          ref={HotelSections.ROOMS}
          {...observerUpdateEvents}
          key={this.hotel.id}
        />
        <DestinationMapSection
          hotelId={this.hotel.id}
          id={HotelSections.MAP}
          ref={HotelSections.MAP}
          {...observerUpdateEvents}
          key={this.hotel.id}
        />
        <EnquiryForm
          hotel={this.hotel}
          id={HotelSections.ENQUIRY_FORM}
          isMobile={this.isMobile}
          onRequestCustomOffer={this.onRequestCustomOffer}
          package={this.selectedPackage}
          ref={HotelSections.ENQUIRY_FORM}
          {...observerUpdateEvents}
          key={this.hotel.id}
        />
        <ActivityCarousel
          hotelId={this.hotel.id}
          id={HotelSections.ACTIVITIES}
          ref={HotelSections.ACTIVITIES}
          {...observerUpdateEvents}
          key={this.hotel.id}
        />
        <divider-line class='mt-3' />
        <RelatedHotels hotel={this.hotel} key={this.hotel.id} />
      </div>
    );
  }

  protected onMoreOffersClick() {
    this.$scrollTo(`#${HotelSections.ROOMS}`, { offset: -100 });
  }

  protected onPersonalizedOfferClick() {
    if (this.formSection) {
      this.formSection.$emit(FormEvents.RESET);
    }
    this.selectedPackage = null;
    this.$scrollTo(`#${HotelSections.ENQUIRY_FORM}`, { offset: -100 });
  }

  protected onRequestCustomOffer() {
    this.selectedPackage = null;
  }

  protected onChosenPackage(data: ChosenPackageEvent) {
    if (this.formSection) {
      this.formSection.$emit(FormEvents.RESET);
    }
    this.selectedPackage = data.package;
    this.$scrollTo(`#${HotelSections.ENQUIRY_FORM}`, { offset: -100 });
  }

  protected updateScrollWatch() {
    this.observedSections = [];

    [
      this.descriptionSection,
      this.roomSection,
      this.mapSection,
      this.formSection,
      this.activitiesSection,
    ].forEach((section) => {
      if (section && section.$el.nodeName !== '#comment') {
        this.observedSections.push(section.$el);
      }
    });
  }

  @Watch('observedSections')
  protected observeSections() {
    if (this.observerThrottle) {
      clearTimeout(this.observerThrottle);
    }

    this.observerThrottle = setTimeout(() => {
      this.scrollObserver.disconnect();
      this.observedSections.forEach((section) => {
        this.scrollObserver.observe(section);
      });
    }, 150);
  }

  @Watch('intersectedSections')
  protected changeIntersectedSections() {
    if (this.navigationBar) {
      const keys = Object.keys(this.intersectedSections);
      if (keys.length > 0) {
        const [first] = keys;
        const payload: HotelNavigationBarStepEvent = {
          id: this.intersectedSections[first].id as HotelSections,
        };

        this.navigationBar.$emit(HotelNavigationBarEvents.STEPPED_IN, payload);
      } else {
        this.navigationBar.$emit(HotelNavigationBarEvents.STEPPED_OUT);
      }
    }
  }
}
