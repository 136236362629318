import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import { GQLRootQuery } from '~/GqlTypes';
import gql from 'graphql-tag';
import { createDestination, DestinationModel } from '~/utils/destination';

const HEADER_MENU_QUERY = gql`
  query {
    destinations {
      inMenu {
        canonicalSlug
        id
        name
      }
    }
  }
`;

interface SetHeaderMenuDestinations {
  destinations: DestinationModel[];
}

@Module({
  name: 'Menu',
  stateFactory: true,
  namespaced: true,
})
export default class Promotions extends AbstractModule {
  public menuDestinations: DestinationModel[] = [];

  @Action({ commit: 'setHeaderMenuDestinations', rawError: true })
  public loadHeaderMenuDestinations(): Promise<SetHeaderMenuDestinations> {
    if (this.menuDestinations.length > 1) {
      return Promise.resolve({
        destinations: this.menuDestinations,
      });
    }

    return this.apollo
      .query<GQLRootQuery>({
        query: HEADER_MENU_QUERY,
      })
      .then((result) => {
        return {
          destinations: result.data.destinations.inMenu.map((destination) =>
            createDestination(destination)
          ),
        };
      });
  }

  @Mutation
  protected setHeaderMenuDestinations(data: SetHeaderMenuDestinations) {
    this.menuDestinations = data.destinations;
  }
}
