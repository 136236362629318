enum Sections {
  DESCRIPTION = 'hotel-description',
  ENQUIRY_FORM = 'hotel-enquiry-form',
  REVIEWS = 'hotel-reviews',
  ROOMS = 'hotel-rooms',
  MAP = 'hotel-map',
  ACTIVITIES = 'hotel-activities',
}

export default Sections;
