import { GQLBeach, GQLHotelBeach } from '~/GqlTypes';
import createImage, { ImageModel } from '~/utils/image';

export interface BeachModel {
  backgroundImage: ImageModel | null;
  content: string;
  excerpt: string;
  id: string;
  listImage: ImageModel | null;
  name: string;
  nextToHotel: boolean;
}

function isHotelBeach(data: any): data is GQLHotelBeach {
  return data && data.__typename === 'HotelBeach';
}

export default function createBeach(
  data: GQLBeach | GQLHotelBeach
): BeachModel {
  let nextToHotel = false;
  if (isHotelBeach(data) && data.nextToHotel) {
    nextToHotel = true;
  }

  const backgroundImage = data.featuredImage
    ? createImage(data.featuredImage)
    : null;

  const listImage = data.listImage ? createImage(data.listImage) : null;

  return {
    backgroundImage,
    content: data.content ? data.content : '',
    excerpt: data.excerpt ? data.excerpt : '',
    id: data.id,
    listImage,
    name: data.name ? data.name : '',
    nextToHotel,
  };
}
