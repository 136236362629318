import 'intersection-observer';
import { createApp } from '~/app/main';

// client-specific bootstrapping logic...

const { app, router, store } = createApp();

// @ts-ignore
if (window.__INITIAL_STATE__) {
  // @ts-ignore
  store.replaceState(window.__INITIAL_STATE__);
}

router.onReady(() => {
  app.$mount('#app');
});

if ((module as any).hot) {
  (module as any).hot.accept();
}
