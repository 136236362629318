import { CurrencyModel, format } from '~/utils/currency';

/**
 * Formats the input number to currency string
 */
export default function(amount: number, currency: CurrencyModel): string {
  // Round up the values to the nearest ten
  const roundedAmount = Math.ceil(amount / 10) * 10;

  const formatedAmount = format(roundedAmount);

  if (currency.code === 'CZK') {
    return `${formatedAmount} Kč`;
  }

  return `${currency.code} ${formatedAmount}`;
}
