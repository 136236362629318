import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    buttons: {
      cancel: 'Zrušit',
      confirm: 'Potvrdit',
      morePromotionOffers: 'Další akční nabídky',
      search: 'Hledat',
    },
  },
};

export default i18n;
