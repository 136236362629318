import { BigBlockInterface, IconTextInterface } from 'elixir-theme';
import VueI18n, { LocaleMessage } from 'vue-i18n';
import { VueRouter } from 'vue-router/types/router';

import { getLocalizedUrl } from '~/app/core/router';
import { annotate as annotateCurrency } from '~/utils/currency';
import { HotelModel } from '~/utils/hotel';

function createIcon(icon: string, text: LocaleMessage): IconTextInterface {
  return {
    iconName: icon,
    text: text.toString(),
    iconColor: 'white',
    large: true,
  };
}

function createIcons(hotel: HotelModel, i18n: VueI18n) {
  const icons: IconTextInterface[] = [];

  if (hotel.beachNextToHotel) {
    icons.push(
      createIcon('$vuetify.icons.beach', i18n.t('app.hotel.nextToHotel'))
    );
  }

  if (hotel.capacityMax) {
    if (hotel.capacityMin === hotel.capacityMax) {
      icons.push(
        createIcon(
          '$vuetify.icons.bed',
          i18n.tc('app.hotel.header.roomCapacity', hotel.capacityMax, {
            to: hotel.capacityMax,
          })
        )
      );
    } else {
      icons.push(
        createIcon(
          '$vuetify.icons.bed',
          i18n.tc('app.hotel.header.roomMultiCapacity', hotel.capacityMax, {
            from: hotel.capacityMin,
            to: hotel.capacityMax,
          })
        )
      );
    }
  }

  if (hotel.areaMin && hotel.areaMax) {
    if (hotel.areaMin === hotel.areaMax) {
      icons.push(
        createIcon(
          '$vuetify.icons.room',
          i18n.tc('app.hotel.header.roomSize', hotel.areaMax, {
            to: hotel.areaMax,
          })
        )
      );
    } else {
      icons.push(
        createIcon(
          '$vuetify.icons.room',
          i18n.tc('app.hotel.header.roomSizeRange', hotel.areaMax, {
            from: hotel.areaMin,
            to: hotel.areaMax,
          })
        )
      );
    }
  }

  if (hotel.boardNames.length > 1) {
    icons.push(createIcon('$vuetify.icons.food', hotel.boardNames.join(', ')));
  }

  return icons;
}

export default function(
  hotel: HotelModel,
  router: VueRouter,
  i18n: VueI18n,
  showIcons?: boolean
): BigBlockInterface {
  let priceText = '---';
  if (hotel.promoPackage) {
    priceText = i18n
      .t('app.priceFrom', {
        amount: annotateCurrency(hotel.promoPackage.price, { code: 'CZK' }),
      })
      .toString();
  }

  const icons = showIcons ? createIcons(hotel, i18n) : [];

  return {
    // TODO: Enable average rating
    averageRating: 0,
    averageRatingText: '',
    bigImgSrc: hotel.listImage ? hotel.listImage.src : '',
    bigImgSrcSet: hotel.listImage ? hotel.listImage.srcset : '',
    buttonIcon: 'keyboard_arrow_right',
    buttonLineOne: i18n.t('app.package.price.perRoomPerNight').toString(),
    buttonLineTwo: priceText,
    hideRating: true,
    itemHeader: hotel.destination ? hotel.destination.name : '',
    itemHeadline: hotel.name,
    listOfIcons: icons,
    name: hotel.id,
    numberOfStars: hotel.stars,
    routeName: getLocalizedUrl(hotel.slug, router),
    topButtonLink: hotel.destination
      ? getLocalizedUrl(hotel.destination.slug, router)
      : undefined,
  };
}
