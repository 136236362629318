import createBigBlock from './createBigBlock';
import isGqlHotel from './typeguard';
import { AreaModel, createArea } from '~/utils/area';
import { default as Sections } from './sections';
import { GQLHotel } from '~/GqlTypes';
import { createDestination, DestinationModel } from '~/utils/destination';
import createImage, { ImageModel } from '~/utils/image';
import createPackage, { PackageModel } from '~/utils/package';
import { createRoom, RoomModel } from '~/utils/room';
import { createPosition, GeoPosition } from '~/utils/GeoPosition';

interface HotelModel {
  area: AreaModel | null;
  areaMin: number | null;
  areaMax: number | null;
  backgroundImage: ImageModel | null;
  beachNextToHotel: boolean;
  boardNames: string[];
  capacityMin: number | null;
  capacityMax: number | null;
  content: string;
  darkHeader: boolean;
  description: string;
  destination: DestinationModel | null;
  distanceToBeach: string | null;
  excerpt: string;
  hideFlightPrice: boolean;
  hideTransferPrice: boolean;
  id: string;
  listImage: ImageModel | null;
  name: string;
  position: GeoPosition | null;
  promoPackage: PackageModel | null;
  rooms: RoomModel[];
  slug: string;
  stars: number;
}

function createHotel(data: GQLHotel): HotelModel {
  let featuredImage = data.featuredImage
    ? createImage(data.featuredImage)
    : null;

  if (
    !featuredImage &&
    data.area &&
    data.area.destination &&
    data.area.destination.featuredImage
  ) {
    featuredImage = createImage(data.area.destination.featuredImage);
  }

  let beachNextToHotel = false;
  if (data.beaches) {
    for (const beach of data.beaches) {
      if (beach.nextToHotel) {
        beachNextToHotel = true;
        break;
      }
    }
  }

  let areaMin: number | null = null;
  let areaMax: number | null = null;
  let capacityMin: number | null = null;
  let capacityMax: number | null = null;

  const rooms: RoomModel[] = [];

  if (data.rooms) {
    for (const room of data.rooms) {
      rooms.push(createRoom(room));

      if (areaMin === null || areaMin > room.area) {
        areaMin = room.area;
      }
      if (areaMax === null || areaMax < room.area) {
        areaMax = room.area;
      }

      for (const occupancy of room.occupancies) {
        const capacity = occupancy.adult + occupancy.child;
        if (capacityMin === null || capacityMin > capacity) {
          capacityMin = capacity;
        }
        if (capacityMax === null || capacityMax < capacity) {
          capacityMax = capacity;
        }
      }
    }
  }

  if (!areaMin || !areaMax) {
    areaMin = null;
    areaMax = null;
  }

  if (!capacityMin || !capacityMax) {
    capacityMin = null;
    capacityMax = null;
  }

  const boardNames: string[] = [];

  if (data.boards && data.boards.length > 0) {
    data.boards.forEach((board) => {
      if (board.name) {
        boardNames.push(board.name);
      }
    });
  }

  return {
    area: data.area ? createArea(data.area) : null,
    areaMin,
    areaMax,
    backgroundImage: featuredImage,
    beachNextToHotel,
    boardNames,
    capacityMin,
    capacityMax,
    content: data.content ? data.content : '',
    darkHeader: data.darkHeader ? data.darkHeader : false,
    description: data.description ? data.description : 'Hotel ' + data.name,
    destination:
      data.area && data.area.destination
        ? createDestination(data.area.destination)
        : null,
    distanceToBeach: data.distanceToBeach ? data.distanceToBeach : null,
    excerpt: data.excerpt ? data.excerpt : '',
    hideFlightPrice: data.hideFlyTicketPrice,
    hideTransferPrice: data.hideTransferPrice,
    id: data.id,
    listImage: data.listImage ? createImage(data.listImage) : null,
    name: data.name,
    position: createPosition(data.latitude, data.longitude),
    promoPackage: data.promoPackage ? createPackage(data.promoPackage) : null,
    rooms,
    slug: data.canonicalSlug ? data.canonicalSlug : '',
    stars: data.hotelType ? data.hotelType.stars : 0,
  };
}

export {
  isGqlHotel,
  createHotel,
  createBigBlock as createBigHotelBlock,
  HotelModel,
  Sections as HotelSections,
};
