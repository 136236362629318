import {
  ButtonLinkInterface,
  BenefitsInterface,
  IconTextInterface,
} from 'elixir-theme';
import { LocaleMessage } from 'vue-i18n';
import { Component } from 'vue-property-decorator';

import { getLocalizedRoute, routes } from '~/app/core/router/routes';
import whyElixirBg from '~/assets/images/why-elixir-bg.jpg';
import { VueComponent } from '~/utils/vue-component';

function createIcon(text: LocaleMessage, iconName: string): IconTextInterface {
  return {
    iconColor: '#2d3588',
    iconName,
    text: text.toString(),
    xLarge: true,
  };
}

@Component
export default class extends VueComponent<{}> {
  protected get benefits(): BenefitsInterface {
    return {
      button: this.button,
      header: this.header,
      icons: [
        createIcon(this.$t('app.benefits.luxury'), '$vuetify.icons.shellStar'),
        createIcon(
          this.$t('app.benefits.specialists'),
          '$vuetify.icons.turtle'
        ),
        createIcon(
          this.$t('app.benefits.verification'),
          '$vuetify.icons.coctail'
        ),
        createIcon(this.$t('app.benefits.tested'), '$vuetify.icons.goggles'),
      ],
    };
  }

  protected get button(): ButtonLinkInterface {
    return {
      name: this.$t('app.benefits.button').toString(),
      routeName: getLocalizedRoute(routes.about, this.$router),
    };
  }

  protected get header(): string {
    return this.$t('app.benefits.header').toString();
  }

  public render() {
    return (
      <background-wrapper
        imgUrl={whyElixirBg}
        stripeColor='linear-gradient(to bottom, rgba(250,250,250,1) 0%,rgba(255,255,255,0) 100%)'
        style='margin-top:-30px;'
        imgPosition='bottom center'
      >
        <benefits {...{ attrs: this.benefits }} />
      </background-wrapper>
    );
  }
}
