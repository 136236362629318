import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    cookies: {
      consent:
        'Abychom vám poskytli co nejlepší zážitek z návštěvy našeho webu, používáme soubory cookie. Použitím tohoto webu vyjadřujete s {consentLink} souhlas.',
      consentLink: 'používáním souborů cookie',
    },
  },
};

export default i18n;
