import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Promotions from '~/app/core/store/modules/Promotions';
import heartTip from '~/assets/images/heartTip.png';
import { CarouselImage, CarouselInterface } from 'elixir-theme';
import { getLocalizedUrl } from '~/app/core/router';
import { annotate as annotateCurrency } from '~/utils/currency';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';

@Component
export default class extends Mixins<PrefetchComponent>(Prefetch) {
  protected isMobile: boolean = false;

  protected get priceText(): string {
    return this.$t('app.price').toString();
  }

  protected get buttonText(): string {
    return this.$t('app.view').toString();
  }

  protected get hotelTips(): CarouselImage[] {
    const tips: CarouselImage[] = [];

    getModule(Promotions, this.$store).hotelTips.forEach((hotel) => {
      if (!hotel.backgroundImage) {
        return;
      }

      let priceText: string = '---';

      if (hotel.promoPackage) {
        priceText = annotateCurrency(hotel.promoPackage.price, { code: 'CZK' });
      }

      tips.push({
        id: hotel.id,
        priceInfo: this.$t('app.priceFrom', { amount: priceText }).toString(),
        routeName: getLocalizedUrl(hotel.slug, this.$router),
        src: hotel.backgroundImage ? hotel.backgroundImage.src : '',
        srcSet: hotel.backgroundImage ? hotel.backgroundImage.srcset : '',
        stars: hotel.stars,
        title: hotel.name,
      });
    });

    return tips;
  }

  public prefetch() {
    return getModule(Promotions, this.$store).loadHotelTips();
  }

  public beforeMount() {
    this.checkMobile();
  }

  @Watch('$vuetify.breakpoint.name')
  public checkMobile() {
    this.isMobile =
      this.$vuetify.breakpoint.name === 'xs' ||
      this.$vuetify.breakpoint.name === 'sm' ||
      this.$vuetify.breakpoint.name === 'md';
  }

  public render() {
    if (this.hotelTips.length < 1) {
      return;
    }

    const attrs: CarouselInterface = {
      photos: this.hotelTips,
      buttonText: this.buttonText,
      priceText: this.priceText,
      height: this.isMobile ? 485 : 500,
      topIconSrc: heartTip,
    };
    return (
      <carousel
        {...{
          attrs,
        }}
        class='mt-3'
      />
    );
  }
}
