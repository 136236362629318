import { GQLArea } from '~/GqlTypes';

interface AreaModel {
  id: string;
  name: string;
  transferPrice: number;
}

function createArea(data: GQLArea): AreaModel {
  return {
    id: data.id,
    name: data.name,
    transferPrice: data.priceTransfer ? data.priceTransfer.amount : 0,
  };
}

export { createArea, AreaModel };
