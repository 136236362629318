import { PromotionBoxInterface } from 'elixir-theme';
import { Component } from 'vue-property-decorator';

import background from '~/assets/images/promotion-pink.png';
import { VueComponent } from '~/utils/vue-component';

interface CustomizedOfferPromotion {
  onShowForm?: () => void;
}

@Component
export default class extends VueComponent<CustomizedOfferPromotion> {
  protected get boxData(): PromotionBoxInterface {
    return {
      backgroundImageUrl: background,
      callToActionBtn: {
        name: this.$t('app.wantOffer').toString(),
      },
      description: this.$t('app.promotions.offer.description').toString(),
      height: 285,
      phoneNumber: this.$t('app.contacts.phone').toString(),
      secondaryCallToAction: this.$t(
        'app.promotions.offer.orCallUs'
      ).toString(),
      title: this.$t('app.promotions.offer.title').toString(),
      vuetifyButtonTextClass: 'accent',
      vuetifyTextClass: 'white',
    };
  }

  public render() {
    return (
      <promotion-box
        {...{ attrs: this.boxData }}
        onButtonClick={() => this.$emit('showForm')}
      />
    );
  }
}
