import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    header: {
      fulltext: {
        button: 'Hledání',
        buttonQuery: 'Vyhledat',
        byParameters: 'Chci hledat podle lokality, termínu či délky',
        placeholder: 'Vyberte destinaci nebo napište název hotelu či resortu',
        showOurHotels: 'Zobrazit naše hotely',
        title: 'Hledáte konkrétní hotel nebo lokalitu?',
      },
      menu: {
        allDestinations: 'Všechny destinace',
        specialOffer: 'Akce',
        wedding: 'Svatba',
        weddings: 'Svatby',
      },
    },
  },
};

export default i18n;
