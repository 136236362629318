import AbstractModule from '~/app/core/store/modules/AbstractModule';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import gql from 'graphql-tag';
import { createDestination, DestinationModel } from '~/utils/destination';
import {
  GQLDestinationConnection,
  GQLPageInfo,
  GQLRootQuery,
} from '~/GqlTypes';
import ApolloClient from 'apollo-client';

const DESTINATIONS_QUERY = gql`
  query($after: String) {
    destinations {
      list(first: 100, after: $after, visibility: PUBLISHED) {
        edges {
          node {
            id
            name
            canonicalSlug
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

interface SetDestinationsCommit {
  destinations: DestinationModel[];
}

function loadAllDestinations(
  data: GQLDestinationConnection,
  apollo: ApolloClient<any>
): Promise<DestinationModel[]> {
  if (!data.pageInfo.hasNextPage) {
    return Promise.resolve(
      data.edges.map((edge) => createDestination(edge.node))
    );
  }

  return apollo
    .query<GQLRootQuery>({
      query: DESTINATIONS_QUERY,
      variables: {
        after: data.pageInfo.endCursor ? data.pageInfo.endCursor : null,
      },
    })
    .then((result) => {
      return loadAllDestinations(result.data.destinations.list, apollo);
    });
}

@Module({
  name: 'Destinations',
  stateFactory: true,
  namespaced: true,
})
export default class Destinations extends AbstractModule {
  public destinations: DestinationModel[] = [];

  protected loadingPromise: Promise<SetDestinationsCommit> | null = null;

  @Action({ commit: 'setDestinations', rawError: true })
  public loadDestinations() {
    if (this.destinations.length > 0) {
      return Promise.resolve({
        destinations: this.destinations,
      });
    }

    if (this.loadingPromise) {
      return this.loadingPromise;
    }

    const pageInfo: GQLPageInfo = {
      hasPreviousPage: false,
      hasNextPage: true,
      endCursor: '',
    };

    const promise = loadAllDestinations(
      {
        edges: [],
        totalCount: 0,
        pageInfo,
      },
      this.apollo
    ).then((destinations) => {
      return {
        destinations,
      };
    });

    this.setLoadingPromise(promise);

    return promise;
  }

  @Mutation
  protected setDestinations(data: SetDestinationsCommit) {
    this.destinations = data.destinations;
  }

  @Mutation
  protected setLoadingPromise(promise: Promise<SetDestinationsCommit> | null) {
    this.loadingPromise = promise;
  }
}
